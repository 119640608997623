import { Box, Chip, ChipTypeMap, LinearProgress, SvgIcon, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MinimumOrderValue from '../../assets/icons/minimum_order_value.svg?react';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import BlackBackgroundTooltip from './BlackBackgroundTooltip';

interface MinimumOrderValueChipProps {
  minimumOrderValue: number;
  disableTooltip?: boolean;
  enableProgress?: boolean;
  totalPrice?: number;
  size?: ChipTypeMap['props']['size'];
  sx?: SxProps;
  sxChip?: SxProps;
}

interface ChipColor {
  chip: ChipTypeMap['props']['color'];
  icon: string | ((theme: Theme) => string);
}

export default function MinimumOrderValueChip({ minimumOrderValue, disableTooltip, enableProgress, totalPrice, size, sx, sxChip }: MinimumOrderValueChipProps): React.JSX.Element {
  const { t } = useTranslation(['contracts', 'common']);
  const currencyFormatWithFraction = useCurrencyFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 });
  const currencyFormat = useCurrencyFormat({ maximumFractionDigits: 0 });
  const formattedMoV = currencyFormat.format(minimumOrderValue);

  const progress = useMemo(() => {
    if (!totalPrice) {
      return 0;
    }

    return (totalPrice / minimumOrderValue) * 100;
  }, [totalPrice, minimumOrderValue]);

  const title = useMemo(() => {
    if (enableProgress) {
      return t('search:minimum_order_value_progress', { basketPrice: currencyFormatWithFraction.format(totalPrice!), minimumOrderValue });
    }

    return t('common:minimum_order_value');
  }, [enableProgress, totalPrice]);

  const chipColor = useMemo<ChipColor>(() => {
    if (enableProgress) {
      return {
        chip: progress >= 100 ? 'success' : 'marketing3',
        icon: 'white',
      };
    }

    return {
      chip: 'layout',
      icon: (theme: Theme) => theme.palette.marketing3.main,
    };
  }, [progress, enableProgress]);

  return (
    <BlackBackgroundTooltip title={title} disableHoverListener={disableTooltip}>
      <Box
        sx={{
          position: 'relative',
          borderRadius: 2,
          overflow: 'hidden',
          ...sx,
        }}
      >
        <Chip
          color={chipColor.chip}
          size={size || 'small'}
          icon={<SvgIcon component={MinimumOrderValue} inheritViewBox sx={{ fill: chipColor.icon }} />}
          label={size === 'medium' ? t('contracts:value_of_mov', { value: formattedMoV }) : formattedMoV}
          sx={sxChip}
        />
        {!!enableProgress && progress < 100 && (
          <LinearProgress
            sx={{
              bgcolor: 'marketing3.main',
              height: '2px',
              position: 'absolute',
              width: '100%',
              bottom: '0px',
            }}
            color="accent"
            variant="determinate"
            value={progress}
          />
        )}
      </Box>
    </BlackBackgroundTooltip>
  );
}
