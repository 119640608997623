import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, ButtonProps, OutlinedInput, styled, SxProps, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface QuantitySelectorProps {
  quantity: number;
  onQuantityChange: (newQuantity: number) => void;
  defaultValue?:number;
  shouldDisplayTitle?: boolean;
  shouldDisplayButtons?: boolean;
  sx?: SxProps;
  addToCartButtonSx?: SxProps;
  inputSx?: SxProps;
  index?: number;
}

interface QuantitySelectorButtonProps extends ButtonProps {
  isDirty?: boolean;
  displayShoppingCart?: boolean;
}

export const QuantitySelectorButton = styled(({ isDirty, displayShoppingCart, ...props }: QuantitySelectorButtonProps) => {
  const sx = displayShoppingCart
    ? { backgroundColor: 'marketing1.main', color: 'marketing1.contrastText', '&:hover': { backgroundColor: 'marketing1.dark' } }
    : { backgroundColor: 'primary.main', color: 'primary.contrastText', '&:hover': { backgroundColor: 'primary.dark' } };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Button {...props} color={isDirty ? 'warning' : 'primary'} variant="contained" sx={isDirty ? {} : sx}>
      {props.children}
    </Button>
  );
})(() => ({
  minWidth: 'unset',
}));

export default function QuantitySelector({
  quantity,
  onQuantityChange,
  defaultValue,
  shouldDisplayTitle,
  shouldDisplayButtons = true,
  sx,
  addToCartButtonSx,
  inputSx,
  index,
}: QuantitySelectorProps): React.JSX.Element {
  const [displayedValue, setDisplayedValue] = useState<number | ''>(quantity);
  const { t } = useTranslation(['basket']);
  const inputName = 'quantitySelector';
  const { formState, setValue } = useForm({
    defaultValues: {
      [inputName]: defaultValue,
    },
  });

  // We should care for form dirtiness only if there is a default value
  const isFormDirty = useMemo(() => formState.isDirty && defaultValue !== undefined, [formState]);
  const displayShoppingCart = useMemo(() => !quantity && defaultValue === undefined && shouldDisplayButtons, [quantity]);

  useEffect(() => {
    setDisplayedValue(quantity);
    setValue(inputName, quantity, { shouldDirty: true });
  }, [quantity]);

  const handleChange = (event: ChangeEvent): void => {
    const inputField = event.target as HTMLInputElement;
    let newQuantity = parseInt(inputField.value.replace(/[+-]/, ''), 10);

    if (Number.isNaN(newQuantity)) {
      setDisplayedValue('');
    } else {
      newQuantity = Math.min(newQuantity, 999);
      onQuantityChange(newQuantity);
      setDisplayedValue(newQuantity);
    }

    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const handleDecrementClick = (): void => {
    const newQuantity = Math.max(quantity - 1, 0);

    onQuantityChange(newQuantity);
    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const handleIncrementClick = (): void => {
    const newQuantity = Math.min(quantity + 1, 999);

    onQuantityChange(newQuantity);
    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!displayShoppingCart ? (
        <form style={{ display: 'flex' }}>
          {shouldDisplayButtons && (
            <QuantitySelectorButton onClick={handleDecrementClick} isDirty={isFormDirty} displayShoppingCart={displayShoppingCart} sx={{ paddingY: 0.5, paddingX: 1 }}>
              <RemoveIcon sx={{ width: 16 }} />
            </QuantitySelectorButton>
          )}
          <OutlinedInput
            onChange={handleChange}
            inputProps={{
              min: 0,
              'data-testid': `quantity-${index}`,
              sx: {
                textAlign: 'center',
                '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                  display: 'none',
                },
                paddingY: 1,
                paddingX: 0,
                height: '1rem',
                ...inputSx,
              },
            }}
            name={inputName}
            size="small"
            sx={{
              mx: 0.5,
              minWidth: 0,
              maxWidth: 52,
              ...sx,
            }}
            type="number"
            value={displayedValue}
          />

          {shouldDisplayButtons && (
          <QuantitySelectorButton onClick={handleIncrementClick} isDirty={isFormDirty} displayShoppingCart={displayShoppingCart} sx={{ paddingY: 0.5, paddingX: 1 }}>
            <AddIcon sx={{ width: 16 }} />
          </QuantitySelectorButton>
          )}
        </form>
      ) : (
        <QuantitySelectorButton onClick={handleIncrementClick} displayShoppingCart={displayShoppingCart} sx={addToCartButtonSx}>
          <ShoppingCartOutlinedIcon sx={{ width: 18 }} />
          {shouldDisplayTitle && (
            <Typography sx={{ ml: 1, color: 'marketing1.contrastText', fontWeight: 600 }}>{t('basket:add_to_basket')}</Typography>
          )}
        </QuantitySelectorButton>
      )}
    </>
  );
}
