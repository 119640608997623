/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationResult } from '@apollo/client';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import {
  Availability_Status_Enum,
  Base_Unit_Insert_Input,
  Base_Unit_Type_Enum,
  Gtin_Type_Enum,
  Packaging_Arr_Rel_Insert_Input,
  Packaging_Insert_Input,
  Product_Insert_Input,
} from 'kheops-graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, generatePath, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { InsertProductMutation, useInsertProductMutation } from '../../mutations/__generated__/insertProduct.generated';
import { PackagingsByCompanyDocument } from '../../queries/__generated__/packagingsByCompany.generated';
import { companyIdAtom, DraftPackaging, draftProductAtom, DraftProductPackagings, draftProductPackagingsAtom, productSubmittedAtom } from './state';
import { RoutePaths } from '../../routes/AppRoutes';
import PhotosUtils from '../../common/utils/photos.utils';

interface ProductAddPageOutletContext {
  insertProductOutput: MutationResult<InsertProductMutation>
}

export function useProductAddPageOutletContext(): ProductAddPageOutletContext {
  return useOutletContext<ProductAddPageOutletContext>();
}

export default function ProductAdd(): React.JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const draftProductPackagings = useAtomValue(draftProductPackagingsAtom);
  const draftProduct = useAtomValue(draftProductAtom);
  const companyId = useAtomValue(companyIdAtom);
  const [productSubmitted, setProductSubmitted] = useAtom(productSubmittedAtom);
  const [insertProduct, insertProductOutput] = useInsertProductMutation({ refetchQueries: ['ProductsByCompany', PackagingsByCompanyDocument] });
  const { t } = useTranslation(['common', 'products']);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const convertFormPackagingsIntoPackagingsInput = (packagings: DraftPackaging[], baseUnitType: 'BULK' | 'UNIT'): Packaging_Arr_Rel_Insert_Input => {
    const packagingsInput = packagings.map((packaging) => {
      const result: Packaging_Insert_Input = {
        sku: packaging.sku,
        company_id: companyId!,
        trade_item_unit_descriptor: packaging.trade_item_unit_descriptor,
        price: packaging.price,
      };

      if (baseUnitType === 'UNIT') {
        result.unit_quantity = packaging.unit_quantity;
      } else {
        result.content_measurement_unit = packaging.content_measurement_unit;
        result.net_content = packaging.net_content;
      }

      return result;
    });

    return { data: packagingsInput };
  };

  const convertFormBaseUnitIntoBaseUnitInput = (productPackaging: DraftProductPackagings): Base_Unit_Insert_Input => {
    const { main_photo, photos } = PhotosUtils.getFormattedBaseUnitPhotosInput(productPackaging.csu.main_photo, productPackaging.csu.photos);
    const packagings = convertFormPackagingsIntoPackagingsInput(productPackaging.packagings, productPackaging.csu.csu_type);
    const baseUnit: Base_Unit_Insert_Input = {
      sku: productPackaging.csu.sku,
      company_id: companyId!,
      availability: productPackaging.csu.availability ? Availability_Status_Enum.Available : Availability_Status_Enum.Unavailable,
      billing_type: productPackaging.csu.billing_type,
      suggested_retail_price: productPackaging.csu.suggested_retail_price || undefined,
      packagings,
      main_photo,
      photos,
    };

    if (productPackaging.csu.csu_type === 'UNIT') {
      baseUnit.content_measurement_unit = productPackaging.csu.content_measurement_unit;
      baseUnit.gtin = productPackaging.csu.gtin || undefined;
      baseUnit.gtin_type = Gtin_Type_Enum.Gtin_13;
      baseUnit.unit_net_content = productPackaging.csu.unit_net_content;
      baseUnit.unit_quantity = productPackaging.csu.unit_quantity;
      baseUnit.unit_type = productPackaging.csu.unit_type;
    } else {
      baseUnit.unit_type = Base_Unit_Type_Enum.Bulk;
    }

    return baseUnit;
  };

  const convertFormIntoProductInsertInput = (): Product_Insert_Input => {
    const baseUnits = draftProductPackagings.map((productPackaging) =>
      convertFormBaseUnitIntoBaseUnitInput(productPackaging));

    return {
      sku: draftProduct!.sku,
      company_id: companyId,
      name: draftProduct!.name,
      sub_family: draftProduct!.sub_family,
      vat_rate: draftProduct!.vat_rate,
      labels: {
        data: draftProduct!.labels.map((label) => ({ label })),
      },
      base_units: {
        data: baseUnits,
      },
      ingredients: draftProduct!.ingredients || undefined,
      allergens: draftProduct!.allergens || undefined,
      calories: draftProduct!.calories || undefined,
      calories_kj: draftProduct!.calories_kj || undefined,
      fat: draftProduct!.fat || undefined,
      saturated_fat: draftProduct!.saturated_fat || undefined,
      carbohydrate: draftProduct!.carbohydrate || undefined,
      sugars: draftProduct!.sugars || undefined,
      sodium: draftProduct!.sodium || undefined,
      protein: draftProduct!.protein || undefined,
      shelf_life_duration: draftProduct?.shelf_life_duration,
      shelf_life_duration_type: draftProduct?.shelf_life_duration ? draftProduct.shelf_life_duration_type : undefined,
      description: draftProduct!.description || undefined,
    };
  };

  useEffect(() => {
    setProductSubmitted(false);

    if (insertProductOutput.data?.insert_product) {
      const { sku, company_id } = insertProductOutput.data.insert_product.returning[0];

      navigate(generatePath(RoutePaths.PRODUCT, { productSku: `${sku}`, companyId: company_id }));

      if (productId) {
        setCommonSnackbarProps({
          label: t('common:update_successful'),
          snackbarProps: {
            open: true,
          },
        });
      }
    } else if (insertProductOutput.error) {
      setCommonSnackbarProps({
        label: t(productId ? 'products:update_failed' : 'products:creation_failed'),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'error',
        },
      });
    }
  }, [insertProductOutput.data, insertProductOutput.error]);

  useEffect(() => {
    if (productSubmitted) {
      insertProduct({
        variables: { product: convertFormIntoProductInsertInput() },
        update: (cache) => {
          cache.evict({ fieldName: 'product' });
        },
      });
    }
  }, [productSubmitted]);

  return (
    <Outlet context={{ insertProductOutput }} />
  );
}
