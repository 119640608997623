import { Box, FormHelperText, Grid, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Base_Unit_Type_Enum, Billing_Type_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { ComputePackagingPriceFromVolumePrice, FormatLogisticalPackaging, GetConvertedUnit, round } from 'kheops-utils';
import { Controller, useFormContext } from 'react-hook-form';
import ValidatorsUtils from '../../../common/utils/validators.utils';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import { isPriceInputDisabled } from '../utils';

interface PackagingPriceProps {
  baseUnitIndex: number;
  packagingIndex: number;
}

export default function PackagingPrice({ baseUnitIndex, packagingIndex }: PackagingPriceProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'packaging', 'products', 'price', 'validation']);
  const { control, formState, setValue, watch } = useFormContext<ProductPackagingsFormData>();
  const [baseUnit, packaging] = watch([
    `productPackagings.${baseUnitIndex}.csu`,
    `productPackagings.${baseUnitIndex}.packagings.${packagingIndex}`,
  ]);

  const pricingFormDisabled = isPriceInputDisabled(baseUnit, packaging);

  const isBilledByWeight = useMemo((): boolean => {
    return baseUnit.billing_type === Billing_Type_Enum.WeightDependent;
  }, [baseUnit.billing_type]);

  const isBulk = useMemo((): boolean => {
    return baseUnit.csu_type === 'BULK';
  }, [baseUnit.csu_type]);

  const volumeUnits = useMemo(() => {
    if (pricingFormDisabled) {
      return;
    }

    return GetConvertedUnit((isBulk ? packaging.content_measurement_unit : baseUnit.content_measurement_unit)!);
  }, [formState, pricingFormDisabled, isBulk]);

  const priceMeasurementUnit = useMemo((): string | undefined => {
    if ((isBilledByWeight || isBulk) && volumeUnits) {
      return volumeUnits.volumeUnit === Measurement_Unit_Enum.L ? Measurement_Unit_Enum.L : volumeUnits.volumeUnit.toLowerCase();
    }
  }, [isBilledByWeight, isBulk, volumeUnits]);

  const formattedPackagingTitle = useMemo((): string => {
    let title = `${t('common:packaging_one')} ${packagingIndex + 1}`;

    if (!pricingFormDisabled) {
      title += ` : ${FormatLogisticalPackaging(packaging, 'fr', isBulk ? { unit_type: Base_Unit_Type_Enum.Bulk, billing_type: baseUnit.billing_type } : undefined)}`;
    }

    return title;
  }, [formState, pricingFormDisabled]);

  useEffect((): void => {
    if (pricingFormDisabled) {
      return;
    }

    let newPackagingPrice = packaging.base_unit_price * (packaging.unit_quantity || packaging.net_content)!;

    if (isBilledByWeight || isBulk) {
      newPackagingPrice = ComputePackagingPriceFromVolumePrice(
        packaging,
        {
          ...baseUnit,
          unit_type: isBulk ? Base_Unit_Type_Enum.Bulk : baseUnit.unit_type,
        },
        packaging.base_unit_price,
      );
    }

    newPackagingPrice = round(newPackagingPrice);

    if (newPackagingPrice !== packaging.price) {
      setValue(`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.price`, newPackagingPrice, { shouldDirty: true });
      setValue(`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.base_unit_price`, packaging.base_unit_price);
    }
  }, [isBilledByWeight, formState, pricingFormDisabled, packaging.base_unit_price]);

  const priceFieldTitle = useMemo(() => {
    if (priceMeasurementUnit) {
      if (priceMeasurementUnit.toUpperCase() === Measurement_Unit_Enum.Unit) {
        return t('packaging:per_unit');
      }

      return t('packaging:per_measurement_unit', { measurementUnit: priceMeasurementUnit });
    }

    return t('products:base_unit_one');
  }, [priceMeasurementUnit]);

  return (
    <Box>
      <Typography variant="body1">
        {formattedPackagingTitle}
      </Typography>
      <Grid container sx={{ mt: 2 }} columnSpacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {priceFieldTitle}*
          </Typography>
          <Controller
            name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.base_unit_price`}
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
              min: { value: 0.01, message: t('validation:this_value_should_be_higher_than_N', { value: 0 }) },
              validate: {
                pattern: (value) =>
                  ValidatorsUtils.validatePrice(value as number) || t('validation:this_value_should_contain_only_2_decimals') as string,
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <OutlinedInput
                  disabled={pricingFormDisabled}
                  name={field.name}
                  value={field.value}
                  onChange={(event) => {
                    const parsedValue = parseFloat(event.target.value);

                    field.onChange(event);
                    setValue(`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.base_unit_price`, !Number.isNaN(parsedValue) ? parsedValue : 0);
                  }}
                  startAdornment={(
                    <InputAdornment position="start">

                      {priceMeasurementUnit
                        ? `€/${t(`packaging:${priceMeasurementUnit}`, { count: 1 })}`
                        : <EuroIcon sx={{ fontSize: '1rem' }} />}
                    </InputAdornment>
                  )}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0.01',
                  }}
                  fullWidth
                  type="number"
                  sx={{ height: 48 }}
                  placeholder={t('price:price_placeholder')}
                />
                {fieldState.error && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>{t('common:packaging_one')}*</Typography>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: 'secondary.light',
              alignItems: 'center',
              borderRadius: 2,
              height: 48,
            }}
          >
            <EuroIcon sx={{ fontSize: '1rem', mx: 1 }} />
            <Typography variant="body1">
              {packaging.price}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
