import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Global_Product_Family_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { productFamilyMap } from 'kheops-utils';
import { currentContextAtom } from '../../../state';
import { CompanyContactInfoQuery, useCompanyContactInfoQuery } from '../../../queries/__generated__/companyContactInfo.generated';
import CompanyContactList from '../../CompanyContactList';
import { PackagingFamilyTypeIntl } from '../../../common/i18n/product-family.translation';
import { packagingLogoMap } from '../../../common/utils/file.utils';

type ContactMap = {
  [key in Product_Sub_Family_Name_Enum]?: NonNullable<CompanyContactInfoQuery['buyingCompany']>['role_assignments'];
}

export interface ContactsByDepartmentProps {
  companyId: string;
  displayContactInfo?: boolean;
}

export default function ContactsByDepartment({ companyId, displayContactInfo }: ContactsByDepartmentProps): React.JSX.Element {
  const { t } = useTranslation(['product-family']);
  const context = useAtomValue(currentContextAtom);
  const { data: companyContactInfo } = useCompanyContactInfoQuery({
    variables: {
      buyingCompanyId: companyId,
      supplyingCompanyId: context.companyId!,
    },
  });

  const contactMap = useMemo((): ContactMap => {
    if (!companyContactInfo) {
      return {};
    }

    const { buyingCompany, supplyingCompany } = companyContactInfo;
    const supplierSubFamilies = supplyingCompany!.products.map(({ sub_family }) => sub_family) as Product_Sub_Family_Name_Enum[];

    return supplierSubFamilies.reduce((acc, supplierSubFamily) => {
      // Code is quite dense here, just filtering user that match supplier sub family
      const contacts = buyingCompany!.role_assignments.filter(({ product_families }) => {
        if (!product_families?.length) {
          return false;
        }

        const manageAllProductFamilies = product_families[0].global_setting === Global_Product_Family_Enum.All;
        const manageAnySupplierProductFamily = product_families.some(({ product_family, product_sub_family }) => {
          const subFamilies = product_family ? productFamilyMap[product_family] : [product_sub_family || ''];

          return subFamilies.includes(supplierSubFamily);
        });

        return manageAllProductFamilies || manageAnySupplierProductFamily;
      });

      if (contacts.length) {
        acc[supplierSubFamily] = contacts;
      }

      return acc;
    }, {} as ContactMap);
  }, [companyContactInfo]);

  if (!companyContactInfo) {
    return <></>;
  }

  if (!Object.keys(contactMap).length) {
    return (
      <CompanyContactList contacts={companyContactInfo.buyingCompany!.role_assignments.map((role) => role.user)} displayContactInfo={displayContactInfo} />
    );
  }

  return (
    <>
      {(Object.keys(contactMap) as Product_Sub_Family_Name_Enum[])
        .sort((a, b) => t(`product-family:${PackagingFamilyTypeIntl[a]}`).localeCompare(t(`product-family:${PackagingFamilyTypeIntl[b]}`)))
        .map((subFamily) => (
          <Box key={subFamily}>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 2, gap: 2 }}>
              <img src={packagingLogoMap[subFamily]} alt={subFamily} height="48" />
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 56 }}>
                <Typography variant="body1">
                  {t(`product-family:${PackagingFamilyTypeIntl[subFamily]}`)}
                </Typography>
                <Typography variant="subtitle2">
                  {t('chat:N_member', { count: contactMap[subFamily]!.length })}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mx: 1 }}>
              <CompanyContactList contacts={contactMap[subFamily]!.map((contact) => contact.user)} displayContactInfo={displayContactInfo} />
            </Box>
          </Box>
        ))}
    </>
  );
}
