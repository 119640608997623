import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { BusinessProfile, Document_Type_Enum } from 'kheops-graphql';
import { useUploadFile } from './useUploadFile';
import { OrderByReferenceIdDocument } from '../queries/__generated__/orderByReferenceId.generated';
import { useErrorSnackbar } from './useErrorSnackbar';
import { UpsertDocumentOfOrderMutation, useUpsertDocumentOfOrderMutation } from '../mutations/__generated__/upsertDocumentOfOrder.generated';
import { currentContextAtom } from '../state';

interface UseUploadDocumentProps {
  orderReferenceId: string;
  orderId: string;
  documentType: Document_Type_Enum;
}
interface UseUploadDocument {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  isDocumentUploadLoading: boolean;
  documentData: UpsertDocumentOfOrderMutation | null | undefined;
  reset: () => void;
}

export function useUploadDocument({ orderReferenceId, orderId, documentType }: UseUploadDocumentProps): UseUploadDocument {
  const { t } = useTranslation(['error']);
  const {
    handleFileChange,
    isFileUploadLoading,
    fileParameters,
    // here we need USER_INVOICE type to get the correct S3 bucket, since user generated invocies are stored in
    // a different bucket than invoices generated by Kheops
  } = useUploadFile({ type: documentType === Document_Type_Enum.Invoice ? 'USER_INVOICE' : documentType, pathId: orderReferenceId });
  const displayError = useErrorSnackbar(t('error:order_has_already_been_updated'));
  const [upsertDocumentOfOrder, { data, loading: isUpsertDocumentLoading, client, reset }] = useUpsertDocumentOfOrderMutation(
    {
      refetchQueries: [OrderByReferenceIdDocument],
      awaitRefetchQueries: true,
      onError: () => {
        displayError();
        client.refetchQueries({ include: [OrderByReferenceIdDocument] });
      },
    },
  );
  const isDocumentUploadLoading = useMemo(() => isFileUploadLoading || isUpsertDocumentLoading, [isUpsertDocumentLoading, isFileUploadLoading]);
  const { realm } = useAtomValue(currentContextAtom);

  useEffect(() => {
    if (fileParameters) {
      upsertDocumentOfOrder({
        variables: {
          data: {
            path: fileParameters.path,
            domain: fileParameters.domain!,
            type: documentType,
            userBusinessProfile: realm as unknown as BusinessProfile,
            orderId,
            orderReferenceId,
          },
        },
      });
    }
  }, [fileParameters]);

  return {
    handleFileChange,
    isDocumentUploadLoading,
    documentData: data,
    reset,
  };
}
