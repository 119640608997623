import { useTheme, Box, Typography, buttonClasses } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useMemo } from 'react';
import { Business_Profile_Enum } from 'kheops-graphql';
import OrderExportButton from './OrderExportButton';
import { orderAtom } from '../state/state';
import { currentContextAtom } from '../../state';
import ContractsUtils from '../../common/utils/contracts.utils';
import { RoutePaths } from '../../routes/AppRoutes';
import SizedImage from '../../common/components/SizedImage';
import FileUtils, { PhotoSizeFit } from '../../common/utils/file.utils';
import BrandBox from '../../common/components/BrandBox';
import BackButton from '../../common/components/BackButton';
import OrderSuggestionChip from './OrderSuggestionChip';
import Comments from './comments/Comments';
import OrderTotalPriceDisplay from './OrderTotalPriceDisplay';
import { getCompanyShortName } from '../../common/utils/common.utils';

export interface OrderHeaderProps {
  compact?: boolean;
}

export default function OrderHeader({ compact }: OrderHeaderProps): React.JSX.Element {
  const { realm } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['order', 'price']);
  const theme = useTheme();
  const order = useAtomValue(orderAtom);

  const otherCompanyRealm = useMemo(
    () => (realm === Business_Profile_Enum.Buyer ? Business_Profile_Enum.Supplier : Business_Profile_Enum.Buyer),
    [realm],
  );

  const otherCompany = ContractsUtils.getOtherCompany(order.contract, realm);
  const companyLink = useMemo(() => {
    return generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: otherCompany.id });
  }, [otherCompany]);

  const companyImage = useMemo(() => {
    const size = compact
      ? { width: 112, height: 112, fit: 'cover' as PhotoSizeFit }
      : { width: 389, height: 156, fit: 'cover' as PhotoSizeFit };

    return {
      photo: otherCompany.photos[0]?.photo,
      type: otherCompanyRealm,
      size,
    };
  }, [otherCompany, compact]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: compact ? 'row' : 'column',
            gap: 2,
            position: 'relative',
          }}
        >
          {!compact && (
            <SizedImage
              src={FileUtils.BuildCompanyImageUrl(companyImage)}
              height={156}
              width={389}
              borderRadius={0}
              alt={t('common:company')}
              sx={{
                '& .Kheops-brand': {
                  position: 'absolute',
                  bottom: theme.spacing(2),
                  right: theme.spacing(2),
                  borderRadius: 6,
                  width: {
                    xs: 40,
                    lg: 56,
                  },
                  height: {
                    xs: 40,
                    lg: 56,
                  },
                },
                '& .Kheops-brandLogo': {
                  width: {
                    xs: 24,
                    lg: 32,
                  },
                },
              }}
              overlay={(
                <>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: theme.spacing(2),
                      left: theme.spacing(2),
                      zIndex: 10,
                    }}
                  >
                    <BackButton to={RoutePaths.ORDER_LIST} sx={{ width: 40, height: 40 }} compact />
                  </Box>
                  {order.suggestor_id && <OrderSuggestionChip sx={{ position: 'absolute', bottom: theme.spacing(3), left: theme.spacing(2) }} />}
                  {otherCompany.brand && <BrandBox brand={otherCompany.brand} />}
                </>
              )}
            />
          )}
          {compact && (
            <Link to={companyLink}>
              <SizedImage
                src={FileUtils.BuildCompanyImageUrl(companyImage)}
                sx={{
                  '& .Kheops-brand': {
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                  },
                }}
                width={112}
                borderRadius={4}
                height={112}
                alt={t('common:company')}
                overlay={
                  otherCompany.brand && <BrandBox brand={otherCompany.brand} />
                }
              />
            </Link>
          )}
          <Box sx={{ display: 'flex', px: compact ? 0 : 2, gap: 2, flex: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                flex: 1,
              }}
            >
              <Typography color="primary" variant="body1" sx={{ backgroundColor: 'primary.light', width: 'fit-content' }}>
                # {order.friendly_id}
              </Typography>
              <Typography
                variant="h5"
                component={Link}
                to={companyLink}
                sx={{
                  lineHeight: 'normal',
                  color: 'inherit',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {getCompanyShortName(otherCompany.tradeName, otherCompanyRealm)}
              </Typography>
              <OrderTotalPriceDisplay />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <OrderExportButton
                orderReferenceId={order.reference_id}
                orderFriendlyId={order.friendly_id}
                orderId={order.id}
                sx={{
                  minWidth: 24,
                  p: 1,
                  borderColor: 'primary.main',
                  [`& .${buttonClasses.startIcon}`]: {
                    m: 0,
                  },
                }}
              />
              {compact && (<Comments compact />)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
