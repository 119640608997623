import { Box, Card, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Billing_Type_Enum, Business_Profile_Enum, Order_Status_Enum } from 'kheops-graphql';
import { currentContextAtom } from '../../state';
import { orderAtom, viewOrderItemsAtom } from '../state/state';
import PackagingDisplayBox from '../../common/components/PackagingDisplayBox';

export default function OrderPackagingWeightUpdate(): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const { realm } = useAtomValue(currentContextAtom);
  const order = useAtomValue(orderAtom);
  const items = useAtomValue(viewOrderItemsAtom);

  const weightDependentPackagings = useMemo(() => {
    return items.filter((item) => item.packaging.base_unit?.billing_type === Billing_Type_Enum.WeightDependent);
  }, [items]);

  if (realm === Business_Profile_Enum.Buyer || !weightDependentPackagings.length || (order.status !== Order_Status_Enum.OrderToBeDeliveredBySupplier && order.status !== Order_Status_Enum.OrderToBePreparedBySupplier)) {
    return <></>;
  }

  return (
    <Card>
      <Typography variant="h6">
        {t('order:verify_weight_dependent_packagings')}
      </Typography>
      <Box>
        {weightDependentPackagings.map(({ packaging }, index) => (
          <PackagingDisplayBox
            orderId={order.id}
            packagingDisplay={packaging}
            orderStatus={order.status}
            canEditWeight
            canEditQuantity
            shouldEditWeightBeEnabledByDefault={order.status === Order_Status_Enum.OrderToBePreparedBySupplier}
            index={index}
          />
        ))}
      </Box>
    </Card>
  );
}
