import DocViewer from '@cyntler/react-doc-viewer';
import { styled } from '@mui/material';

const DocumentPreviewer = styled(DocViewer)(({ theme }) => ({
  '#pdf-controls, #header-bar': {
    display: 'none',
  },
  '#image-renderer': {
    backgroundImage: 'none',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 14,
  },
  borderRadius: 14,
  background: `${theme.palette.secondary.light} !important`,
}));

export default DocumentPreviewer;
