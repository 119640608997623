import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { fr } from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers-pro/locales';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import useAuthProvider from './hooks/useAuth/useAuthProvider';
import { CustomApolloProvider } from './providers/CustomApolloProvider';
import CustomThemeProvider from './providers/CustomThemeProvider';
import useKheopsRoutes from './hooks/useKheopsRoutes';

// Set Mui Data Grid Pro X license
LicenseInfo.setLicenseKey(
  // eslint-disable-next-line max-len
  '7a208bfe0b54c2e0e8b165bdc8cd0fc1Tz01MjY4NyxFPTE2OTc3MTY1MjgwMjcsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);

export default function App(): React.JSX.Element {
  const routes = useKheopsRoutes();
  const router = createBrowserRouter(routes);

  useAuthProvider();

  return (
    <Suspense fallback={<CircularProgress />}>
      <CustomApolloProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
          <CustomThemeProvider>
            <GoogleOAuthProvider clientId={import.meta.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <RouterProvider router={router} />
            </GoogleOAuthProvider>
          </CustomThemeProvider>
        </LocalizationProvider>
      </CustomApolloProvider>
    </Suspense>
  );
}
