import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InstantSearch } from 'react-instantsearch';
import AddIcon from '@mui/icons-material/Add';
import { useSearchClient } from '../../hooks/useSearchClient';
import AddPackagingsDialog from './AddPackagingsDialog';

interface AddPackagingsButtonProps {
  companyId: string;
  excludedObjectIds: string[];
}
export default function AddPackagingsButton({ companyId, excludedObjectIds }: AddPackagingsButtonProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'discovery', 'common']);
  const filters = excludedObjectIds.map((id) => `NOT objectID:${id}`).join(' AND ');
  const [isAddPackagingModalOpen, setIsAddPackagingModalOpen] = useState(false);
  const searchClient = useSearchClient();

  const handleButtonClick = (): void => {
    setIsAddPackagingModalOpen(true);
  };

  return (
    <InstantSearch
      indexName={import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX}
      searchClient={searchClient}
    >
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        color="primary"
        onClick={handleButtonClick}
      >
        {t('products:add_a_base_unit')}
      </Button>
      <AddPackagingsDialog
        companyId={companyId}
        filters={filters}
        isOpen={isAddPackagingModalOpen}
        setIsOpen={setIsAddPackagingModalOpen}
      />
    </InstantSearch>
  );
}
