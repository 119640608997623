import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import CSUPackagingForm from './CSUPackagingForm';
import WeightPackagingForm from './WeightPackagingForm';
import { packagingDefaultValue } from '../../hooks/usePackagingsFormDefaultValues';

interface PackagingFormProps {
  baseUnitIndex: number;
}

export default function PackagingForm({ baseUnitIndex }: PackagingFormProps): React.JSX.Element {
  const { watch, control } = useFormContext<ProductPackagingsFormData>();
  const { t } = useTranslation(['common', 'products']);
  const { fields: packagings, append, remove } = useFieldArray({
    control,
    name: `productPackagings.${baseUnitIndex}.packagings`,
  });
  const baseUnitType = watch(`productPackagings.${baseUnitIndex}.csu.csu_type`);

  const handleAddPackaging = useCallback((): void => {
    append(packagingDefaultValue);
  }, []);

  const handleRemovePackaging = useCallback((packagingIndex: number): void => {
    remove(packagingIndex);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
      <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
        {t('products:packagings')}
      </Typography>
      {
        packagings.map((packaging, packagingIndex) => {
          const packagingForm = baseUnitType === 'UNIT'
            ? <CSUPackagingForm baseUnitIndex={baseUnitIndex} packagingIndex={packagingIndex} />
            : <WeightPackagingForm baseUnitIndex={baseUnitIndex} packagingIndex={packagingIndex} />;

          return (
            <React.Fragment key={packaging.id}>
              {packagingForm}
              <Button
                disabled={packagings.length === 1}
                variant="outlined"
                color="error"
                onClick={() => handleRemovePackaging(packagingIndex)}
                startIcon={<DeleteOutlinedIcon />}
                sx={{
                  alignSelf: 'center',
                  px: 5,
                  mb: packagingIndex === packagings.length - 1 ? 1 : 2,
                }}
              >
                {t('common:delete')}
              </Button>
            </React.Fragment>
          );
        })
      }
      <Button
        variant="outlined"
        onClick={handleAddPackaging}
        startIcon={<AddIcon />}
        sx={{
          alignSelf: 'center',
        }}
      >
        {t('products:add_a_packaging')}
      </Button>
    </Box>
  );
}
