import { useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useEffect, useState } from 'react';
import { useConfigure, useRefinementList } from 'react-instantsearch';
import type { PlainSearchParameters } from 'algoliasearch-helper';
import { HistoryIndexUiState, configureRefineAtom, uiStateAtom } from './state/state';
import { currentContextAtom } from '../state';

interface SearchConfigureProps {
  children: ReactNode;
  index: string;
  filters?: string;
}

// Each use of useConfigure returns a new instance of refine which disallows instantsearch
// state sharing between components. Set it into an atom fix this issue.
export default function SearchConfigure({ children, index, filters }: SearchConfigureProps): React.JSX.Element {
  const { companyGeoloc } = useAtomValue(currentContextAtom);
  const defaultSearchParameters: PlainSearchParameters = {
    hitsPerPage: 36,
    getRankingInfo: true,
    aroundLatLng: `${companyGeoloc!.lat}, ${companyGeoloc!.lng}`,
    aroundRadius: 'all',
    filters,
  };
  const initialSearchParameters = {
    ...defaultSearchParameters,
    ...(window.history.state.uiState?.[index] as HistoryIndexUiState)?.configure,
  };
  const [useConfigureSearchParameters, setUseConfigureSearchParameters] = useState(initialSearchParameters);
  const { refine } = useConfigure(useConfigureSearchParameters);
  const { refine: fakeRefine } = useRefinementList({ attribute: 'photo.domain' });
  const setConfigureRefine = useSetAtom(configureRefineAtom);
  const setUiState = useSetAtom(uiStateAtom);

  useEffect(() => {
    const defaultRefine = (searchParameters: PlainSearchParameters): void => {
      const newSearchParameters = {
        ...defaultSearchParameters,
        ...searchParameters,
      };

      setUseConfigureSearchParameters(newSearchParameters);
      refine(newSearchParameters);

      // Triggers a refine on a facet value without any filtering purpose to force instantsearch-hooks to be updated
      // because of this issue: https://github.com/algolia/react-instantsearch/issues/3490
      fakeRefine('');
    };

    setConfigureRefine({ refine: defaultRefine });
    setUiState(initialSearchParameters);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  );
}
