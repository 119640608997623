import { ResponsiveStyleValue } from '@mui/system';
import { SvgIcon, SxProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Contract_Status_Enum } from 'kheops-graphql';
import InWaitContractIcon from '../assets/icons/inwait_contract.svg?react';

interface ContractStatusTagProps {
  status: Contract_Status_Enum;
  size?: number | ResponsiveStyleValue<number>;
  sx?: SxProps;
}

export default function ContractStatusTag({ status, size, sx }: ContractStatusTagProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const iconSx = {
    height: size || 18,
    width: size || 18,
    ...sx,
  };

  return (
    status === Contract_Status_Enum.Signed
      ? (
        <Tooltip title={t('discovery:already_contractualized_supplier')}>
          <VerifiedIcon sx={iconSx} fontSize="small" color="primary" />
        </Tooltip>
      )
      : (
        <Tooltip title={t('discovery:supplier_with_pending_contract_signature')}>
          <SvgIcon
            component={InWaitContractIcon}
            viewBox="0 0 18 18"
            sx={{
              color: 'text.secondary',
              fill: 'none',
              ...iconSx,
            }}
          />
        </Tooltip>
      )
  );
}
