import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import { AlgoliaHit } from 'instantsearch.js';
import { SupplierHit } from '../../search/search';
import DiscoveryCompanyHit from './DiscoveryCompanyHit';
import DiscoveryFilters from './DiscoveryFilters';
import i18n from '../../i18n';

export default function DiscoveryCompanyHits(): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  // eslint-disable-next-line deprecation/deprecation
  const { hits, results } = useHits<AlgoliaHit<SupplierHit>>();
  const format = new Intl.NumberFormat(i18n.resolvedLanguage);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {!!results && (
        <>
          <Typography variant="h5">
            {t('discovery:N_supplier', { count: results.nbHits, formattedCount: format.format(results.nbHits) })}
          </Typography>
          <DiscoveryFilters
            fullTextSearchPlaceholder={t('discovery:search_by_name')}
            contractFilterAttribute="partners"
            navigationAttribute="product_families"
            clearAttributes={[
              'administrative_areas',
            ]}
            hideLabelsFilter
            searchMode="COMPANY"
            index={import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX}
          />
          <Grid container rowSpacing={2} columnSpacing={2}>
            {hits.map((hit) => (
              <Grid key={hit.objectID} item xs={12} sm={6} md={4} xl={3}>
                <DiscoveryCompanyHit hit={hit} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}
