import { Box, FormControlLabel, Switch } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Billing_Type_Enum } from 'kheops-graphql';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BlackBackgroundTooltip from '../../../common/components/BlackBackgroundTooltip';
import { ProductPackagingsFormData } from '../ProductFormPackagings';

interface BillingTypeSwitchProps {
  name: `productPackagings.${number}.csu.billing_type`;
}

export default function BillingTypeSwitch({ name }: BillingTypeSwitchProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const { control } = useFormContext<ProductPackagingsFormData>();

  const transformInput = useCallback((value: Billing_Type_Enum): boolean => {
    return value === Billing_Type_Enum.WeightDependent;
  }, []);

  const transformOutput = useCallback((value: boolean): Billing_Type_Enum => {
    return value ? Billing_Type_Enum.WeightDependent : Billing_Type_Enum.Fixed;
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            sx={{ ml: 0, mr: 1 }}
            control={(
              <Switch
                {...field}
                onChange={(event, checked) => {
                  const newValue = transformOutput(checked);

                  field.onChange(newValue);
                }}
                checked={transformInput(field.value)}
              />
            )}
            label={t('products:billed_per_weight')}
          />
        )}
      />
      <BlackBackgroundTooltip title={t('products:billed_per_weight_help_message')} placement="right-start">
        <HelpOutlineIcon fontSize="small" sx={{ color: 'text.secondary', fontSize: '0.875rem' }} />
      </BlackBackgroundTooltip>
    </Box>
  );
}
