import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Document_Type_Enum } from 'kheops-graphql';

const DELETE_DOCUMENT_DIALOG_TITLE_KEYS: {[key in Document_Type_Enum]?: string} = {
  [Document_Type_Enum.Invoice]: 'delete_invoice',
  [Document_Type_Enum.PurchaseOrder]: 'delete_purchase_order',
  [Document_Type_Enum.DeliveryNote]: 'delete_delivery_note',
};

interface DocumentDeleteDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  documentType: Document_Type_Enum;
}

export default function DocumenDeleteDialog({ documentType, open, onClose }:DocumentDeleteDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);

  const handleCancel = (): void => {
    onClose(false);
  };
  const handleConfirm = (): void => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 800,
          },
        },
      }}
      open={open}
    >
      <DialogTitle>
        <Typography variant="h2">
          {t(`order:${DELETE_DOCUMENT_DIALOG_TITLE_KEYS[documentType]}`)}
        </Typography>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {t('order:delete_document_message')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="inherit" variant="text">
          {t('common:quit')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="error"
        >
          {t('common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
