import { Avatar, Box, SxProps } from '@mui/material';
import { Brand_Enum, Maybe } from 'kheops-graphql';
import FileUtils from '../utils/file.utils';
import { UserAvatars } from '../models/user.models';

interface KheopsAvatarProps {
  userAvatars: UserAvatars;
  brand?: Maybe<Brand_Enum>;
  sx?: SxProps;
  size: number;
}

export default function KheopsAvatar(
  {
    size,
    userAvatars: { auth_provider_avatar_url, default_avatar },
    brand,
    sx,
  }: KheopsAvatarProps,
): React.JSX.Element {
  return (
    <>
      <Avatar
        sx={{
          width: size,
          height: size,
          borderRadius: 4,
          ...sx,
        }}
        src={
          auth_provider_avatar_url
          || FileUtils.BuildImageURL(default_avatar!, { width: 128, height: 128 }, 'png')
        }
        variant="rounded"
      />
      {!!brand && (
        <Box
          sx={{
            position: 'absolute',
            borderRadius: 4,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            height: 24,
            width: 24,
            padding: 0.5,
            boxSizing: 'border-box',
          }}
        >
          <Box
            component="img"
            src={FileUtils.BuildBrandLogoURL(brand)}
            alt="logo"
            loading="lazy"
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </Box>
      )}
    </>
  );
}
