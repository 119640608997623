import { OutlinedInput, OutlinedInputProps, SxProps } from '@mui/material';
import { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import PhoneInputWithCountrySelect, { ReactHookFormComponentProps } from 'react-phone-number-input/react-hook-form';
import CountriesPhoneCodeSelect from './CountriesPhoneCodeSelect';

interface CustomPhoneNumberInputProps {
  formProps: ReactHookFormComponentProps<FieldValues>;
  inputPlaceholder?: string;
  selectSx?: SxProps;
  inputSx?: SxProps;
  inputProps?: OutlinedInputProps;
}

export default function CustomPhoneNumberInput({ formProps, selectSx, inputSx, inputProps, inputPlaceholder }: CustomPhoneNumberInputProps): React.JSX.Element {
  const ForwardedInputComponent = useMemo(() => {
    return forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
      (props, ref) => {
        const { onChange, value, type } = props;

        return (
          <OutlinedInput
            inputRef={ref}
            onChange={onChange}
            value={value}
            type={type}
            sx={inputSx}
            placeholder={inputPlaceholder}
            {...inputProps}
          />
        );
      },
    );
  }, [inputProps?.disabled]);

  return (
    <PhoneInputWithCountrySelect
      {...formProps}
      defaultCountry="FR"
      initialValueFormat="national"
      inputComponent={ForwardedInputComponent}
      countrySelectComponent={CountriesPhoneCodeSelect}
      countrySelectProps={{
        disabled: inputProps?.disabled,
        selectSx,
      }}
      style={{
        display: 'flex',
        gap: 8,
      }}
    />
  );
}
