import { Chip, ChipProps, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import i18n from '../../i18n';
import BlackBackgroundTooltip from './BlackBackgroundTooltip';

interface DiscountChipProps {
  discount: number;
  toolTipEnabled?: boolean;
  sx?: SxProps;
  color?: ChipProps['color'];
}
export default function DiscountChip({ discount, sx, toolTipEnabled = false, color = 'layout' }: DiscountChipProps): React.JSX.Element {
  const percentFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'percent', maximumFractionDigits: 2 });
  const { t } = useTranslation('common');

  return (
    <BlackBackgroundTooltip title={t('contracts:discount')} disableHoverListener={!toolTipEnabled}>
      <Chip
        label={`-${percentFormatter.format(discount)}`}
        icon={<DiscountIcon />}
        color={color}
        size="small"
        sx={sx}
      />
    </BlackBackgroundTooltip>
  );
}
