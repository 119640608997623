import { Box, Grid } from '@mui/material';
import KheopsLogo from '../assets/logo/kheops-logo-full-black.svg?react';
import HeroImage from '../assets/photos/bg-fruits.webp';
import { useRedirectIfAuth } from '../hooks/useRedirectIfAuth';
import LoginForm from './LoginForm';

export default function LoginPage(): React.JSX.Element {
  const RedirectIfAuth = useRedirectIfAuth();

  return (
    <RedirectIfAuth>
      <Grid container sx={{ backgroundColor: 'white' }}>
        <Grid
          item
          xs={7}
          sx={{
            position: 'sticky',
            top: 0,
            borderBottomRightRadius: '148px',
            overflow: 'hidden',
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
          height="100vh"
        >
          <Box component="img" src={HeroImage} sx={{ minWidth: '100%', objectFit: 'cover' }} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={5}
          sx={{
            flexDirection: 'column',
            paddingTop: { xs: 4, sm: 9 },
            flexWrap: 'nowrap',
            backgroundColor: 'white',
            height: '100vh',
          }}
        >
          <Grid item sx={{ textAlign: 'center', mb: 0, flex: 'none', pt: 6 }}>
            <Box component={KheopsLogo} height="52px" width="100%" />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
            <LoginForm />
          </Grid>
        </Grid>
      </Grid>
    </RedirectIfAuth>
  );
}
