import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useAtomValue } from 'jotai';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import ProductSearchBar from '../../products/common/ProductSearchBar';
import { companyPackagingsAtom } from '../state';
import PackagingsPricesFormRow from './PackagingsPricesFormRow';
import { insensitiveStringIncludes } from '../../common/utils/common.utils';

export default function PackagingsPricesForm(): React.JSX.Element {
  const { t } = useTranslation(['common', 'products', 'price']);
  const breakpoint = useCurrentBreakpoint();
  const companyPackagings = useAtomValue(companyPackagingsAtom);
  const [filteredProducts, setFilteredProducts] = useState(companyPackagings);

  const productFamiliesOptions = useMemo((): Product_Sub_Family_Name_Enum[] => {
    const options = new Set<Product_Sub_Family_Name_Enum>();

    companyPackagings.forEach(({ product }) => options.add(product.sub_family as Product_Sub_Family_Name_Enum));

    return [...options.values()];
  }, [companyPackagings]);

  const virtualItemSize = useMemo((): number => {
    switch (breakpoint) {
      case 'xs':
        return 261;
      case 'sm':
      case 'md':
        return 239;
      default:
        return 115;
    }
  }, [breakpoint]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const VirtualRow: any = useCallback(({ index, style }: any) => (
    <div style={style}>
      <PackagingsPricesFormRow packaging={filteredProducts[index]} />
    </div>
  ), [filteredProducts]);

  const handleProductSearchChange = useCallback((productFamilies: Product_Sub_Family_Name_Enum[], query: string): void => {
    const newFilteredProducts = companyPackagings.filter((packaging) => {
      const matchFamilies = !productFamilies.length || productFamilies.includes(packaging.product.sub_family as Product_Sub_Family_Name_Enum);
      const matchSearch = !query || insensitiveStringIncludes(packaging.product.name, query) || packaging.base_unit.gtin === query;

      return matchFamilies && matchSearch;
    });

    setFilteredProducts(newFilteredProducts);
  }, [companyPackagings]);

  useEffect(() => {
    setFilteredProducts(companyPackagings);
  }, [companyPackagings]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          {t('common:product_other')}
        </Typography>
        <ProductSearchBar
          productFamiliesOptions={productFamiliesOptions}
          onChange={handleProductSearchChange}
          sx={{
            mb: {
              xs: 3,
              lg: 0,
            },
          }}
        />
        <Grid
          container
          columnSpacing={1}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
            '& .MuiGrid-item': {
              py: 2,
            },
          }}
        >
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              {t('products:base_unit_one')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t('price:packaging_price_without_tax')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t('price:catalog_price_without_tax')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ height: '100%' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Grid
              container
              sx={{
                '& > .MuiGrid-item:last-of-type .MuiDivider-root': {
                  display: 'none',
                },
              }}
            >
              <FixedSizeList
                height={height}
                width="100%"
                itemSize={virtualItemSize}
                itemCount={filteredProducts.length}
                overscanCount={5}
              >
                {VirtualRow}
              </FixedSizeList>
            </Grid>
          )}
        </AutoSizer>
      </Box>
    </>
  );
}
