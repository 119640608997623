"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chat_Message_Custom_Type = void 0;
var Chat_Message_Custom_Type;
(function (Chat_Message_Custom_Type) {
    Chat_Message_Custom_Type["OFFER"] = "OFFER";
    Chat_Message_Custom_Type["ACCEPTED_OFFER"] = "ACCEPTED_OFFER";
    Chat_Message_Custom_Type["JOINED"] = "JOINED";
    Chat_Message_Custom_Type["CREATED"] = "CREATED";
    Chat_Message_Custom_Type["LEFT"] = "LEFT";
    Chat_Message_Custom_Type["INVITED"] = "INVITED";
})(Chat_Message_Custom_Type || (exports.Chat_Message_Custom_Type = Chat_Message_Custom_Type = {}));
