import { Box, Typography } from '@mui/material';

interface ImageDarkOverlayProps {
  label: string;
}

export default function ImageDarkOverlay({ label }: ImageDarkOverlayProps): React.JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 5,
      }}
    >
      <Typography
        variant="body1"
        color="white"
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          px: 4,
          boxSizing: 'border-box',
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
