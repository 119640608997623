import { Badge, Box, Typography } from '@mui/material';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { useTranslation } from 'react-i18next';
import { PushTriggerOption } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { UserMessage } from '@sendbird/chat/message';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { currentContextAtom, userAtom } from '../state';
import { useCompanyChannelInfoByIdQuery } from '../queries/__generated__/companyChannelInfoById.generated';
import { GroupChannelMetadata, currentChannelAtom } from './state';
import Utils from '../common/utils/common.utils';
import ChannelCompanyImage from './ChannelCompanyImage';

export interface ChannelListPreviewProps {
  channel: GroupChannel;
  onSelectChannel: () => void;
}

export default function ChannelListPreview({ channel, onSelectChannel }: ChannelListPreviewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'chat']);
  const { id: userId } = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const { data: companyChannelInfoData } = useCompanyChannelInfoByIdQuery({
    variables: {
      companyId: realm === Business_Profile_Enum.Buyer
        ? (channel.cachedMetaData as GroupChannelMetadata).supplyingCompanyId
        : (channel.cachedMetaData as GroupChannelMetadata).buyingCompanyId,
    },
  });
  const currentChannel = useAtomValue(currentChannelAtom);
  const shouldSeeDot = channel.myPushTriggerOption !== PushTriggerOption.OFF && channel.unreadMessageCount;

  return (
    <Box
      onClick={() => onSelectChannel()}
      sx={{
        display: 'flex',
        borderRadius: 2,
        p: 1,
        cursor: 'pointer',
        backgroundColor: channel.url === currentChannel?.url ? 'rgba(0, 0, 0, 0.04)' : 'background.paper',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      {companyChannelInfoData?.company_by_pk && (
        <Badge color="primary" variant="dot" invisible={!shouldSeeDot} sx={{ flexShrink: 1, width: '100%' }}>
          <ChannelCompanyImage
            company={{
              businessProfile: companyChannelInfoData.company_by_pk.business_profile,
              photo: companyChannelInfoData.company_by_pk.photos[0]?.photo,
              brand: companyChannelInfoData.company_by_pk.brand,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              width: 'calc(100% - 56px)',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 0.5,
              ml: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle1" sx={{ lineHeight: '1rem' }}>
                {companyChannelInfoData.company_by_pk.tradeName}
              </Typography>
              {channel.myPushTriggerOption === 'off' && (
                <NotificationsOffOutlinedIcon sx={{ width: 18, height: 18, color: 'text.secondary' }} />
              )}
            </Box>
            {channel.lastMessage && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'space-between',
                  '.MuiTypography-root': channel.unreadMessageCount
                    ? {
                      fontWeight: 600,
                      color: 'text.primary',
                    }
                    : undefined,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    flex: 1,
                    lineHeight: '1rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mr: 0.5,
                  }}
                >
                  {/* Cast as UserMessage because sendbird definition uses BaseMessage instead even though it is actually a UserMessage */}
                  {(channel.lastMessage as UserMessage).sender.userId === userId && t('chat:you')}
                  {(channel.lastMessage as UserMessage).message}
                </Typography>
                <Typography variant="subtitle2" sx={{ lineHeight: '1rem' }}>
                  {Utils.formatTimeAgo(new Date(channel.lastMessage.createdAt), { style: 'short' })}
                </Typography>
              </Box>
            )}
          </Box>
        </Badge>
      )}
    </Box>
  );
}
