import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { DiscoveryView, discoveryViewAtom } from './state';

const SwitchButtonStyle: SxProps<Theme> = {
  width: 108,
  fontSize: '0.875rem',
  px: 0,
  '&:hover': {
    backgroundColor: 'unset',
  },
};

const SelectedViewButtonStyle: SxProps<Theme> = {
  ...SwitchButtonStyle,
  backgroundColor: 'common.black',
  color: 'white',
  '&:hover': {
    backgroundColor: 'common.black',
  },
};

const NonSelectedViewButtonStyle: SxProps<Theme> = {
  ...SwitchButtonStyle,
  backgroundColor: 'layout.main',
  color: 'text.primary',
};

export default function DiscoveryViewSwitch(): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery', 'search']);
  const [discoveryView, setDiscoveryView] = useAtom(discoveryViewAtom);
  const getViewButtonStyle = (view: DiscoveryView): SxProps<Theme> => {
    return discoveryView === view ? SelectedViewButtonStyle : NonSelectedViewButtonStyle;
  };

  return (
    <Box
      sx={{
        display: {
          xs: 'flex',
          sm: 'none',
        },
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Typography variant="subtitle1">
        {t('discovery:browse_by')}
      </Typography>
      <Box sx={{ height: 40, display: 'flex', alignSelf: 'start', borderRadius: 10, backgroundColor: 'layout.main' }}>
        <Button
          variant="contained"
          sx={{ ...getViewButtonStyle('PRODUCTS') }}
          onClick={() => setDiscoveryView('PRODUCTS')}
        >
          {t('common:product_other')}
        </Button>
        <Button
          variant="contained"
          sx={{ ...getViewButtonStyle('SUPPLIERS') }}
          onClick={() => setDiscoveryView('SUPPLIERS')}
        >
          {t('search:suppliers')}
        </Button>
      </Box>
    </Box>
  );
}
