import { Business_Profile_Enum } from 'kheops-graphql';

type DiscoveryWordingMap = {
  [profile in Business_Profile_Enum]: {
    [key: string]: string
  }
}

export const DiscoveryWordingsKeys: DiscoveryWordingMap = {
  [Business_Profile_Enum.Buyer]: {
    header: 'discovery:find_new_suppliers',
    geoloc_radius_label: 'distance_search_around_my_shop',
    contract_status_label: 'common:supplier_other',
    contract_status_contractualized_option: 'discovery:contractualized_suppliers_only',
    contract_status_all_option: 'discovery:contractualized_and_non_contractualized',
    sorted_by_distance: 'discovery:sorted_by_distance_from_your_shop',
    delivery_zone_option: 'discovery:only_suppliers_can_deliver',
    delivery_zone_tag: 'discovery:that_deliver_my_area',
    out_of_delivery_zones: 'discovery:dont_deliver_in_your_area',
  },
  [Business_Profile_Enum.Supplier]: {
    header: 'discovery:find_new_buyers',
    geoloc_radius_label: 'distance_search_around_my_company',
    contract_status_label: 'common:buyer_other',
    contract_status_contractualized_option: 'discovery:contractualized_buyers_only',
    contract_status_all_option: 'discovery:contractualized_and_non_contractualized',
    search_by_name: 'discovery:search_by_buyer_name',
    n_result: 'discovery:N_buyer',
    sorted_by_distance: 'discovery:sorted_by_distance_from_your_company',
    delivery_zone_option: 'discovery:only_buyers_i_can_deliver',
    out_of_delivery_zones: 'discovery:out_of_your_delivery_zones',
  },
};

export const isVisibleInDiscoveryFilter = 'is_visible_in_discovery:true';
