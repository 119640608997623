import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import { useProductSubFamiliesByFamilyQuery } from '../../queries/__generated__/productSubFamiliesByFamily.generated';
import ProductFamilyChip from './ProductFamilyChip';
import { ProductFamilySettings, productFamiliesSettingsAtom } from '../state';

interface ProductFamiliesSummaryProps {
  productFamily: Product_Family_Name_Enum;
  selectedOnly?: boolean;
}

export default function ProductFamilyChips({ productFamily, selectedOnly }: ProductFamiliesSummaryProps): React.JSX.Element {
  const { t } = useTranslation(['product-family', 'settings']);
  const { data: productSubFamiliesData } = useProductSubFamiliesByFamilyQuery({ variables: { productFamily } });
  const [productFamilySettings, setProductFamilySettings] = useAtom(productFamiliesSettingsAtom);

  const isSettingActive = useCallback((value: Product_Sub_Family_Name_Enum): boolean => {
    return !!productFamilySettings.product_sub_families?.has(value);
  }, [productFamilySettings]);

  const toggleSetValue = <T, >(set: Set<T>, toggleValue: T): Set<T> => {
    if (set.delete(toggleValue)) {
      return set;
    }

    return set.add(toggleValue);
  };

  const toggleProductSubFamily = useCallback((value: Product_Sub_Family_Name_Enum): void => {
    const currentSubFamilies = productFamilySettings.product_sub_families;
    const newSubFamilies = !currentSubFamilies
      ? new Set<Product_Sub_Family_Name_Enum>([value])
      : toggleSetValue(currentSubFamilies, value);

    const newProductFamilySettings: ProductFamilySettings = {
      product_sub_families: newSubFamilies,
    };

    setProductFamilySettings(newProductFamilySettings);
  }, [productFamilySettings]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 2,
        mt: 2,
      }}
    >
      {
        productSubFamiliesData?.product_sub_family.reduce<React.JSX.Element[]>((acc, subFamily) => {
          const selected = isSettingActive(subFamily.name);

          if (!selectedOnly || selected) {
            acc.push(
              <ProductFamilyChip
                key={subFamily.name}
                label={t(`product-family:${PackagingFamilyTypeIntl[subFamily.name]}`)}
                selected={selected}
                onClick={!selectedOnly ? () => toggleProductSubFamily(subFamily.name) : undefined}
              />,
            );
          }

          return acc;
        }, [] as React.JSX.Element[])
      }
    </Box>
  );
}
