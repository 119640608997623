import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { InstantSearch } from 'react-instantsearch';
import { useSearchClient } from '../../hooks/useSearchClient';
import { currentContextAtom } from '../../state';
import FilterUtils from '../../search/filters/filter.utils';
import SearchConfigure from '../../search/SearchConfigure';
import SearchPagination from '../../search/SearchPagination';
import useUpdateUiState from '../hooks/useUpdateHistoryUiState';
import DiscoveryCompanyHits from './DiscoveryCompanyHits';
import DiscoveryCarrousel from '../carrousel/DiscoveryCarrousel';
import ProductSlide from '../carrousel/ProductSlide';
import DiscoveryViewSwitch from '../DiscoveryViewSwitch';
import DiscoveryNavigationWrapper from '../navigation/DiscoveryNavigationWrapper';
import useDiscoveryDefaultFilters from '../../hooks/useDiscoveryDefaultFilters';
import { isVisibleInDiscoveryFilter } from '../../search/discovery.utils';

export default function SupplierDiscovery(): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const searchClient = useSearchClient();
  const index = import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX;
  const { companyId } = useAtomValue(currentContextAtom);
  const { initialIndexUiState, updateUiState } = useUpdateUiState({ index });

  const defaultFilters = useDiscoveryDefaultFilters();
  const filters = `${defaultFilters} AND ${FilterUtils.GetSignedContractFilter('partners', companyId)}`;
  const carrouselFilters = `${isVisibleInDiscoveryFilter} AND ${FilterUtils.GetSignedContractFilter('buyers', companyId)}`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {(!isXs && carrouselFilters) && (
        <DiscoveryCarrousel
          index={import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX}
          filters={carrouselFilters}
          slide={ProductSlide}
          attribute="family"
        />
      )}
      <InstantSearch
        indexName={index}
        searchClient={searchClient}
        initialUiState={{
          [index]: initialIndexUiState,
        }}
        onStateChange={updateUiState}
      >
        <SearchConfigure filters={filters} index={index}>
          <DiscoveryNavigationWrapper attribute="product_families">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                borderRadius: {
                  xs: 0,
                  sm: 6,
                },
                py: 3,
                px: {
                  xs: 2,
                  sm: 3,
                },
                backgroundColor: 'white',
                mx: {
                  xs: -1,
                  sm: 0,
                },
              }}
            >
              {isXs && <DiscoveryViewSwitch />}
              <DiscoveryCompanyHits />
              <SearchPagination />
            </Box>
          </DiscoveryNavigationWrapper>
        </SearchConfigure>
      </InstantSearch>
    </Box>
  );
}
