import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { CompanyInfo } from './companyInfo/CompanyInfoCard';
import PhoneNumberDisplay from '../common/components/PhoneNumberDisplay';

interface CompanyContactInfoBoxProps {
  company: CompanyInfo
}
export default function CompanyContactInfoBox({ company }: CompanyContactInfoBoxProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const { isLoaded } = useLoadScript({ googleMapsApiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY });

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="h6">
        {t('common:company')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column',
          },
        }}
      >
        <Box sx={{ width: '50%' }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">{t('common:phone')}</Typography>
            <PhoneNumberDisplay
              phoneNumber={company.contactPhoneNumber}
              typographyVariant="subtitle1"
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">{t('common:email_address')}</Typography>
            <Typography variant="subtitle1">{company.contactEmail}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">{t('common:address')}</Typography>
            <Typography variant="subtitle1">{company.addresses[0].formattedAddress}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              sm: 350,
              xs: '100%',
            },
            height: 300,
          }}
        >
          {isLoaded && (
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: 300,
              borderRadius: 12,
            }}
            options={{
              disableDefaultUI: true,
            }}
            zoom={8}
            center={{
              lat: company.addresses[0].latitude,
              lng: company.addresses[0].longitude,
            }}
          >
            <MarkerF position={{ lng: company.addresses[0].longitude, lat: company.addresses[0].latitude }} clickable={false} />
          </GoogleMap>
          )}
        </Box>
      </Box>
    </Box>
  );
}
