import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import { Product_Label_Name_Enum } from 'kheops-graphql';
import LabelsIcon from '../../assets/icons/labels.svg?react';
import FilterButton, { FilterButtonMode } from '../../search/filters/FilterButton';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import { logoLabels } from '../../packaging/packaging-formatter';

interface ProductLabelsFilterProps {
  mode?: FilterButtonMode;
}

export default function ProductLabelsFilter({ mode }: ProductLabelsFilterProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { items, refine } = useRefinementList({ limit: 100, attribute: 'labels' });
  const currentRefinements = useNormalizedCurrentRefinements('labels') as string[];
  const [itemQuery, setItemQuery] = useState('');

  const labelOptions = useMemo((): React.JSX.Element[] => {
    return items
      .map((item) => ({ ...logoLabels[item.value as Product_Label_Name_Enum], label: item.value }))
      .filter((label) => label.alt.toLowerCase().indexOf(itemQuery.toLowerCase()) === 0)
      .sort((labelA, labelB) => labelA.alt.localeCompare(labelB.alt))
      .map(({ label, alt }) => (
        <FormControlLabel
          key={label}
          control={<Checkbox checked={currentRefinements.includes(label)} />}
          onChange={() => refine(label)}
          label={alt}
          sx={{
            height: 48,
            px: 0.5,
          }}
        />
      ));
  }, [items, currentRefinements, itemQuery]);

  return (
    <FilterButton
      buttonProps={{
        startIcon: <LabelsIcon />,
      }}
      label={t('discovery:labels')}
      activeItemCount={currentRefinements.length}
      variant="outlined"
      mode={mode}
      name="labels"
      content={(
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  sx={{
                    marginRight: 1,
                    padding: 0.5,
                    borderRadius: 3,
                    color: 'white',
                    backgroundColor: 'common.black',
                  }}
                />
              ),
              sx: {
                borderRadius: 4,
                height: 48,
              },
            }}
            onChange={(event) => setItemQuery(event.target.value)}
            value={itemQuery}
            placeholder={t('discovery:search_a_label')}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body2">{t('discovery:labels')}</Typography>
            {labelOptions}
          </Box>
        </Box>
      )}
    />
  );
}
