import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Billing_Type_Enum } from 'kheops-graphql';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useTranslation } from 'react-i18next';
import BlackBackgroundTooltip from '../common/components/BlackBackgroundTooltip';

interface BillingTypeCardProps {
  billingType: Billing_Type_Enum,
  inlineVersion?: boolean,
  sx?: SxProps<Theme>,
}

interface BillingTypeInformation {
  title: string,
  helperText: string,
}

const billingTypeToInformationMap = new Map<Billing_Type_Enum, BillingTypeInformation>([
  [Billing_Type_Enum.Fixed, { title: 'products:billed_per_unit', helperText: 'products:billed_per_unit_helper' }],
  [Billing_Type_Enum.WeightDependent, { title: 'products:billed_per_weight', helperText: 'products:billed_per_weight_helper' }],
]);

export default function BillingTypeCard({ billingType, inlineVersion, sx }: BillingTypeCardProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const billingTypeInformation = billingTypeToInformationMap.get(billingType);
  const boxProps = inlineVersion
    ? {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      gap: 1,
      px: 1,
      py: 0.5,
    }
    : {
      width: 124,
      height: 70,
      textAlign: 'center',
    };

  return (
    <BlackBackgroundTooltip title={t(billingTypeInformation!.helperText)}>
      <Box
        sx={{
          ...boxProps,
          borderRadius: 2,
          textAlign: 'center',
          backgroundColor: 'marketing2.main',
          ...sx,
        }}
      >
        <ReceiptIcon
          sx={{ mt: inlineVersion ? undefined : 1.5 }}
          fontSize={inlineVersion ? 'small' : 'medium'}
        />
        <Typography variant="subtitle1">{t(billingTypeInformation!.title)}</Typography>
      </Box>
    </BlackBackgroundTooltip>
  );
}
