import { styled } from '@mui/material';
import { RichTextEditor } from 'mui-tiptap';

const CustomRichTextEditor = styled(RichTextEditor)(({ theme: customTheme }) => ({
  borderRadius: customTheme.spacing(1.5),
  minHeight: 116,
  svg: {
    color: customTheme.palette.text.primary,
    width: 18,
    height: 18,
  },
}));

export default CustomRichTextEditor;
