import { Box, Typography } from '@mui/material';
import React from 'react';

export interface CompanyInfoSectionProps {
  title: string;
  icon: string;
  children: React.ReactNode;
}

export default function CompanyInfoSection({ title, icon, children }: CompanyInfoSectionProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: {
          xs: '100%',
          lg: 600,
        },
      }}
    >
      <Typography variant="h6">
        {icon} {title}
      </Typography>
      <Box
        sx={{
          border: '1px solid',
          borderColor: {
            xs: 'transparent',
            lg: 'divider',
          },
          borderRadius: 6,
          backgroundColor: 'background.paper',
          p: 3,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
