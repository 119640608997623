import { Button, SxProps } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useAddOrderToBasket } from '../../hooks/useAddOrderToBasket';

interface ReorderButtonProps {
  orderReferenceId: string;
  sx?: SxProps;
  variant?: 'outlined' | 'contained',
}

export default function ReorderButton({ orderReferenceId, sx, variant = 'outlined' }: ReorderButtonProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);
  const { addOrderToBasket, addedToBasket } = useAddOrderToBasket({ orderReferenceId });

  return (
    <Button
      size="extraLarge"
      variant={variant}
      color="primary"
      startIcon={addedToBasket ? <CheckCircleOutlineOutlinedIcon sx={{ height: 18, width: 18 }} /> : <AddShoppingCartIcon sx={{ height: 18, width: 18 }} />}
      onClick={addOrderToBasket}
      sx={sx}
    >
      {t(addedToBasket ? 'common:added' : 'basket:order_again')}
    </Button>
  );
}
