import { Container, containerClasses, Grid, Typography } from '@mui/material';
import { AlgoliaHit } from 'instantsearch.js';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import { SupplierHit } from '../search';
import SearchBuyerHit from './SearchBuyerHit';

export default function SearchBuyerHits(): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { hits, results } = useHits<AlgoliaHit<SupplierHit>>();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!results && (
        <Container
          maxWidth="xl"
          sx={{
            // The selector is here is used to increase the specifity.
            [`&.${containerClasses.maxWidthXl}`]: {
              px: {
                xs: 1,
                sm: 0,
              },
            },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            {t(DiscoveryWordingsKeys.SUPPLIER.n_result, { count: results.nbHits })}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            {t(DiscoveryWordingsKeys.SUPPLIER.sorted_by_distance)}
          </Typography>
          <Grid container columnSpacing={2}>
            {hits.map((hit) => (
              <Grid key={hit.objectID} item xs={12} sm={6} md={4} lg={3}>
                <SearchBuyerHit hit={hit} />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}
