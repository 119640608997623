import { useEffect } from 'react';
import { IndexUiState } from 'instantsearch.js';
import { createSignal } from '@react-rxjs/utils';
import { debounceTime } from 'rxjs';
import { OnStateChangeArgs } from '../../search/state/state';

export interface UseUpdateUiState {
  updateUiState: (args: OnStateChangeArgs) => void;
  initialIndexUiState: IndexUiState;
}

export interface UseUpdateUiStateProps {
  index: string;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const [history$, historyReplaceState] = createSignal<any>();

export default function useUpdateUiState({ index }: UseUpdateUiStateProps): UseUpdateUiState {
  const updateUiState = ({ uiState, setUiState }: OnStateChangeArgs): void => {
    const historyUiState = {
      ...window.history.state,
      uiState: {
        ...window.history.state.uiState,
        [index]: uiState[index],
      },
    };

    delete historyUiState.uiState[index].configure.aroundLatLng;

    historyReplaceState(historyUiState);
    setUiState(uiState);
  };

  useEffect((): void => {
    history$
      .pipe(debounceTime(200))
      .subscribe((historyUiState) => {
        window.history.replaceState(historyUiState, '');
      });
  }, []);

  return {
    updateUiState,
    initialIndexUiState: window.history.state.uiState?.[index] as IndexUiState,
  };
}
