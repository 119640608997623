import CloseIcon from '@mui/icons-material/Close';
import { IconButton, alertClasses } from '@mui/material';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { commonSnackbarPropsAtom } from '../common/state/state';

export function useErrorSnackbar(label: string): () => void {
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  return useCallback(() => {
    const snackbarOnClose = (): void => {
      setCommonSnackbarProps(undefined);
    };

    setCommonSnackbarProps({
      label,
      snackbarProps: {
        open: true,
        autoHideDuration: null,
      },
      alertProps: {
        severity: 'error',
        action: (
          <IconButton
            onClick={snackbarOnClose}
            color="inherit"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        ),
        sx: {
          [`.${alertClasses.action}`]: {
            pt: 0,
            pl: 1,
            alignItems: 'center',
          },
        },
      },
    });
  }, [label]);
}
