/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { AuthToken, ISocialProfile } from '../models/auth.model';

export default class AuthAPIService {
  async refreshToken(refreshToken: string): Promise<AuthToken> {
    const response = await axios.post<AuthToken>(
      `${import.meta.env.REACT_APP_SERVER_BASE_URL}/auth/refreshToken`,
      { refresh_token: refreshToken },
    );
    return response.data;
  }

  async loginWithGoogle(
    accessToken: string,
  ): Promise<{ profile: ISocialProfile; tokens: AuthToken }> {
    const response = await axios.post<{
      profile: ISocialProfile;
      tokens: AuthToken;
    }>(`${import.meta.env.REACT_APP_SERVER_BASE_URL}/auth/login/google`, { access_token: accessToken });

    return response.data;
  }
}
