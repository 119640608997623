import { Box, styled, keyframes, Theme } from '@mui/material';
import React from 'react';

export interface LoadingDotsProps {
  loading: boolean;
  children: React.ReactElement | string;
  color?: string | ((theme: Theme) => string);
}

function LoadingDots({ loading, children, color }: LoadingDotsProps): React.JSX.Element {
  const dotKeyFrames = keyframes`
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }

    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }

    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
  `;

  const determineColor = (theme: Theme): string => {
    if (!color) {
      return theme.palette.primary.main;
    }

    return typeof color === 'string' ? color : color(theme);
  };

  const LoadingDotsBox = styled('div')(({ theme }) => ({
    '& .loading-dots--dot': {
      animation: `${dotKeyFrames} 1.5s infinite ease-in-out`,
      backgroundColor: determineColor(theme),
      borderRadius: '10px',
      display: 'inline-block',
      height: '8px',
      width: '8px',

      '&:nth-of-type(2)': {
        animationDelay: '.5s',
        marginLeft: '4px',
      },

      '&:nth-of-type(3)': {
        animationDelay: '1s',
        marginLeft: '4px',
      },
    },
  }));

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: loading ? 'block' : 'none', position: 'absolute', width: '100%', height: '100%', textAlign: 'center' }}>
        <LoadingDotsBox sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <div className="loading-dots--dot" />
          <div className="loading-dots--dot" />
          <div className="loading-dots--dot" />
        </LoadingDotsBox>
      </Box>
      <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>
        {children}
      </Box>
    </Box>
  );
}

export default React.memo(LoadingDots);
