export interface AuthToken {
  token_type: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  sendbird_access_token?: string | null;
}

export interface ISocialProfile {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  picture?: string;
}

export const AUTH_TOKEN = 'auth-token';
