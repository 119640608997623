import { Chip, ChipProps, SxProps, Theme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export type FilterTagVariant = 'filled' | 'outlined';
export type FilterTagSize = 'small' | 'medium';

export interface FilterTagProps {
  chipProps: ChipProps;
  variant: FilterTagVariant;
  size: FilterTagSize;
}

const VariantSxMap: {[variant in FilterTagVariant]: SxProps<Theme>} = {
  filled: {
    backgroundColor: 'secondary.light',
    '& .MuiChip-deleteIcon': {
      color: 'text.primary',
      mx: 0,
    },
  },
  outlined: {
    backgroundColor: 'white',
    color: 'common.black',
    border: '1px solid',
    borderColor: 'divider',
    '& .MuiChip-deleteIcon': {
      color: 'text.secondary',
      ml: 0.5,
    },
  },
};

const SizeSxMap: {[size in FilterTagSize]: SxProps<Theme>} = {
  small: {
    px: 2,
    py: 1,
    height: 36,
    fontWeight: 400,
    fontSize: '1rem',
  },
  medium: {
    py: 2,
    px: 1,
    height: 40,
    fontWeight: 400,
    fontSize: '0.875rem',
  },
};

export default function FilterTag({ chipProps, variant, size }: FilterTagProps): React.JSX.Element {
  return (
    <Chip
      deleteIcon={<ClearIcon />}
      {...chipProps}
      sx={{
        borderRadius: 2,
        ...VariantSxMap[variant],
        ...SizeSxMap[size],
        ...chipProps.sx,
      } as SxProps<Theme>}
    />
  );
}
