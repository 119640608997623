import { SvgIconTypeMap, SxProps, Theme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { FunctionComponent, SVGProps } from 'react';
import ProductIcon from '../../assets/icons/product.svg?react';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import DeliveryIcon from '../../assets/icons/delivery.svg?react';
import ProfileIcon from '../../assets/icons/profile.svg?react';
import BillingIcon from '../../assets/icons/billing.svg?react';
import { RoutePaths } from '../../routes/AppRoutes';

export type NavigationAction = 'logout';

export interface SubNavigationTabData {
  to: string;
  label: string;
}

export interface NavigationTabData extends SubNavigationTabData {
  category: NavigationCategories;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  subTabs: SubNavigationTabData[];
  sx?: SxProps<Theme>;
}

export enum NavigationCategories {
  PROFILE = 'PROFILE',
  PRODUCTS = 'PRODUCTS',
  PRICES = 'PRICES',
  DELIVERY = 'DELIVERY',
  BILLING = 'BILLING',
}

export interface NavigationItem {
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  labelKey: string;
  // to and action should not be set at the same time, to is used in that case
  to?: string;
  action?: NavigationAction;
  desktopOnly?: boolean;
}

export const SupplierNavigation: NavigationItem[] = [
  {
    icon: ProfileIcon,
    labelKey: 'settings:my_company',
    to: RoutePaths.COMPANY_PROFILE,
  },
  {
    icon: ProductIcon,
    labelKey: 'common:my_products',
    to: RoutePaths.COMPANY_PRODUCTS,
  },
  {
    icon: DiscountIcon,
    labelKey: 'common:my_prices',
    to: RoutePaths.COMPANY_PRODUCTS_PRICES,
    desktopOnly: true,
  },
  {
    icon: DeliveryIcon,
    labelKey: 'common:my_delivery_zones',
    to: RoutePaths.COMPANY_DELIVERY_ZONES,
  },
];

export const BuyerNavigation: NavigationItem[] = [
  {
    icon: DeliveryIcon,
    labelKey: 'common:delivery',
    to: RoutePaths.COMPANY_BUYER_DELIVERY_INFO,
  },
  {
    icon: BillingIcon,
    labelKey: 'common:billing',
    to: RoutePaths.COMPANY_BUYER_BILLING_INFO,
  },
];

export const CommonNavigation: NavigationItem[] = [
  {
    icon: PersonOutlinedIcon,
    labelKey: 'common:my_personal_information',
    to: RoutePaths.USER,
  },
  {
    icon: LogoutOutlinedIcon,
    labelKey: 'common:sign_out',
    action: 'logout',
  },
];

export const MobileNavigation: NavigationItem[] = [
  {
    icon: PersonOutlinedIcon,
    labelKey: 'common:my_personal_information',
    to: RoutePaths.USER,
  },
  {
    icon: ChangeCircleOutlinedIcon,
    labelKey: 'common:switch_company',
    to: RoutePaths.NAVIGATION_CONTEXTS,
  },
];

export const SupplierCompanyNavMap: NavigationTabData[] = [
  {
    category: NavigationCategories.PROFILE,
    to: RoutePaths.COMPANY_PROFILE,
    icon: ProfileIcon,
    label: 'settings:my_company',
    subTabs: [],
  },
  {
    category: NavigationCategories.PRODUCTS,
    to: RoutePaths.COMPANY_PRODUCTS,
    icon: ProductIcon,
    label: 'common:product_other',
    subTabs: [],
  },
  {
    category: NavigationCategories.PRICES,
    to: RoutePaths.COMPANY_PRODUCTS_PRICES,
    icon: DiscountIcon,
    label: 'price:prices',
    sx: {
      display: {
        xs: 'none',
        lg: 'inline-flex',
      },
    },
    subTabs: [
      {
        to: RoutePaths.COMPANY_PRODUCTS_PRICES,
        label: 'contracts:default_price',
      },
      {
        to: RoutePaths.COMPANY_GLOBAL_DISCOUNTS,
        label: 'price:global_discounts',
      },
      {
        to: RoutePaths.COMPANY_CATALOGS,
        label: 'price:prices_catalog',
      },
    ],
  },
  {
    category: NavigationCategories.DELIVERY,
    to: RoutePaths.COMPANY_DELIVERY_ZONES,
    icon: DeliveryIcon,
    label: 'common:delivery',
    subTabs: [],
  },
];

export const BuyerCompanyNavMap: NavigationTabData[] = [
  {
    category: NavigationCategories.DELIVERY,
    to: RoutePaths.COMPANY_BUYER_DELIVERY_INFO,
    icon: DeliveryIcon,
    label: 'common:delivery',
    subTabs: [],
  },
  {
    category: NavigationCategories.BILLING,
    to: RoutePaths.COMPANY_BUYER_BILLING_INFO,
    icon: BillingIcon,
    label: 'common:billing',
    subTabs: [],
  },
];
