/* eslint-disable import/no-duplicates */
import { Typography } from '@mui/material';
import { AdminMessage, FileMessage, MultipleFilesMessage, UserMessage } from '@sendbird/chat/message';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export interface ChannelSeparatorProps {
  message: AdminMessage | UserMessage | FileMessage | MultipleFilesMessage;
}

export default function ChannelSeparator({ message }: ChannelSeparatorProps): React.JSX.Element {
  return (
    <Typography variant="subtitle2" sx={{ textAlign: 'center', my: 2 }}>
      {format(message.createdAt, 'dd MMMM yyyy', { locale: fr })}
    </Typography>
  );
}
