import { Box, Button, Card, Container, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageWrapper } from './PageWrapper';
import ErrorPageImage from '../../assets/images/error_page_image.png';

export default function ErrorPage(): React.JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContactSupport = (): void => {
    window.Intercom('show');
  };

  return (
    <PageWrapper fullPage>
      <Container
        maxWidth="sm"
        sx={{
          mt: {
            xs: -1,
            sm: 3,
          },
          p: {
            xs: 0,
          },
        }}
      >
        <Card
          sx={{
            px: 3,
            py: 5,
            mx: {
              xs: 1,
              sm: 0,
            },
            textAlign: 'center',
          }}
        >
          <Box
            component="img"
            src={ErrorPageImage}
            sx={{ pb: 3 }}
          />
          <Typography variant="h1">
            {t('error:an_error_occured')}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
            {t('error:an_error_occured_description')}
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexWrap: {
                xs: 'wrap-reverse',
                sm: 'wrap',
              },
              justifyContent: 'center',
              gap: 2,
              px: 2,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<HelpOutlineIcon />}
              onClick={handleContactSupport}
              sx={{ flexGrow: 1 }}
            >
              {t('error:contact_support')}
            </Button>
            <Button
              variant="contained"
              startIcon={<LoginOutlinedIcon />}
              onClick={() => navigate('/')}
              sx={{ flexGrow: 1 }}
            >
              {t('error:back_to_home_page')}
            </Button>
          </Box>
        </Card>
      </Container>
    </PageWrapper>
  );
}
