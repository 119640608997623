import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useAtomValue } from 'jotai';
import { RoutePaths } from '../routes/AppRoutes';
import PackageIcon from '../assets/icons/package.svg?react';
import { currentContextAtom } from '../state';

export interface NavigationTabInfo {
  label: string;
  to: string;
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
}

type NavigationTabsInfo = {
  [key in Business_Profile_Enum]: NavigationTabInfo[]
};

export default function useNavigationTabs(): NavigationTabInfo[] {
  const { t } = useTranslation(['common']);
  const { realm } = useAtomValue(currentContextAtom);
  const TABS_INFO: NavigationTabsInfo = {
    [Business_Profile_Enum.Buyer]: [
      { label: t('common:discover'), to: RoutePaths.DISCOVERY_SUPPLIERS, icon: AddBusinessOutlinedIcon },
      { label: t('common:product_other'), to: RoutePaths.SEARCH_PRODUCT, icon: AddShoppingCartOutlinedIcon },
      { label: t('common:supplier_other'), to: RoutePaths.CONTRACT_LIST, icon: StorefrontIcon },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { label: t('common:orders'), to: RoutePaths.ORDER_LIST, icon: PackageIcon as any },
    ],
    [Business_Profile_Enum.Supplier]: [
      { label: t('common:prospecting'), to: RoutePaths.DISCOVERY_BUYERS, icon: AddBusinessOutlinedIcon },
      { label: t('common:customer_other'), to: RoutePaths.CONTRACT_LIST, icon: StorefrontIcon },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { label: t('common:orders'), to: RoutePaths.ORDER_LIST, icon: PackageIcon as any },
    ],
  };

  return TABS_INFO[realm];
}
