import { Box, useMediaQuery, useTheme } from '@mui/material';
import { InstantSearch } from 'react-instantsearch';
import { useAtomValue } from 'jotai';
import { useSearchClient } from '../../hooks/useSearchClient';
import SearchConfigure from '../../search/SearchConfigure';
import SearchPagination from '../../search/SearchPagination';
import FilterUtils from '../../search/filters/filter.utils';
import useUpdateHistoryUiState from '../hooks/useUpdateHistoryUiState';
import DiscoveryProductHits from './DiscoveryProductHits';
import { currentContextAtom } from '../../state';
import { isVisibleInDiscoveryFilter } from '../../search/discovery.utils';
import DiscoveryCarrousel from '../carrousel/DiscoveryCarrousel';
import SupplierSlide from '../carrousel/SupplierSlide';
import DiscoveryViewSwitch from '../DiscoveryViewSwitch';
import DiscoveryNavigationWrapper from '../navigation/DiscoveryNavigationWrapper';

export default function ProductsDiscovery(): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const searchClient = useSearchClient();
  const index = import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX;
  const { initialIndexUiState, updateUiState } = useUpdateHistoryUiState({ index });
  const { companyId } = useAtomValue(currentContextAtom);
  const carrouselFilters = `business_profile:SUPPLIER AND ${FilterUtils.GetSignedContractFilter('partners', companyId)}`;
  const filters = `${isVisibleInDiscoveryFilter} AND ${FilterUtils.GetSignedContractFilter('buyers', companyId)}`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {!isXs && (
        <DiscoveryCarrousel
          index={import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX}
          filters={carrouselFilters}
          attribute="product_families"
          slide={SupplierSlide}
        />
      )}
      <InstantSearch
        indexName={index}
        searchClient={searchClient}
        initialUiState={{
          [index]: initialIndexUiState,
        }}
        onStateChange={updateUiState}
      >
        <SearchConfigure index={index} filters={filters}>
          <DiscoveryNavigationWrapper attribute="family">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                borderRadius: {
                  xs: 0,
                  sm: 6,
                },
                py: 3,
                px: {
                  xs: 2,
                  sm: 3,
                },
                backgroundColor: 'white',
                mx: {
                  xs: -1,
                  sm: 0,
                },
              }}
            >
              {isXs && <DiscoveryViewSwitch />}
              <DiscoveryProductHits />
              <SearchPagination />
            </Box>
          </DiscoveryNavigationWrapper>
        </SearchConfigure>
      </InstantSearch>
    </Box>
  );
}
