import { Box, Button } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { RoutePaths } from '../../routes/AppRoutes';
import { draftProductAtom } from './state';
import { useNavBlocker } from '../../hooks/useNavBlocker';

interface ProductFormQuitBtnProps {
  productId: string | undefined;
  shouldBlock: boolean;
}

export default function ProductFormQuitBtn({ productId, shouldBlock }: ProductFormQuitBtnProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'common']);
  const draftProduct = useAtomValue(draftProductAtom);
  const blockerMessage = {
    title: t(productId ? 'products:abort_edition' : 'products:abort_creation'),
    message: <Trans i18nKey={productId ? 'products:abort_edition_message' : 'products:abort_creation_message'} />,
    confirmLabel: t('common:confirm'),
    cancelLabel: t('common:cancel'),
  };
  const blockerModal = useNavBlocker(shouldBlock, blockerMessage);

  return (
    <Box>
      {blockerModal}
      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        component={Link}
        to={
          !draftProduct?.sku
            ? generatePath(RoutePaths.COMPANY_PRODUCTS)
            : generatePath(RoutePaths.PRODUCT, { productSku: `${draftProduct!.sku!}`, companyId: draftProduct!.company_id! })
        }
      >
        {t(productId ? 'common:quit' : 'common:cancel')}
      </Button>
    </Box>
  );
}
