import { Business_Profile_Enum, Order_Status_Enum } from 'kheops-graphql';

type ContextOrderStatusWordingMap = {
  [context in Business_Profile_Enum]: {
    [status in Order_Status_Enum]: string
  }
}

export const OrderStatusTypeIntl: ContextOrderStatusWordingMap = {
  BUYER: {
    order_to_be_validated_by_buyer: 'order_to_be_validated_short',
    order_to_be_validated_by_supplier: 'order_to_be_validated_by_supplier',
    order_to_be_delivered_by_supplier: 'order_to_be_delivered_by_supplier',
    order_to_be_prepared_by_supplier: 'order_to_be_prepared_by_supplier',
    order_to_be_received_by_buyer: 'order_to_be_received_by_buyer_short',
    order_to_be_billed_by_supplier: 'order_to_be_billed_by_supplier',
    order_to_be_paid_by_buyer: 'order_to_be_paid_by_buyer_short',
    order_being_paid_by_buyer: 'order_being_paid_by_buyer',
    order_closed: 'order_closed',
    order_cancelled: 'order_cancelled',
    order_archived: 'order_archived',

  },
  SUPPLIER: {
    order_to_be_validated_by_buyer: 'order_to_be_validated_by_buyer',
    order_to_be_validated_by_supplier: 'order_to_be_validated_short',
    order_to_be_delivered_by_supplier: 'order_to_be_delivered_by_supplier_short',
    order_to_be_prepared_by_supplier: 'order_to_be_prepared_by_supplier',
    order_to_be_received_by_buyer: 'order_to_be_received_by_buyer',
    order_to_be_billed_by_supplier: 'order_to_be_billed_by_supplier_short',
    order_to_be_paid_by_buyer: 'order_to_be_paid_by_buyer',
    order_being_paid_by_buyer: 'order_being_paid_by_buyer',
    order_closed: 'order_closed',
    order_cancelled: 'order_cancelled',
    order_archived: 'order_archived',
  },
};

export const OrderStatusTypeShortIntl = {
  order_to_be_validated_by_buyer: 'order_to_be_validated_short',
  order_to_be_validated_by_supplier: 'order_to_be_validated_short',
  order_to_be_delivered_by_supplier: 'order_to_be_delivered_by_supplier_short',
  order_to_be_prepared_by_supplier: 'order_to_be_prepared_by_supplier',
  order_to_be_received_by_buyer: 'order_to_be_received_by_buyer_short',
  order_to_be_billed_by_supplier: 'order_to_be_billed_by_supplier_short',
  order_to_be_paid_by_buyer: 'order_to_be_paid_by_buyer_short',
  order_being_paid_by_buyer: 'order_being_paid_by_buyer_short',
  order_closed: 'order_closed',
  order_cancelled: 'order_cancelled',
  order_archived: 'order_archived',
};
