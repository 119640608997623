import type { PlainSearchParameters } from 'algoliasearch-helper';
import { UiState } from 'instantsearch.js';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { BarcodeCapture } from 'scandit-web-datacapture-barcode';
import { OrderRecommendation } from 'kheops-graphql';
import { SINGLE_ORDER_PAGE_DISPLAY_MODE, COMPANY_PAGE_DISPLAY_MODE } from '../../common/state/local-storage-keys';

// Retrieved from instantsearch.d.ts but not exported
export interface OnStateChangeArgs {
  uiState: UiState;
  setUiState(uiState: UiState | ((previousUiState: UiState) => UiState)): void;
}

export enum DisplayMode {
  GRID = 'GRID',
  LIST = 'LIST'
}

export interface HistoryIndexUiState {
  page?: number;
  refinementList?: { [attribute: string]: string[] };
  configure?: PlainSearchParameters;
  query?: string;
}

interface ConfigureRefine {
  refine: (searchParameters: PlainSearchParameters) => void;
}

export type FiltersName = 'product_families' | 'geolocation' | 'company_status' | 'labels';

export const singleOrderPageDisplayModeAtom = atomWithStorage<DisplayMode>(SINGLE_ORDER_PAGE_DISPLAY_MODE, DisplayMode.GRID);
export const companyPageDisplayModeAtom = atomWithStorage<DisplayMode>(COMPANY_PAGE_DISPLAY_MODE, DisplayMode.GRID);
export const searchProductReadyAtom = atom(true);

export const configureRefineAtom = atom<ConfigureRefine | undefined>(undefined);

export const uiStateAtom = atom<PlainSearchParameters>({});

export const barcodeCaptureAtom = atom<BarcodeCapture | undefined>(undefined);

export const currentExpandedAccordionAtom = atom<FiltersName | null>('product_families');

export const selectedRecommendationsAtom = atom<OrderRecommendation[]>([]);
