import { debounce, OutlinedInputProps } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import SearchInput from './SearchInput';

interface FullTextSearchProps {
  inputProps?: OutlinedInputProps;
  value?: string;
  label?: string;
  onChange?: (value: string) => void;
}

export default function FullTextSearch({ inputProps, value, onChange }: FullTextSearchProps): React.JSX.Element {
  const { refine } = useSearchBox();
  const [inputQuery, setInputQuery] = useState('');
  const [initialized, setInitialized] = useState(false);

  const debouncedRefine = useMemo(() => {
    return debounce((refineValue: string) => refine(refineValue));
  }, []);

  const handleChange = useCallback((query: string) => {
    setInputQuery(query);
    debouncedRefine(query);

    if (onChange) {
      onChange(query);
    }
  }, []);

  useEffect(() => {
    if ((inputQuery !== value) || !initialized) {
      setInputQuery(value || '');
    }

    if (!initialized) {
      setInitialized(true);
    } else {
      debouncedRefine(value || '');
    }
  }, [value]);

  return (
    <SearchInput
      value={inputQuery}
      sx={{
        pl: {
          xs: 1,
          sm: 2,
        },
        width: '100%',
        borderRadius: 4,
      }}
      {...inputProps}
      onChange={handleChange}
    />
  );
}
