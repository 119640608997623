import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton, loadingButtonClasses } from '@mui/lab';
import { buttonClasses, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Payment_Method_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentDownloadFile } from '../../hooks/useDownloadFile';
import useOrderInvoiceType from '../../hooks/useOrderInvoiceType';
import { documentDownloadUrlsAtom } from '../state/state';

interface OrderInvoiceButtonProps {
  orderFriendlyId: string;
  hasCommission: boolean;
  hasInvoiceGeneration: boolean;
  paymentMethod: Payment_Method_Enum;
}

export default function OrderInvoiceButton({ orderFriendlyId, hasCommission, hasInvoiceGeneration, paymentMethod }: OrderInvoiceButtonProps): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const invoiceType = useOrderInvoiceType(hasCommission, hasInvoiceGeneration, paymentMethod);
  const documentDownloadUrls = useAtomValue(documentDownloadUrlsAtom);
  const theme = useTheme();

  const downloadUrl = useMemo(
    () => documentDownloadUrls.get(invoiceType),
    [invoiceType, documentDownloadUrls],
  );

  const handleButtonClick = useDocumentDownloadFile({ documentType: invoiceType, downloadUrl: downloadUrl!, orderId: orderFriendlyId });

  return (
    <LoadingButton
      size="extraLarge"
      variant="text"
      color="primary"
      onClick={handleButtonClick}
      loading={!downloadUrl}
      startIcon={<FileDownloadOutlinedIcon />}
      sx={{
        flex: 'auto',
        backgroundColor: 'white',
        ':hover': {
          backgroundColor: 'primary.main',
          color: 'white',
        },
        py: 1.5,
        [theme.breakpoints.down('sm')]: {
          px: 0,
        },
        [`& .${loadingButtonClasses.startIconLoadingStart}, .${buttonClasses.icon}`]: {
          height: '18px',
          mt: '-6px', // offset the icon container as the icon's svg size is 24px but we want it to only take 18px in size.
        },
      }}
    >
      {t('order:see_invoice')}
    </LoadingButton>
  );
}
