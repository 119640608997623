import { Box, Divider, Popover, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Dispatch, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { getCompanyShortName } from '../common/utils/common.utils';
import { BuyerNavigation, CommonNavigation, SupplierNavigation } from '../common/state/navigation';
import { availableContextsAtom, currentContextAtom } from '../state';
import NavigationSection from './NavigationSection';
import ContextImage from './ContextImage';
import ContextNavigation from './ContextNavigation';
import { ellipsisStyle } from '../common/utils/style.utils';

interface ProfileBoxProps {
  anchorHandler: Dispatch<React.SetStateAction<HTMLElement| null>>
  anchorEl: HTMLElement | null;
}

export default function ProfileBox({ anchorHandler, anchorEl } : ProfileBoxProps) : React.JSX.Element {
  const location = useLocation();
  const { t } = useTranslation(['common']);
  const context = useAtomValue(currentContextAtom);
  const availableContexts = useAtomValue(availableContextsAtom);
  const navigationItems = context.realm === Business_Profile_Enum.Supplier ? SupplierNavigation : BuyerNavigation;

  const handleUserMenuClose = (): void => {
    anchorHandler(null);
  };

  useEffect((): void => {
    anchorHandler(null);
  }, [location]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleUserMenuClose}
      open={Boolean(anchorEl)}
      sx={{
        mt: 1,
        '& .MuiPaper-root': {
          width: 312,
          p: 3,
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            mb: context.realm === Business_Profile_Enum.Supplier ? 1 : 0,
          }}
        >
          <ContextImage context={context} size={48} borderRadius={5} />
          <Typography variant="h6" sx={{ fontWeight: 400, ...ellipsisStyle }}>
            {getCompanyShortName(context.companyName, context.realm)}
          </Typography>
        </Box>
        <NavigationSection items={navigationItems} />
        <Divider sx={{ mt: 2 }} />
      </Box>
      <NavigationSection items={CommonNavigation} />
      {availableContexts.length > 1 && (
        <>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="subtitle2" sx={{ lineHeight: 'normal' }}>
              {t('common:other_companies')}
            </Typography>
            <ContextNavigation />
          </Box>
        </>
      )}
    </Popover>
  );
}
