import { generatePath } from 'react-router';
import { AlgoliaHit } from 'instantsearch.js';
import { TFunction } from 'i18next';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { productFamilyMap } from 'kheops-utils';
import { RoutePaths } from '../routes/AppRoutes';
import { PackagingFamilyTypeIntl, ProductFamilyTypeIntl } from '../common/i18n/product-family.translation';
import { ProductHit } from '../search/search';
import { ProductFamilyNavigation, ProductSubFamilyNavigation, productFamilyImageMap, productSubFamilyImageMap } from './state';

export interface DiscoveryNavigationItem {
  family: Product_Family_Name_Enum | Product_Sub_Family_Name_Enum;
  img: string;
  label: string;
}

export function getProductUrlFromProductHit(hit: AlgoliaHit<ProductHit>): string {
  return `${generatePath(RoutePaths.PACKAGING, { productSku: hit.productSku, companyId: hit.company_id })}?csu=${hit.base_unit_sku}&packaging=${hit.objectID.split('-')[0]}`;
}

export function getProductFamilyNavigationItems(t: TFunction): DiscoveryNavigationItem[] {
  return (Object.keys(productFamilyMap) as Product_Family_Name_Enum[])
    .map((family) => ({
      family,
      img: productFamilyImageMap[family],
      label: t(`product-family:${ProductFamilyTypeIntl[family]}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getProductSubFamilyNavigationItems(t: TFunction, family: Product_Family_Name_Enum): DiscoveryNavigationItem[] {
  return productFamilyMap[family]
    .map((subFamily) => ({
      family: subFamily,
      img: productSubFamilyImageMap[subFamily],
      label: t(`product-family:${PackagingFamilyTypeIntl[subFamily]}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getSearchFamiliesFromCurrentFamilies(currentFamily: ProductFamilyNavigation, currentSubFamily: ProductSubFamilyNavigation): Product_Sub_Family_Name_Enum[] {
  if (currentSubFamily !== 'ALL') {
    return [currentSubFamily];
  }

  if (currentFamily !== 'ALL') {
    return productFamilyMap[currentFamily];
  }

  return [];
}
