import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function BankTransferInformation(): React.JSX.Element {
  const { t } = useTranslation(['payment', 'price']);

  return (
    <Grid
      container
      sx={{
        textAlign: 'start',
        p: 4,
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>{t('payment:bank_transfer_informations')}</Typography>
      </Grid>
      <Grid
        sx={{
          mt: 1,
          '& .MuiGrid-item': {
            py: 1,
          },
        }}
        container
        item
        xs={12}
      >
        <Grid
          item
          sm={7}
          xs={12}
        >
          <Typography variant="subtitle2">IBAN</Typography>
          <Typography variant="body1">{import.meta.env.REACT_APP_LEMONWAY_IBAN}</Typography>
        </Grid>
        <Grid
          item
          sm={3}
          xs={12}
        >
          <Typography variant="subtitle2">BIC</Typography>
          <Typography variant="body1">{import.meta.env.REACT_APP_LEMONWAY_BIC_CODE}</Typography>
        </Grid>
        <Grid
          item
          sm={2}
          xs={12}
        >
          <Typography variant="subtitle2">{t('payment:currency')}</Typography>
          <Typography variant="body1">EUR</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            pt: 1,
          }}
        >
          <Typography variant="subtitle2">{t('payment:account_holder')}</Typography>
          <Typography variant="body1">{import.meta.env.REACT_APP_LEMONWAY_HOLDER_NAME}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
