import { Box, Tab, Tabs, Typography, keyframes, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderRecommendation } from 'kheops-graphql';
import OrderRecommendationsBgXs from '../../assets/images/order-recommendations-xs.png';
import OrderRecommendationsBgSm from '../../assets/images/order-recommendations-sm.png';
import OrderRecommendationsBgMd from '../../assets/images/order-recommendations-md.png';
import OrderRecommendationsBgLg from '../../assets/images/order-recommendations-lg.png';
import OrderRecommendationsBgXl from '../../assets/images/order-recommendations-xl.png';
import { useOrderRecommendationsQuery } from '../../queries/__generated__/orderRecommendations.generated';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import { currentContextAtom } from '../../state';
import OrderRecommendationCard from './OrderRecommendationCard';
import { selectedRecommendationsAtom } from '../state/state';

export const OrderRecommendationsBackgrounds = {
  xs: OrderRecommendationsBgXs,
  sm: OrderRecommendationsBgSm,
  md: OrderRecommendationsBgMd,
  lg: OrderRecommendationsBgLg,
  xl: OrderRecommendationsBgXl,
};

export default function OrderRecommendations(): React.JSX.Element {
  const { t } = useTranslation(['basket']);
  const { companyId } = useAtomValue(currentContextAtom);
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const breakpoint = useCurrentBreakpoint();
  const { data } = useOrderRecommendationsQuery({ variables: { buyingCompanyId: companyId }, fetchPolicy: 'network-only' });
  const [selectedRecommendations, setSelectedRecommendations] = useAtom(selectedRecommendationsAtom);

  const width = isUpSm ? 332 : 237;
  const cardWidthPlusSpacing = width + parseInt(theme.spacing(2), 10);

  const reorderRecommendations = useCallback((recommendation: OrderRecommendation) => {
    const alreadySelectedIndex = selectedRecommendations.findIndex(({ contractReferenceId }) => contractReferenceId === recommendation.contractReferenceId);

    if (alreadySelectedIndex !== -1) {
      setSelectedRecommendations(selectedRecommendations.toSpliced(alreadySelectedIndex, 1).concat([recommendation]));
    } else {
      setSelectedRecommendations(selectedRecommendations.concat([recommendation]));
    }
  }, [selectedRecommendations]);

  const recommendations = useMemo(() => {
    const fetchedRecommendations = data?.getOrderRecommendations?.recommendations;

    if (!fetchedRecommendations) {
      return;
    }

    return fetchedRecommendations
      .filter((recommendation) => !selectedRecommendations.some(({ contractReferenceId }) => contractReferenceId === recommendation!.contractReferenceId))
      .concat(selectedRecommendations);
  }, [data?.getOrderRecommendations?.recommendations, selectedRecommendations]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!recommendations?.length && (
        <Box
          sx={{
            backgroundImage: `url(${OrderRecommendationsBackgrounds[breakpoint]})`,
            backgroundColor: '#FFD371',
            backgroundSize: 'cover',
            p: 2,
            mb: 2,
            mx: 1,
          }}
        >
          <Typography variant="title">
            {t('basket:order_again')}
          </Typography>
          <Tabs
            sx={{
              mt: 2,
              '& .MuiTabs-flexContainer': {
                gap: 2,
                height: 186,
              },
              gap: 1,
            }}
            variant="scrollable"
            scrollButtons={isUpMd}
            TabScrollButtonProps={{
              slots: {
                StartScrollButtonIcon: ArrowBackIcon,
                EndScrollButtonIcon: ArrowForwardIcon,
              },
              sx: {
                '&.Mui-disabled': {
                  opacity: 1,
                  color: 'text.disabled',
                },
                opacity: 1,
                backgroundColor: 'white',
                width: 40,
                height: 40,
                alignSelf: 'center',
                borderRadius: 10,
              },
            }}
          >
            {recommendations.map((recommendation, index) => {
              const lastCardPosition = `translateX(${cardWidthPlusSpacing * (recommendations.length - 1)}px)`;
              const position = `translateX(${cardWidthPlusSpacing * index}px)`;
              const clickedCardAnimation = keyframes`
                from {
                  transform: ${position};
                }
                to {
                  transform: ${lastCardPosition};
                }
              `;

              return (
                <Tab
                  key={recommendation!.id}
                  component={OrderRecommendationCard}
                  recommendation={recommendation!}
                  onAddToBasket={() => reorderRecommendations(recommendation!)}
                  sxCard={{
                    transition: 'transform .3s',
                    position: 'absolute',
                    transform: position,
                    width,
                    boxSizing: 'border-box',
                    '&.kheops-clicked': {
                      zIndex: 10,
                      animation: `${clickedCardAnimation} .6s ease-in-out`,
                      transform: lastCardPosition,
                    },
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
      )}
    </>
  );
}
