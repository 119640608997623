import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const BlackBackgroundTooltip = styled((props: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: props.className }}>{props.children}</Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
  },
}));

export default BlackBackgroundTooltip;
