import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Order_Status_Enum, TaskAction } from 'kheops-graphql';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { format } from 'date-fns';
import { orderAtom, orderDeliveryDateAtom } from '../state/state';
import DeliveryDatePicker from '../../common/components/DeliveryDatePicker';
import { longDateFormat } from '../../common/state/state';

export type DeliveryDateOrderStatus = Order_Status_Enum.OrderToBeValidatedBySupplier | Order_Status_Enum.OrderToBeDeliveredBySupplier | Order_Status_Enum.OrderToBePreparedBySupplier;

interface OrderDeliveryDateDialogProps {
  open: boolean;
  onClose: (date?: Date | null) => void;
  orderStatus: DeliveryDateOrderStatus;
  actionType: Exclude<TaskAction, TaskAction.Cancel>;
}

const deliveryDateDialogWordingMap : {
  [action in Exclude<TaskAction, TaskAction.Cancel>]: {
  [status in DeliveryDateOrderStatus]: {
  title: string;
  description: string;
}}} = {
  APPROVE: {
    order_to_be_validated_by_supplier: {
      title: 'order:validate_order',
      description: 'order:confirm_delivery_date_message',
    },
    order_to_be_delivered_by_supplier: {
      title: 'order:confirm_delivery',
      description: 'order:confirm_delivery_message',
    },
    order_to_be_prepared_by_supplier: {
      title: 'order:confirm_delivery',
      description: 'order:confirm_delivery_message',
    },
  },
  MODIFY: {
    order_to_be_delivered_by_supplier: {
      title: 'order:reschedule_delivery',
      description: 'order:reschedule_delivery_message',
    },
    order_to_be_validated_by_supplier: {
      title: 'order:validate_order',
      description: 'order:confirm_delivery_date_message',
    },
    order_to_be_prepared_by_supplier: {
      title: 'order:reschedule_delivery',
      description: 'order:reschedule_delivery_message',
    },
  },
};

export default function OrderDeliveryDateDialog({ open, onClose, orderStatus, actionType }: OrderDeliveryDateDialogProps): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'order']);
  const { contract: { buyingCompanyId } } = useAtomValue(orderAtom);
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false);
  const [deliveryDate, setDeliveryDate] = useAtom(orderDeliveryDateAtom);
  const [initialDate, setInitialDate] = useState(deliveryDate);

  const handleCancel = (): void => {
    onClose();
    setDeliveryDate(initialDate);
    setShouldShowErrorMessage(false);
  };

  const handleDateChange = (value: Date | null): void => {
    setDeliveryDate(value);
  };

  const handleConfirm = (): void => {
    if (!deliveryDate && orderStatus === Order_Status_Enum.OrderToBeValidatedBySupplier) {
      setShouldShowErrorMessage(true);
      return;
    }
    onClose(deliveryDate);
  };

  const disabledConfirm = useMemo((): boolean => {
    return actionType === TaskAction.Modify && (!deliveryDate || !initialDate || format(initialDate, longDateFormat) === format(deliveryDate, longDateFormat));
  }, [initialDate, deliveryDate]);

  useEffect((): void => {
    setInitialDate(deliveryDate);
  }, [open]);

  return (
    <Dialog
      onClose={handleCancel}
      open={open}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: {
            xs: 'calc(100vw - 64px)',
            md: 640,
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h2">
          {t(deliveryDateDialogWordingMap[actionType][orderStatus].title)}
        </Typography>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            textAlign: 'center',
            mb: 3,
            [theme.breakpoints.down('sm')]: {
              px: 2,
            },
          }}
          variant="body1"
        >
          {t(deliveryDateDialogWordingMap[actionType][orderStatus].description)}
        </Typography>
        {(orderStatus === Order_Status_Enum.OrderToBeValidatedBySupplier || actionType === TaskAction.Modify) && (
          <DeliveryDatePicker companyId={buyingCompanyId} value={deliveryDate} onChange={handleDateChange} />
        )}
      </DialogContent>
      {shouldShowErrorMessage && (<Typography variant="subtitle1" color="error" align="center">{t('order:delivery_date_error_message')}</Typography>)}
      <DialogActions>
        <Button onClick={handleCancel} color="inherit" variant="text">
          {t('common:cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={disabledConfirm}
        >
          {t('common:validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
