import { Box, Typography, TypographyOwnProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Duration_Type_Enum, Maybe } from 'kheops-graphql';
import { useTranslation } from 'react-i18next';
import BlackBackgroundTooltip from '../../../common/components/BlackBackgroundTooltip';

interface ShelfLifeDurationDisplayProps {
  duration?: Maybe<number>;
  durationType?: Maybe<Duration_Type_Enum>;
  durationVariant?: TypographyOwnProps['variant'];
  titleVariant?: TypographyOwnProps['variant'];
  titleAndValueGap?: number;
}

export default function ShelfLifeDurationDisplay({ duration, durationType, durationVariant, titleVariant, titleAndValueGap }: ShelfLifeDurationDisplayProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'products']);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: titleAndValueGap ?? 1,
      }}
    >
      <Typography variant={titleVariant}>{t('products:shelf_life')}</Typography>
      {durationType
        ? (
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
            }}
          >
            <Typography variant={durationVariant}>
              {duration} {t(`common:${durationType.toLowerCase()}`, { count: duration! }).toLowerCase()}
            </Typography>
            <BlackBackgroundTooltip sx={{ ml: 0.5 }} title={t('products:shelf_life_duration_helper')}>
              <InfoOutlinedIcon fontSize="small" />
            </BlackBackgroundTooltip>
          </Box>
        )
        : (
          <Typography variant={durationVariant}>{t('common:not_filled')}</Typography>
        )}
    </Box>
  );
}
