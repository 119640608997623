import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { BusinessProfile, TaskAction } from 'kheops-graphql';
import { useUpdateOrderActionMutation } from '../mutations/__generated__/updateOrderAction.generated';
import { isPerformingActionAtom, orderDeliveryDateAtom, orderSddPaymentDateAtom, viewOrderItemsAtom } from '../orders/state/state';
import { OrderByReferenceIdDocument } from '../queries/__generated__/orderByReferenceId.generated';
import { GetOrderNextActionsDocument } from '../queries/__generated__/orderNextActions.generated';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { currentContextAtom } from '../state';

interface UseOrderActionsProps {
  orderReferenceId: string;
  orderId: string;
}

interface UseOrderActions {
  updateOrder: (taskAction?: TaskAction) => Promise<void>;
  isLoading: boolean;
}
export default function useOrderActions({ orderReferenceId, orderId }: UseOrderActionsProps): UseOrderActions {
  const items = useAtomValue(viewOrderItemsAtom);
  const deliveryDate = useAtomValue(orderDeliveryDateAtom);
  const orderSddPaymentDate = useAtomValue(orderSddPaymentDateAtom);
  const [updateOrderAction, { loading: isLoading, client }] = useUpdateOrderActionMutation(
    {
      refetchQueries: [OrderByReferenceIdDocument, GetOrderNextActionsDocument],
      awaitRefetchQueries: true,
      onError: () => client.refetchQueries({ include: [OrderByReferenceIdDocument] }),
    },
  );
  const setPerformingAction = useSetAtom(isPerformingActionAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { t } = useTranslation(['contracts', 'error']);
  const context = useAtomValue(currentContextAtom);
  const TASK_ACTION_TO_CONFIRMATION_MESSAGE_MAP: { [action in TaskAction]?: string } = {
    APPROVE: 'contracts:the_order_has_been_approved',
    MODIFY: 'contracts:the_order_has_been_modified',
    CANCEL: 'contracts:the_order_has_been_cancelled',
  };

  const updateOrder = useCallback(async (taskAction?: TaskAction): Promise<void> => {
    setPerformingAction(true);

    const { errors } = await updateOrderAction({
      variables: {
        referenceId: orderReferenceId,
        orderId,
        userCompanyId: context.companyId,
        taskAction,
        userBusinessProfile: context.realm! as unknown as BusinessProfile,
        orderDeliveryDate: deliveryDate ? deliveryDate.toISOString() : undefined,
        sddPaymentDate: orderSddPaymentDate ? orderSddPaymentDate.toISOString() : undefined,
        items: items.map(({ packaging, quantity, weight }) => ({
          packagingId: packaging.id,
          quantity,
          adjustedWeight: weight,
        })),
      },
    });

    if (errors instanceof ApolloError) {
      if (errors.message === 'Given order id does not match latest version of the order') {
        setCommonSnackbarProps({
          label: t('error:order_has_already_been_updated'),
          snackbarProps: {
            open: true,
          },
          alertProps: {
            severity: 'error',
          },
        });
      }

      setPerformingAction(false);
      return;
    }

    setCommonSnackbarProps({
      label: t(`${taskAction ? TASK_ACTION_TO_CONFIRMATION_MESSAGE_MAP[taskAction] : 'contracts:the_order_has_been_modified'}`),
      snackbarProps: {
        open: true,
      },
    });

    setPerformingAction(false);
  }, [items, deliveryDate, orderSddPaymentDate, orderId]);

  return {
    updateOrder,
    isLoading,
  };
}
