import { Box, CircularProgress, Pagination } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Business_Profile_Enum, Contract_History_Bool_Exp } from 'kheops-graphql';
import { RoutePaths } from '../../routes/AppRoutes';
import GuitaristButterflyImage from '../../assets/images/guitarist_butterfly.png';
import useOrders, { OrdersFilters } from '../../hooks/useOrders';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import { getArraysIntersection } from '../../common/utils/common.utils';
import { currentContextAtom, userAtom } from '../../state';
import { orderListUiStateAtom, orderListPageAtom, OrdersCategories, contextToActionNeededStatusMap, orderCatogoryToStatusesMap } from '../state/state';
import OrderTable from './OrderTable';
import OrderFilters from './OrderFilters';
import OrderCategoriesSelection from './OrderCategoriesSelection';

export type OrdersCountByCategoryMap = {
  [status in OrdersCategories]: number;
}

export default function OrderList(): React.JSX.Element {
  const PAGE_SIZE = 20;

  const { t } = useTranslation(['order', 'contracts']);
  const { id: userId } = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const [page, setPage] = useAtom(orderListPageAtom);
  const [offset, setOffset] = useState(0);
  const [ordersFilters, setOrdersFilters] = useState<OrdersFilters>({
    aggregateAllCategoryStatuses: orderCatogoryToStatusesMap.get(OrdersCategories.ALL)!,
    aggregateValidationCategoryStatuses: orderCatogoryToStatusesMap.get(OrdersCategories.VALIDATION)!,
    aggregateDeliveryCategoryStatuses: orderCatogoryToStatusesMap.get(OrdersCategories.DELIVERY)!,
    aggregatePaymentCategoryStatuses: orderCatogoryToStatusesMap.get(OrdersCategories.PAYMENT)!,
    aggregateCompletedCategoryStatuses: orderCatogoryToStatusesMap.get(OrdersCategories.COMPLETED)!,
  });
  const { orders, ordersContracts, loading, called, countMap } = useOrders(offset, PAGE_SIZE, ordersFilters);

  const [orderListUiState, setOrderListUiState] = useAtom(orderListUiStateAtom);

  const emptyPagePlaceholderProps = realm === Business_Profile_Enum.Buyer
    ? {
      title: t('order:no_orders'),
      description: t('order:no_orders_buyer_description'),
      imageSrc: GuitaristButterflyImage,
      buttonLabel: t('contracts:discover_new_suppliers'),
      linkDestination: generatePath(RoutePaths.DISCOVERY_SUPPLIERS),
    }
    : {
      title: t('order:no_orders'),
      description: t('order:no_orders_supplier_description'),
      imageSrc: GuitaristButterflyImage,
      buttonLabel: t('contracts:discover_new_buyers'),
      linkDestination: generatePath(RoutePaths.DISCOVERY_BUYERS),
    };

  useEffect(() => {
    const { selectedPartners, onlyMyOrders, onlyOrdersWhereActionIsNeeded, currentSetCategory } = orderListUiState;

    let contractFilter: Contract_History_Bool_Exp | undefined;

    if (selectedPartners.length) {
      const companiesIds = selectedPartners.map((company) => company.value);
      contractFilter = realm === Business_Profile_Enum.Buyer
        ? {
          supplyingCompanyId: {
            _in: companiesIds,
          },
        }
        : {
          buyingCompanyId: {
            _in: companiesIds,
          },
        };
    }

    const creatorFilter = onlyMyOrders ? { _eq: userId } : {};
    const setCategoriesStatuses = orderCatogoryToStatusesMap.get(currentSetCategory)!;

    if (onlyOrdersWhereActionIsNeeded) {
      const onlyActionNeededStatuses = contextToActionNeededStatusMap.get(realm)!;
      const statusesToGet = getArraysIntersection([setCategoriesStatuses, onlyActionNeededStatuses]);
      const aggregateAllCategoryStatuses = getArraysIntersection([orderCatogoryToStatusesMap.get(OrdersCategories.ALL)!, onlyActionNeededStatuses]);
      const aggregateValidationCategoryStatuses = getArraysIntersection([orderCatogoryToStatusesMap.get(OrdersCategories.VALIDATION)!, onlyActionNeededStatuses]);
      const aggregateDeliveryCategoryStatuses = getArraysIntersection([orderCatogoryToStatusesMap.get(OrdersCategories.DELIVERY)!, onlyActionNeededStatuses]);
      const aggregatePaymentCategoryStatuses = getArraysIntersection([orderCatogoryToStatusesMap.get(OrdersCategories.PAYMENT)!, onlyActionNeededStatuses]);
      const aggregateCompletedCategoryStatuses = getArraysIntersection([orderCatogoryToStatusesMap.get(OrdersCategories.COMPLETED)!, onlyActionNeededStatuses]);

      setOrdersFilters({
        contractFilter,
        creatorFilter,
        statusesToGet,
        aggregateAllCategoryStatuses,
        aggregateValidationCategoryStatuses,
        aggregateDeliveryCategoryStatuses,
        aggregatePaymentCategoryStatuses,
        aggregateCompletedCategoryStatuses,
      });
      return;
    }

    const statusesToGet = setCategoriesStatuses;
    const aggregateAllCategoryStatuses = orderCatogoryToStatusesMap.get(OrdersCategories.ALL)!;
    const aggregateValidationCategoryStatuses = orderCatogoryToStatusesMap.get(OrdersCategories.VALIDATION)!;
    const aggregateDeliveryCategoryStatuses = orderCatogoryToStatusesMap.get(OrdersCategories.DELIVERY)!;
    const aggregatePaymentCategoryStatuses = orderCatogoryToStatusesMap.get(OrdersCategories.PAYMENT)!;
    const aggregateCompletedCategoryStatuses = orderCatogoryToStatusesMap.get(OrdersCategories.COMPLETED)!;

    setOrdersFilters({
      contractFilter,
      creatorFilter,
      statusesToGet,
      aggregateAllCategoryStatuses,
      aggregateValidationCategoryStatuses,
      aggregateDeliveryCategoryStatuses,
      aggregatePaymentCategoryStatuses,
      aggregateCompletedCategoryStatuses,
    });
  }, [orderListUiState]);

  const handleCategoryChange = useCallback((selectedCategory: OrdersCategories): void => {
    const newUiState = {
      ...orderListUiState,
      currentSetCategory: selectedCategory,
    };

    setOrderListUiState(newUiState);
    setPage(1);
    setOffset(0);
    window.history.replaceState({
      ...window.history.state,
      uiState: {
        ...newUiState,
        page: 1,
      },
    }, '');
  }, [page, orderListUiState]);

  const handlePageChange = useCallback((newPage: number): void => {
    setPage(newPage);
    setOffset(PAGE_SIZE * (newPage - 1));
    window.scrollTo(0, 0);
    window.history.replaceState({
      ...window.history.state,
      uiState: {
        ...orderListUiState,
        page: newPage,
      },
    }, '');
  }, [orderListUiState]);

  useEffect(() => {
    if (window.history.state.uiState) {
      setOrderListUiState({
        ...orderListUiState,
        ...window.history.state.uiState,
      });
      setPage(window.history.state.uiState.page);
      setOffset(PAGE_SIZE * (window.history.state.uiState.page - 1));
    }
  }, []);

  const thereAreNoOrdersToFetch = useMemo(() => {
    const { onlyMyOrders, onlyOrdersWhereActionIsNeeded } = orderListUiState;

    return countMap.ALL === 0 && !onlyMyOrders && !onlyOrdersWhereActionIsNeeded;
  }, [orderListUiState, countMap]);

  const mainComponent = useMemo(() => {
    if (thereAreNoOrdersToFetch) {
      return <EmptyPagePlaceholder {...emptyPagePlaceholderProps} sx={{ pt: 4 }} />;
    }

    return (
      <>
        <OrderTable orders={orders} />
        {!!orders.length && (
          <Pagination
            color="primary"
            sx={{
              '& .MuiPagination-ul': {
                justifyContent: 'center',
              },
            }}
            page={page}
            count={Math.ceil(countMap[orderListUiState.currentSetCategory] / PAGE_SIZE)}
            onChange={(event, newPage) => handlePageChange(newPage)}
          />
        )}
      </>
    );
  }, [orders, page, countMap, thereAreNoOrdersToFetch]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {called && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <OrderFilters ordersContracts={ordersContracts} />
            <OrderCategoriesSelection
              ordersCountByCategory={countMap}
              handleCategoryChange={handleCategoryChange}
            />
          </Box>
          { loading
            ? (
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <CircularProgress />
              </Box>
            )
            : (mainComponent)}
        </>
      )}
    </>
  );
}
