import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { SxProps } from '@mui/material';
import { currentBasketItemsAtom, currentBuyingCompanyIdAtom } from '../../basket/state/state';
import QuantitySelector from '../../common/components/quantitySelector/QuantitySelector';
import { contractAtom } from '../../state';
import useBasketItem from '../../hooks/useBasketItem';

interface UpdateBasketButtonProps {
  packagingId: string;
  supplyingCompanyId: string;
  shouldDisplayTitle?: boolean;
  addToCartButtonSx?: SxProps;
}

export default function UpdateBasketButton({ packagingId, supplyingCompanyId, shouldDisplayTitle, addToCartButtonSx }: UpdateBasketButtonProps): React.JSX.Element {
  const editBasketItem = useSetAtom(currentBasketItemsAtom);
  const buyingCompanyId = useAtomValue(currentBuyingCompanyIdAtom);
  const basketContractAtom = useMemo(() => {
    return contractAtom(buyingCompanyId!, supplyingCompanyId);
  }, [buyingCompanyId, supplyingCompanyId]);
  const contract = useAtomValue(basketContractAtom);

  const item = useBasketItem(`${packagingId}_${contract?.reference_id}`);

  const handleQuantityChange = useCallback((newQuantity: number) => {
    editBasketItem({
      id: item?.id,
      packagingId,
      quantity: newQuantity,
      contractId: contract!.reference_id!,
    });
  }, [contract, item, packagingId]);

  return (
    contract
      ? <QuantitySelector quantity={item?.quantity || 0} onQuantityChange={handleQuantityChange} shouldDisplayTitle={shouldDisplayTitle} addToCartButtonSx={addToCartButtonSx} />
      : <></>
  );
}
