import { useEffect, useState } from 'react';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { Analytics } from './common/utils/analytics';
import useCurrentBreakpoint from './hooks/useCurrentBreakpoint';
import { useIntercomReady } from './hooks/useIntercom';
import { RoutePaths } from './routes/AppRoutes';

export default function RootPage(): React.JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const matchSearchProductPage = useMatch(RoutePaths.SEARCH_PRODUCT);
  const intercomReady = useIntercomReady();
  const breakpoint = useCurrentBreakpoint();
  const [prevPath, setPrevPath] = useState(document.referrer);

  useEffect(() => {
    if (intercomReady) {
      window.Intercom('update', { hide_default_launcher: (!!matchSearchProductPage && breakpoint === 'sm') || breakpoint === 'xs' });
    }
  }, [intercomReady, breakpoint, matchSearchProductPage]);

  useEffect(() => {
    let offlineTimeoutId: number;

    window.addEventListener('offline', () => {
      offlineTimeoutId = window.setTimeout(() => {
        navigate(RoutePaths.OFFLINE);
      }, 5000);
    });

    window.addEventListener('online', () => {
      clearTimeout(offlineTimeoutId);

      if (window.location.pathname === RoutePaths.OFFLINE) {
        navigate(-1);
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== prevPath) {
      Analytics.page({
        path: location.pathname,
        referrer: prevPath,
      });
      setPrevPath(location.pathname);
    }
    window.scrollTo(0, 0);
  }, [location]);

  return <Outlet />;
}
