import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { RoutePathType } from '../../routes/AppRoutes';

export default function useCurrentTabs(tabsToMatch: RoutePathType[]): RoutePathType | false {
  const location = useLocation();

  return useMemo(() => {
    const currentTab = tabsToMatch.find((path) => location.pathname.includes(path));

    return currentTab || false;
  }, [location]);
}
