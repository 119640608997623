import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { Base_Unit_Type_Enum, Billing_Type_Enum, Measurement_Unit_Enum, Trade_Item_Unit_Descriptor_Enum } from 'kheops-graphql';
import { DraftPackaging, DraftProductPackagings, draftProductPackagingsAtom, editModeAtom } from '../form/state';

export const packagingDefaultValue: DraftPackaging = {
  trade_item_unit_descriptor: '' as Trade_Item_Unit_Descriptor_Enum,
  content_measurement_unit: '' as Measurement_Unit_Enum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unit_quantity: '' as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  net_content: '' as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  price: '' as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  base_unit_price: '' as any,
};

export const productPackagingDefaultValue: DraftProductPackagings = {
  csu: {
    csu_type: 'UNIT',
    unit_type: '' as Base_Unit_Type_Enum,
    billing_type: Billing_Type_Enum.Fixed,
    content_measurement_unit: '' as Measurement_Unit_Enum,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    suggested_retail_price: '' as any,
    gtin: '',
    photos: [],
    main_photo: undefined,
    availability: true,
  },
  packagings: [packagingDefaultValue],
};

export default function usePackagingsFormDefaultValues(): DraftProductPackagings[] {
  const draftProductPackagings = useAtomValue(draftProductPackagingsAtom);
  const editMode = useAtomValue(editModeAtom);

  const packagingsFormDefaultValues = useMemo((): DraftProductPackagings[] => {
    // eslint-disable-next-line default-case
    switch (editMode) {
      case 'CREATE':
        return [productPackagingDefaultValue];
      case 'ADD':
        return [...draftProductPackagings, productPackagingDefaultValue];
      case 'EDIT':
        return draftProductPackagings;
    }
  }, []);

  return packagingsFormDefaultValues;
}
