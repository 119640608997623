import { useTranslation } from 'react-i18next';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import { useAtomValue } from 'jotai';
import { Chat_Message_Custom_Type } from 'kheops-utils';
import { useUpdateContractMutation } from '../../mutations/__generated__/updateContract.generated';
import { ContractPropositionInfoDocument } from '../../queries/__generated__/contractPropositionInfo.generated';
import { currentContextAtom, userAtom } from '../../state';
import { ContractConditions } from '../state';
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar';
import useHandleBuyerAckNotification from './useHandleBuyerAckNotification';

export type UseMakeAnOfferResponse = (channel: GroupChannel, contract: ContractConditions, contractId: string) => Promise<void>;

export default function useMakeAnOffer(): UseMakeAnOfferResponse {
  const { t } = useTranslation(['contracts']);
  const { realm } = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const [updateContract] = useUpdateContractMutation();
  const channelContext = useChannelContext();
  const showErrorSnackbar = useErrorSnackbar(t('contracts:commercial_offer_changed_by_others'));
  const { handleBuyerAckNotification } = useHandleBuyerAckNotification();

  const makeAnOffer = async (channel: GroupChannel, offerInput: ContractConditions, contractId: string): Promise<void> => {
    const { data } = await updateContract({
      variables: {
        contractId,
        contractInput: {
          minimum_order_value: offerInput.minimum_order_value,
          discount: offerInput.discount,
          catalog_id: offerInput.catalog_id,
          proposer_user_id: userId,
          proposer_business_profile: realm,
        },
      },
      refetchQueries: [ContractPropositionInfoDocument],
    });

    if (!data?.update_contract_by_pk) {
      showErrorSnackbar();

      return;
    }

    const messageRequestHandler = await channel.sendUserMessage({
      customType: Chat_Message_Custom_Type.OFFER,
      data: JSON.stringify({
        ...offerInput,
        contract_id: data.update_contract_by_pk.id,
      }),
      message: t('contracts:new_commercial_offer'),
    });

    messageRequestHandler.onSucceeded((message) => {
      // Casts as any here because sendbird typings don't contain messagesDispatcher
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (channelContext as any).messagesDispatcher({
        type: channelContext.messageActionTypes.SEND_MESSAGE_SUCCESS,
        payload: message,
      });
      handleBuyerAckNotification(channel);
    });
  };

  return makeAnOffer;
}
