import { GroupChannel } from '@sendbird/chat/groupChannel';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useAtomValue } from 'jotai';
import { useSendBuyerRepliedToSupplierChatMutation } from '../../mutations/__generated__/sendBuyerRepliedToSupplierChat.generated';
import { currentContextAtom, userAtom } from '../../state';
import { GroupChannelMetadata } from '../state';

interface HandleBuyerAckNotification {
  handleBuyerAckNotification: (groupChannel: GroupChannel | undefined) => Promise<void>
}

export default function useHandleBuyerAckNotification(): HandleBuyerAckNotification {
  const context = useAtomValue(currentContextAtom);
  const [sendNotification] = useSendBuyerRepliedToSupplierChatMutation();
  const user = useAtomValue(userAtom);

  const handleBuyerAckNotification = async (groupChannel: GroupChannel | undefined): Promise<void> => {
    if (context.realm === Business_Profile_Enum.Buyer && groupChannel) {
      const cachedMetaData = (groupChannel.cachedMetaData as GroupChannelMetadata);

      if (!cachedMetaData.hasSentBuyerAckNotification && groupChannel.inviter) {
        const { supplyingCompanyId, buyingCompanyId, inviterBusinessProfile } = cachedMetaData;

        const channelCreatorIsSupplier = inviterBusinessProfile === Business_Profile_Enum.Supplier;

        if (channelCreatorIsSupplier) {
          const buyerName = `${user.first_name} ${user.last_name}`;

          await sendNotification({
            variables: {
              buyingCompanyId,
              supplyingCompanyId,
              buyerName,
            },
          });

          await groupChannel.updateMetaData({ hasSentBuyerAckNotification: 'true' }, true);
        }
      }
    }
  };

  return {
    handleBuyerAckNotification,
  };
}
