import { styled } from '@mui/material';

const AuthForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  width: '314px',
  paddingTop: theme.spacing(1),

  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '100%',
  },
  '& .MuiButtonBase-root': {
    margin: theme.spacing(0),
  },
}));

export default AuthForm;
