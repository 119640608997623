import { Tab, styled } from '@mui/material';

const CustomFilterTab = styled(Tab)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(6),
  marginRight: theme.spacing(1),
  '&:hover': {
    borderColor: theme.palette.greys.light,
  },
  '&.Mui-selected': {
    borderColor: theme.palette.common.black,
    color: theme.palette.greys.dark,
    fontWeight: '600',
  },
}));

export default CustomFilterTab;
