import { Box, Card, IconButton, Slide, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { ContractsByCompanyIdQuery } from '../queries/__generated__/contractsByCompanyId.generated';
import { currentContextAtom } from '../state';
import ContactableCompanyRow from './ContactableCompanyRow';
import { newChannelSlideOpenAtom } from './state';

type ContactableCompanies = ContractsByCompanyIdQuery['contract'];

interface ContactableCompanyListProps {
  contracts: ContactableCompanies;
  availableChannels: GroupChannel[];
}

export default function ContactableCompanyList({ contracts, availableChannels }: ContactableCompanyListProps): React.JSX.Element {
  const { t } = useTranslation(['chat']);
  const { realm } = useAtomValue(currentContextAtom);
  const [newChannelSlideOpen, setNewChannelSlideOpen] = useAtom(newChannelSlideOpenAtom);

  const sortedContracts = useMemo(() => {
    if (!contracts.length) {
      return [];
    }

    return contracts
      .filter(({ channel_url }) => !availableChannels.some(({ url }) => channel_url === url))
      .sort((a, b) => {
        const companyKey = realm === Business_Profile_Enum.Buyer ? 'supplying_company' : 'buying_company';

        return a[companyKey].tradeName.toLowerCase().localeCompare(b[companyKey].tradeName.toLowerCase());
      });
  }, [contracts, availableChannels]);

  return (
    <Slide direction="right" in={newChannelSlideOpen} mountOnEnter unmountOnExit>
      <Card
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          left: 0,
          width: {
            xs: `calc(100% - ${theme.spacing(4)})`,
            md: `calc(100% - ${theme.spacing(6)})`,
          },
          height: {
            xs: `calc(100% - ${theme.spacing(4)})`,
            md: `calc(100% - ${theme.spacing(6)})`,
          },
          zIndex: 1,
        })}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 700 }}>{t('chat:new_discussion')}</Typography>
          <IconButton onClick={() => setNewChannelSlideOpen(false)} color="secondary">
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, overflow: 'auto' }}>
          {sortedContracts.map((contract) => (
            <ContactableCompanyRow contract={contract} channelUrl={contract.channel_url || undefined} key={contract.id} />
          ))}
        </Box>
      </Card>
    </Slide>
  );
}
