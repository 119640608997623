import { Box, Typography } from '@mui/material';
import KheopsAvatar from '../../../common/components/KheopsAvatar';
import { CompanyRoleAssignment } from '../../../common/models/user.models';

export interface ContactsProps {
  contacts: CompanyRoleAssignment[];
}

export default function ContactList({ contacts }: ContactsProps): React.JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {contacts.map(({ user: { first_name, last_name, auth_provider_avatar_url, default_avatar } }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <KheopsAvatar userAvatars={{ auth_provider_avatar_url, default_avatar }} size={40} sx={{ borderRadius: 50 }} />
          <Typography variant="body1">
            {first_name} {last_name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
