import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useSetAtom } from 'jotai';
import { BasketOrder } from '../hooks/useBasketOrders';
import useOpenable from '../hooks/useOpenable';
import BulkRemoveFromBasketDialog from './BulkRemoveFromBasketDialog';
import { currentBasketItemsAtom } from './state/state';
import { commonSnackbarPropsAtom } from '../common/state/state';

interface BulkRemoveFromBasketButtonProps {
  order?: BasketOrder;
}

export default function BulkRemoveFromBasketButton({ order }: BulkRemoveFromBasketButtonProps): React.JSX.Element {
  const { open, close, isOpen } = useOpenable();
  const { t } = useTranslation(['basket']);
  const [basketItemsAtomData, editBasketItems] = useAtom(currentBasketItemsAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const theme = useTheme();

  const handleClose = (confirmed: boolean): void => {
    if (confirmed) {
      const basketItemsToDelete = order
        ? [...basketItemsAtomData.values()].filter((basketQuantity) => basketQuantity.contractId === order.contractId)
        : [...basketItemsAtomData.values()];

      const formattedQuantitiesToDelete = basketItemsToDelete.map((basketItem) => ({
        ...basketItem,
        quantity: 0,
      }));

      editBasketItems(...formattedQuantitiesToDelete);
      setCommonSnackbarProps({
        label: t('basket:basket_updated'),
        snackbarProps: {
          open: true,
          sx: {
            zIndex: theme.zIndex.modal + 1, // in order for the snackbar to net be hidden by basket drawer
            mr: 10,
          },
        },
      });
    }

    close();
  };

  return (
    <>
      <Button
        sx={{
          textDecoration: 'underline',
          width: '100%',
          fontSize: 14,
          alignItems: 'center',
          px: 2,
          py: 1.5,
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
        variant="text"
        color="secondary"
        startIcon={<DeleteOutlineIcon sx={{ width: 18, height: 18 }} />}
        onClick={open}
      >
        {t(order ? 'basket:remove_from_my_basket' : 'basket:empty_my_basket')}
      </Button>
      {isOpen && (
        <BulkRemoveFromBasketDialog
          onClose={handleClose}
          isOpen={isOpen}
          order={order}
        />
      )}
    </>
  );
}
