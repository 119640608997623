import { atom } from 'jotai';
import { Maybe } from 'kheops-graphql';

export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface DeliveryHoursDraft {
  weekDays: WeekDays[];
  openingHour: Date;
  closingHour: Date;
  deliveryDescription?: Maybe<string>;
}

export interface ContactsDraft {
  contactIds: string[];
}

export const deliveryHoursDrawerOpenAtom = atom(false);
