import { Box, MenuItem, Select, SxProps } from '@mui/material';
import { Ref } from 'react';
import ReactCountryFlag from 'react-country-flag';

interface CountriesPhoneCodeSelectProps {
  value?: string;
  ref?: Ref<HTMLInputElement>;
  disabled?: boolean;
  onChange: (country?: string) => void;
  selectSx: SxProps;
}

export default function CountriesPhoneCodeSelect({ value, ref, onChange, selectSx, disabled }: CountriesPhoneCodeSelectProps): React.JSX.Element {
  return (
    <Select
      value={value}
      inputRef={ref}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value || undefined)}
      sx={selectSx}
    >
      {['AD', 'BE', 'CH', 'DE', 'ES', 'FR', 'GB', 'IE', 'IT', 'LU', 'MC', 'NL', 'PT'].map((country) => (
        <MenuItem
          value={country}
          key={country}
          sx={{
            '&.Mui-selected': {
              fontWeight: 600,
              backgroundColor: 'common.white',
            },
            py: 2,
            '&:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <ReactCountryFlag countryCode={country} svg />
            {country}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}
