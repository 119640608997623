import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';

export const PackagingFamilyTypeIntl: {[key in Product_Sub_Family_Name_Enum]: string} = {
  BAKERY: 'bakery',
  BEER_CIDER: 'beer_cider',
  CLOTHING_TEXTILE: 'clothing_textile',
  DAIRY: 'dairy',
  DELI: 'deli',
  FISH_SEAFOOD: 'fish_seafood',
  FLORAL: 'floral',
  FRUITS: 'fruits',
  HOUSEHOLD_CLEANING: 'household_cleaning',
  HYGIENE_BEAUTY: 'hygiene_beauty',
  NON_ALCOHOLIC_BEVERAGES: 'non_alcoholic_beverages',
  RAW_MEAT: 'raw_meat',
  SALTY_GROCERY: 'salty_grocery',
  SPIRITS: 'spirits',
  SWEET_GROCERY: 'sweet_grocery',
  VEGETABLES: 'vegetables',
  VEGETARIAN_VEGAN: 'vegetarien_vegan',
  WINE_CHAMPAGNE_SPARKLING: 'wine_champagne_sparkling',
  FROZEN_FOOD: 'frozen_food',
  PETS: 'pets',
  GAMES_TOYS: 'games_toys',
};

export const ProductFamilyTypeIntl: {[key in Product_Family_Name_Enum]: string} = {
  BEVERAGES: 'beverages',
  FRESH_FOOD: 'fresh_food',
  GROCERY: 'grocery',
  NON_FOOD_PRODUCTS: 'non_food_products',
};
