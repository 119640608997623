import { SxProps, Theme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useClearRefinements, useSearchBox } from 'react-instantsearch';
import ClearAllButton from '../../common/components/ClearAllButton';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import useRefine from '../../hooks/useRefine';
import FilterUtils from './filter.utils';
import useDiscoveryDefaultFilters, { SearchMode } from '../../hooks/useDiscoveryDefaultFilters';
import { currentContextAtom } from '../../state';

interface ClearFiltersButtonProps {
  attributesToCheck: string[];
  excludedAttributes?: string[];
  searchMode?: SearchMode;
  sx?: SxProps<Theme>;
}

export default function ClearFiltersButton({ attributesToCheck, excludedAttributes, searchMode, sx }: ClearFiltersButtonProps): React.JSX.Element {
  const { refine: clearRefinements } = useClearRefinements({ excludedAttributes });
  const currentRefinements = useNormalizedCurrentRefinements(attributesToCheck) as string[];
  const { query, clear: clearQuery } = useSearchBox();
  const { refine, uiState } = useRefine();

  const { companyId } = useAtomValue(currentContextAtom);
  const defaultFilters = useDiscoveryDefaultFilters(searchMode);
  const signedContractFilter = FilterUtils.GetSignedContractFilter(searchMode === 'PRODUCT' ? 'buyers' : 'partners', companyId!);
  const defaultContractFiltersValue = `${defaultFilters} AND ${signedContractFilter}`;

  const clearAll = (): void => {
    clearRefinements();
    clearQuery();
    refine({
      aroundRadius: 'all',
      filters: defaultContractFiltersValue,
    });
  };

  const displayButton = useMemo((): boolean => {
    return currentRefinements.length + query.length > 0
      || Number.isInteger(uiState.aroundRadius)
      || (uiState.filters !== undefined && (defaultContractFiltersValue !== uiState.filters));
  }, [currentRefinements, query, uiState]);

  return displayButton
    ? (
      <ClearAllButton
        sx={{
          height: 40,
          ...sx,
        }}
        onClick={clearAll}
      />
    )
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <></>;
}
