import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum } from 'kheops-graphql';
import { BuyingCompanyInfo, Member, SupplyingCompanyInfo } from '../state';
import ChannelCompanyImage from '../ChannelCompanyImage';
import ChannelCompanyMember from './ChannelCompanyMember';

export interface ChannelCompanyMemberListProps {
  company: SupplyingCompanyInfo | BuyingCompanyInfo;
  companyMembers: Member[];
  businessProfile: Business_Profile_Enum;
}

export default function ChannelCompanyMemberList({ company, companyMembers, businessProfile }: ChannelCompanyMemberListProps): React.JSX.Element {
  const { t } = useTranslation(['chat']);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', my: 2, gap: 1 }}>
        <ChannelCompanyImage
          company={{
            businessProfile,
            photo: company.photos[0]?.photo,
            brand: 'brand' in company ? company.brand : undefined,
          }}
        />
        <Box>
          <Typography variant="body1">
            {company.tradeName}
          </Typography>
          <Typography variant="subtitle2">
            {t('chat:N_member', { count: companyMembers.length })}
          </Typography>
        </Box>
      </Box>
      {companyMembers.map((user) => (
        <ChannelCompanyMember member={user} key={user.id} />
      ))}
    </>
  );
}
