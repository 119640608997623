import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Typography, accordionSummaryClasses } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { productFamilyLogoMap } from '../../common/utils/file.utils';
import { ProductFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import { productFamiliesSettingsAtom } from '../state';
import { useProductSubFamiliesByFamilyQuery } from '../../queries/__generated__/productSubFamiliesByFamily.generated';
import ProductFamilyChip from './ProductFamilyChip';
import ProductFamilyChips from './ProductFamilyChips';

export interface ProductFamiliesAccordionProps {
  productFamily: Product_Family_Name_Enum;
}

export default function ProductFamiliesAccordion({ productFamily }: ProductFamiliesAccordionProps): React.JSX.Element {
  const { t } = useTranslation(['product-family', 'settings']);
  const { data: productSubFamiliesData } = useProductSubFamiliesByFamilyQuery({ variables: { productFamily } });
  const [productFamilySettings, setProductFamilySettings] = useAtom(productFamiliesSettingsAtom);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const checkProductFamilyStatus = useCallback((status: 'checked' | 'indeterminate'): boolean => {
    let result: boolean;
    const isChecked = !!productSubFamiliesData?.product_sub_family.every((subFamily) => productFamilySettings.product_sub_families?.has(subFamily.name));

    if (status === 'checked') {
      result = isChecked;
    } else {
      result = !isChecked && !!productSubFamiliesData?.product_sub_family.some((subFamily) => {
        return productFamilySettings.product_sub_families?.has(subFamily.name);
      });
    }

    return result;
  }, [productFamilySettings, productSubFamiliesData]);

  const toggleProductFamily = useCallback((): void => {
    const subFamilies = productFamilySettings.product_sub_families || new Set<Product_Sub_Family_Name_Enum>();

    if (checkProductFamilyStatus('checked')) {
      productSubFamiliesData?.product_sub_family.forEach((subFamily) => subFamilies!.delete(subFamily.name));
    } else {
      productSubFamiliesData?.product_sub_family.forEach((subFamily) => subFamilies!.add(subFamily.name));
    }

    setProductFamilySettings({
      product_sub_families: subFamilies,
    });
  }, [productFamilySettings, productSubFamiliesData]);

  return (
    <Accordion
      disableGutters
      onChange={(event, expanded) => setAccordionExpanded(expanded)}
      expanded={accordionExpanded}
      sx={{
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        my: 2,
        '&:before': {
          display: 'none',
        },
        '&:first-of-type': {
          borderRadius: 2,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        sx={{
          [`& .${accordionSummaryClasses.content}`]: {
            my: 2,
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <Box sx={{ display: 'column', ml: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={productFamilyLogoMap[productFamily]} alt={productFamily} height="40" />
            <Typography variant="h6" sx={{ ml: 2, fontWeight: 400 }}>
              {t(`product-family:${ProductFamilyTypeIntl[productFamily]}`)}
            </Typography>
          </Box>
          {!accordionExpanded && checkProductFamilyStatus('checked') && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <ProductFamilyChip label={t('settings:all_departments')} selected />
            </Box>
          )}
          {!accordionExpanded && checkProductFamilyStatus('indeterminate') && (
            <ProductFamilyChips productFamily={productFamily} selectedOnly />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
          p: 3,
          pt: 2,
          textAlign: 'center',
        }}
      >
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              onChange={() => toggleProductFamily()}
              checked={checkProductFamilyStatus('checked')}
              indeterminate={checkProductFamilyStatus('indeterminate')}
            />
          )}
          label={t('settings:select_all')}
        />
        <ProductFamilyChips productFamily={productFamily} />
      </AccordionDetails>
    </Accordion>
  );
}
