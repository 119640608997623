import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MeasurementUnitSelect from './inputs/MeasurementUnitSelect';
import TradeItemUnitSelect from './inputs/TradeItemUnitSelect';
import UnitNetContentInput from './inputs/UnitNetContentInput';

interface WeightPackagingFormProps {
  baseUnitIndex: number;
  packagingIndex: number;
}

export default function WeightPackagingForm({ baseUnitIndex, packagingIndex }: WeightPackagingFormProps): React.JSX.Element {
  const { t } = useTranslation('products');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap', mb: 0.5, fontWeight: 500 }}>
            {t('products:packaging_type')}*
          </Typography>
          <TradeItemUnitSelect name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.trade_item_unit_descriptor`} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {t('products:net_content')}*
          </Typography>
          <UnitNetContentInput name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.net_content`} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {t('products:measurement_unit')}*
          </Typography>
          <MeasurementUnitSelect name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.content_measurement_unit`} />
        </Grid>
      </Grid>
      <Typography variant="subtitle2">
        {t('products:bulk_packaging_description')}
      </Typography>
    </>
  );
}
