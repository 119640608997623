import { usePagination } from 'react-instantsearch';
import { Pagination, SxProps } from '@mui/material';
import { ChangeEvent } from 'react';

interface SearchPaginationProps {
  sx?: SxProps;
}

export default function SearchPagination({ sx }: SearchPaginationProps): React.JSX.Element {
  const { currentRefinement, nbPages, refine } = usePagination();

  const handlePaginationChange = (event: ChangeEvent<unknown>, value: number): void => {
    window.scrollTo(0, 0);
    refine(value - 1);
  };

  return (
    <Pagination
      color="primary"
      sx={{ mx: 'auto', mt: 1, ...sx }}
      count={nbPages}
      page={currentRefinement + 1}
      onChange={handlePaginationChange}
    />
  );
}
