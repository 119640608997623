import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Global_Product_Family_Enum } from 'kheops-graphql';
import ProductFamilyChip from '../settingsDialog/ProductFamilyChip';

export interface GlobalDepartmentSettingProps {
  globalSetting: Global_Product_Family_Enum
}

export default function GlobalDepartmentSetting({ globalSetting }: GlobalDepartmentSettingProps): React.JSX.Element {
  const { t } = useTranslation(['settings']);

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
      }}
    >
      <ProductFamilyChip label={t(globalSetting === Global_Product_Family_Enum.All ? 'settings:all_departments' : 'settings:no_departments')} selected />
    </Box>
  );
}
