import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import FileUtils from '../common/utils/file.utils';
import { Context } from '../common/models/user.models';
import { currentContextAtom } from '../state';
import SizedImage from '../common/components/SizedImage';

export interface ContextImageProps {
  context: Context;
  size: number;
  borderRadius: number;
}

export default function ContextImage({ context, size, borderRadius }: ContextImageProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const { realm } = useAtomValue(currentContextAtom);

  const src = useMemo((): string => {
    if (context.companyBrand) {
      return FileUtils.BuildBrandLogoURL(context.companyBrand);
    }

    return FileUtils.BuildCompanyImageUrl({
      photo: context.companyPhotos?.[0]?.photo,
      type: realm,
      size: {
        width: size,
        height: size,
      },
    });
  }, [context]);

  return (
    <SizedImage
      src={src}
      alt={t('common:company')}
      width={size}
      height={size}
      borderRadius={borderRadius}
    />
  );
}
