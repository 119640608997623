import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl } from '../common/i18n/product-family.translation';

export interface ProductFamily {
  value: Product_Sub_Family_Name_Enum;
  label: string;
}

export default function useSortedProductFamiliesMap(families: Product_Sub_Family_Name_Enum[]): ProductFamily[] {
  const { t } = useTranslation('product-family');
  const productFamiliesMap = useMemo(() => (
    families
      .map((family) => ({
        value: family,
        label: t(`product-family:${PackagingFamilyTypeIntl[family]}`),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  ), [families]);

  return productFamiliesMap;
}
