import { Box, Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { AlgoliaHit } from 'instantsearch.js';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { RoutePaths } from '../../routes/AppRoutes';
import { ProductHit } from '../../search/search';
import SearchUtils from '../../common/utils/search.utils';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { ellipsisStyle, linkStyle } from '../../common/utils/style.utils';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { imageSizeByBreakpoint } from '../state';
import { contractsAtom, currentContextAtom } from '../../state';
import UpdateBasketButton from '../../search/product/UpdateBasketButton';
import ContractRequestButton from '../../company/ContractRequestButton';
import { getProductUrlFromProductHit } from '../discovery.utils';
import QualityLabels from '../../search/product/QualityLabels';
import ImageDarkOverlay from '../../common/components/ImageDarkOverlay';

export interface DiscoverProductHitProps {
  hit: AlgoliaHit<ProductHit>;
}

export default function DiscoveryProductHit({ hit }: DiscoverProductHitProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const currencyFormat = useCurrencyFormat();
  const breakpoint = useCurrentBreakpoint();
  const contracts = useAtomValue(contractsAtom);
  const packagingUrl = getProductUrlFromProductHit(hit);
  const { companyAddress } = useAtomValue(currentContextAtom);

  const contract = useMemo(() => {
    return contracts.find(({ supplyingCompanyId }) => hit.company_id === supplyingCompanyId);
  }, [contracts]);

  const canDeliver = useMemo(() => {
    if (contract?.status !== Contract_Status_Enum.Signed && hit.delivery_zones?.length) {
      return SearchUtils.canItDeliverAnArea(hit.delivery_zones, companyAddress.administrativeAreaLevel2!);
    }

    return true;
  }, [hit, contract]);

  const ctaButton = useMemo(() => {
    return contract?.status === Contract_Status_Enum.Signed
      ? (
        <UpdateBasketButton
          packagingId={hit.packaging_id}
          supplyingCompanyId={hit.company_id}
          shouldDisplayTitle={isXs}
          addToCartButtonSx={{
            width: {
              xs: '100%',
              sm: 'initial',
            },
            height: {
              xs: 40,
              sm: 'initial',
            },
            '& .MuiTypography-root': {
              fontWeight: 400,
            },
          }}
        />
      )
      : (
        <Box sx={{ flex: { xs: 1, sm: 'initial' } }}>
          <ContractRequestButton
            companyId={hit.company_id}
            contractStatus={contract?.status}
            toolTipWording={t('contracts:you_can_command_when_supplier_is_contractualized')}
            buttonWording={isXs ? t('contracts:contact') : undefined}
            whenDisableTooltipHover={!contract?.status}
            buttonProps={{
              startIcon: <AddBusinessOutlinedIcon />,
              color: 'primary',
              sx: {
                width: {
                  xs: '100%',
                  sm: 'initial',
                },
                py: 1,
                px: 2,
                minWidth: 20,
                '& .MuiButton-startIcon': {
                  m: 0,
                },
              },
            }}
          />
        </Box>
      );
  }, [contract, isXs]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '100%',
        borderRadius: 6,
        border: '1px solid',
        borderColor: 'divider',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: {
            xs: 'row-reverse',
            sm: 'column',
          },
          alignItems: {
            xs: 'center',
            sm: 'unset',
          },
          gap: 1,
        }}
      >
        {!isXs && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              position: 'absolute',
              top: 24,
              left: 24,
              zIndex: 5,
            }}
          >
            <QualityLabels
              labels={hit.labels}
              size={32}
              iconSx={{
                backgroundColor: 'white',
                height: 32,
                width: 32,
                textAlign: 'center',
              }}
            />
          </Box>
        )}
        <Link
          to={packagingUrl}
          state={{ origin: 'DISCOVERY' }}
        >
          <SizedImage
            src={FileUtils.BuildPackagingImageUrl({ photo: hit.photo, type: hit.family, size: { ...imageSizeByBreakpoint[breakpoint], fit: 'contain' } })}
            alt={t('common:product_one')}
            height={imageSizeByBreakpoint[breakpoint].width!}
            width={imageSizeByBreakpoint[breakpoint].height!}
            borderRadius={0}
            overlay={
              !canDeliver && (
                <ImageDarkOverlay label={t('discovery:is_not_delivered_in_your_area')} />
              )
            }
          />
        </Link>
        <Box
          sx={{
            minWidth: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            my: 2,
            mr: {
              xs: 0.5,
              sm: 2,
            },
            ml: 2,
          }}
        >
          <Box
            component={Link}
            to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: hit.company_id })}
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              ...linkStyle,
              mb: 0.5,
            }}
          >
            <Box
              component="img"
              src={FileUtils.BuildCompanyImageUrl({ photo: hit.companyPhoto, type: Business_Profile_Enum.Supplier, size: { height: 24, fit: 'contain' } })}
              sx={{
                height: 24,
                width: 24,
                borderRadius: '50%',
                marginRight: 0.5,
              }}
              alt={t('common:company')}
            />
            <Typography variant="subtitle1" sx={ellipsisStyle}>
              {hit.company}
            </Typography>
          </Box>
          <Box component={Link} to={packagingUrl} sx={{ mb: 1, ...linkStyle }}>
            <Typography variant="body1" sx={{ fontWeight: 600, ...ellipsisStyle }}>
              {hit.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5, mb: 1 }}>
            <Chip label={hit.baseUnit} size="small" sx={{ mr: 0.5, backgroundColor: 'secondary.light' }} />
            {isXs && (
              <QualityLabels
                labels={hit.labels}
                size={24}
                iconSx={{
                  backgroundColor: 'white',
                  height: 24,
                  width: 24,
                  textAlign: 'center',
                }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="title" color="primary">
                {SearchUtils.getFormatedUnitPrice(hit, currencyFormat.format)}
              </Typography>
              {hit.suggestedRetailPrice && (
                <Typography variant="body1">
                  {t('price:suggest_retail_price_acronym')} : {hit.suggestedRetailPrice}
                </Typography>
              )}
            </Box>
            {!isXs && ctaButton}
          </Box>
        </Box>
      </Box>
      {isXs && (
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 2, pb: 2 }}>
          {ctaButton}
        </Box>
      )}
    </Box>
  );
}
