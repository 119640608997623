import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export default function useCurrentBreakpoint(): Breakpoint {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  let currentBreakpoint: Breakpoint;

  if (isXl) {
    currentBreakpoint = 'xl';
  } else if (isLg) {
    currentBreakpoint = 'lg';
  } else if (isMd) {
    currentBreakpoint = 'md';
  } else if (isSm) {
    currentBreakpoint = 'sm';
  } else {
    currentBreakpoint = 'xs';
  }

  return currentBreakpoint;
}
