import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { AUTH_TOKEN } from '../../common/models/auth.model';
import { dispatchAuthStateAtom } from '../../common/state/state';
import { AuthActionType } from './authActionsTypes';

export default function useAuthProvider(): void {
  const dispatch = useSetAtom(dispatchAuthStateAtom);

  useEffect(() => {
    const jsonToken = localStorage.getItem(AUTH_TOKEN);

    if (jsonToken) {
      return dispatch({ type: AuthActionType.RESTORE_TOKEN });
    }
    return dispatch({ type: AuthActionType.LOGOUT });
  }, []);
}
