import { Box, Card, IconButton, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl, ProductFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import { ellipsisStyle } from '../../common/utils/style.utils';
import { currentFamilyAtom, currentSubFamilyAtom, discoveryViewAtom, navigationDescriptionKeyByFamily, navigationDescriptionOfSuppliersKeyByFamily, productSubFamilyImageMap } from '../state';
import { DiscoveryNavigationItem, getProductFamilyNavigationItems, getProductSubFamilyNavigationItems } from '../discovery.utils';
import useNavigateByProductFamily from '../hooks/useNavigateByProductFamily';

interface DiscoveryCarrouselNavigationSlideProps {
  item: DiscoveryNavigationItem;
  onClick: (family: Product_Family_Name_Enum | Product_Sub_Family_Name_Enum) => void;
}

export function DiscoveryCarrouselNavigationSlide({ item, onClick }: DiscoveryCarrouselNavigationSlideProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        cursor: 'pointer',
        width: {
          xs: 124,
          sm: 173,
          lg: 198,
        },
      }}
      onClick={() => onClick(item.family)}
    >
      <Box
        component="img"
        src={item.img}
        sx={{
          borderRadius: 4,
          objectFit: 'cover',
          width: {
            xs: 124,
            sm: 173,
            lg: 198,
          },
          height: {
            xs: 88,
            sm: 104,
            lg: 120,
          },
        }}
      />
      <Typography variant="body1" sx={{ ...ellipsisStyle }}>
        {item.label}
      </Typography>
    </Box>
  );
}

export default function DiscoveryCarrouselNavigation(): React.JSX.Element {
  const { t } = useTranslation(['discovery', 'product-family']);
  const currentFamily = useAtomValue(currentFamilyAtom);
  const currentSubFamily = useAtomValue(currentSubFamilyAtom);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const discoveryView = useAtomValue(discoveryViewAtom);
  const { navigate } = useNavigateByProductFamily();

  const items = useMemo((): DiscoveryNavigationItem[] => {
    return currentFamily !== 'ALL'
      ? getProductSubFamilyNavigationItems(t, currentFamily)
      : getProductFamilyNavigationItems(t);
  }, [currentFamily]);

  useEffect((): void => {
    let newTitle: string;
    let newDescription: string;
    const isProductView = discoveryView === 'PRODUCTS';

    if (currentFamily === 'ALL') {
      newTitle = t(isProductView ? 'discovery:all_products' : 'discovery:all_suppliers');
      newDescription = t(isProductView ? 'discovery:browse_all_products' : 'discovery:browse_all_suppliers');
    } else if (currentSubFamily === 'ALL') {
      newTitle = t(`product-family:${ProductFamilyTypeIntl[currentFamily]}`);
      newDescription = t(`discovery:${isProductView ? navigationDescriptionKeyByFamily[currentFamily] : navigationDescriptionOfSuppliersKeyByFamily[currentFamily]}`);
    } else {
      newTitle = t(`product-family:${PackagingFamilyTypeIntl[currentSubFamily]}`);
      newDescription = t(`discovery:${isProductView ? navigationDescriptionKeyByFamily[currentSubFamily] : navigationDescriptionOfSuppliersKeyByFamily[currentSubFamily]}`);
    }

    setTitle(newTitle);
    setDescription(`${newDescription} ${t('discovery:brighten_up_your_store_catalog')}`);
  }, [currentFamily, currentSubFamily, discoveryView]);

  const handleNavigation = (family: Product_Family_Name_Enum | Product_Sub_Family_Name_Enum | 'ALL'): void => {
    if (currentFamily !== 'ALL') {
      navigate(currentFamily, family as Product_Sub_Family_Name_Enum);
    } else {
      navigate(family as Product_Family_Name_Enum, 'ALL');
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: currentSubFamily !== 'ALL' ? 'row' : 'column',
        gap: {
          xs: 1,
          sm: currentSubFamily !== 'ALL' ? 2 : 3,
        },
        backgroundColor: {
          xs: 'transparent',
          sm: 'white',
        },
        px: {
          xs: 1,
          sm: 3,
        },
        pt: {
          xs: 1,
          sm: 3,
        },
        pb: {
          xs: 0,
          sm: 3,
        },
      }}
    >
      {currentSubFamily !== 'ALL' && (
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => navigate(currentFamily, 'ALL')}
            color="secondary"
            sx={{
              backgroundColor: 'white',
              position: {
                xs: 'initial',
                sm: 'absolute',
              },
              top: '8px',
              left: '8px',
              ':hover': {
                backgroundColor: 'white',
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box
            component="img"
            src={productSubFamilyImageMap[currentSubFamily]}
            sx={{
              borderRadius: 4,
              height: 132,
              width: 132,
              display: {
                xs: 'none',
                sm: 'block',
              },
            }}
          />
        </Box>
      )}
      <Box>
        <Box sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
          {(currentFamily !== 'ALL' && currentSubFamily === 'ALL') && (
            <IconButton
              onClick={() => navigate('ALL', 'ALL')}
              color="secondary"
              sx={{
                backgroundColor: {
                  xs: 'white',
                  sm: 'secondary.light',
                },
                ':hover': {
                  xs: 'white',
                  sm: 'secondary.light',
                },
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h1" sx={{ fontFamily: 'ambit', fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ display: { xs: 'none', lg: 'block' } }}>
          {description}
        </Typography>
      </Box>
      {currentSubFamily === 'ALL' && (
        <Tabs
          sx={{
            '& .MuiTabs-flexContainer': {
              gap: 2,
            },
            gap: 1,
          }}
          variant="scrollable"
          TabScrollButtonProps={{
            slots: {
              StartScrollButtonIcon: ArrowBackIcon,
              EndScrollButtonIcon: ArrowForwardIcon,
            },
            sx: {
              '&.Mui-disabled': {
                opacity: 1,
                color: 'text.disabled',
              },
              display: {
                xs: 'none',
                md: 'inline-flex',
              },
              opacity: 1,
              backgroundColor: 'white',
              width: 40,
              height: 40,
              alignSelf: 'center',
              borderRadius: 10,
            },
          }}
        >
          {items.map((item) => {
            return (
              <Tab
                key={item.family}
                component={DiscoveryCarrouselNavigationSlide}
                item={item}
                onClick={(family) => handleNavigation(family)}
              />
            );
          })}
        </Tabs>
      )}
    </Card>
  );
}
