import { useCallback, useState } from 'react';

export interface UseOpenableValues {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export default function useOpenable(): UseOpenableValues {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  return {
    isOpen,
    open,
    close,
  };
}
