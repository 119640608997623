import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { Product_Label_Name_Enum } from 'kheops-graphql';
import { FormatPackagingLabels, LabelImage } from '../../packaging/packaging-formatter';

interface QualityLabelsProps {
  labels: Product_Label_Name_Enum[];
  size: number;
  iconSx?: SxProps<Theme>;
}

export default function QualityLabels({ labels, size, iconSx }: QualityLabelsProps): React.JSX.Element {
  const labelImages = FormatPackagingLabels(labels.map((label) => ({ label })));

  return (
    <>
      {labelImages.slice(0, 2).map((label: LabelImage) => (
        <Box sx={{ lineHeight: 'normal', ...iconSx }} key={label.src}>
          <img src={label.src} alt={label.alt} height={size} />
        </Box>
      ))}
      {labelImages.length > 2 && (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            width: size,
            height: size,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            color: 'text.primary',
          }}
        >
          +{labelImages.length - 2}
        </Box>
      )}
    </>
  );
}
