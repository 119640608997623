import { Configure, InstantSearch } from 'react-instantsearch';
import { useSearchClient } from '../../hooks/useSearchClient';
import CarrouselHits, { SlideProps } from './CarrouselHits';
import DiscoveryNavigationWrapper from '../navigation/DiscoveryNavigationWrapper';
import { OnStateChangeArgs } from '../../search/state/state';

export interface DiscoveryCarrouselProps {
  slide: ({ hit }: SlideProps) => React.JSX.Element;
  index: string;
  filters: string;
  attribute: string;
}

export default function DiscoveryCarrousel({ slide, index, filters, attribute }: DiscoveryCarrouselProps): React.JSX.Element {
  const searchClient = useSearchClient();

  // This state change handler is a simple pipe that seems useless.
  // However, setting a state change handler makes instant search debounce refines,
  // which is useful in DiscoveryNavigationWrapper.
  const onStateChange = ({ uiState, setUiState }: OnStateChangeArgs): void => {
    setUiState(uiState);
  };

  return (
    <InstantSearch indexName={index} searchClient={searchClient} onStateChange={onStateChange}>
      <Configure filters={filters} hitsPerPage={10} />
      <DiscoveryNavigationWrapper attribute={attribute} ignoreHistory>
        <CarrouselHits slide={slide} />
      </DiscoveryNavigationWrapper>
    </InstantSearch>
  );
}
