import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductVersionByIdQuery } from '../queries/__generated__/productVersionById.generated';
import FileUtils from '../common/utils/file.utils';
import SizedImage from '../common/components/SizedImage';

type Photo = NonNullable<ProductVersionByIdQuery['product']>[number]['base_units'][number]['photos'][number]['photo']

interface BaseUnitPhotosDisplay {
  mainPhoto?: Photo | null;
  photos: Photo[];
  productSubFamily: string;
}

export default function BaseUnitPhotosDisplay({ mainPhoto, photos, productSubFamily }: BaseUnitPhotosDisplay): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const [shouldDisplaySkeleton, setShouldDisplaySkeleton] = useState(true);
  const [photoOnMainDisplay, setPhotoOnMainDisplay] = useState<Photo | null | undefined>(mainPhoto);

  useEffect(() => {
    setPhotoOnMainDisplay(mainPhoto);
  }, [mainPhoto]);

  const handlePhotoSelection = (photo: Photo): void => {
    setPhotoOnMainDisplay(photo);
    setShouldDisplaySkeleton(true);
  };

  const photosSelectionComponent = useMemo(() => {
    return photos.map((photo) => {
      const isPhotoDisplayedOnMainDisplay = photo.path === photoOnMainDisplay?.path;
      return (
        <Button
          onClick={() => handlePhotoSelection(photo)}
          disabled={photos.length === 1}
          disableRipple
        >
          <Box
            key={photo.path}
            component="img"
            alt={photo.path}
            src={FileUtils.BuildPackagingImageUrl({ photo, type: productSubFamily, size: { height: 48, width: 48, fit: 'contain' } })}
            sx={{
              borderRadius: 4,
              borderColor: isPhotoDisplayedOnMainDisplay ? 'divider' : 'transparent',
              borderWidth: 2,
              borderStyle: 'solid',
              '&:hover': {
                borderStyle: 'solid',
                borderColor: isPhotoDisplayedOnMainDisplay ? 'divider' : 'greys.light',
              },
              '&:active': {
                borderColor: 'divider',
              },
              p: 2,
              height: 48,
              width: 48,
              objectFit: 'contain',
            }}
          />
        </Button>
      );
    });
  }, [photoOnMainDisplay]);

  return (

    <Box
      sx={{
        width: { lg: 600, xs: 'fit-content' },
        display: 'flex',
        alignSelf: { lg: 'inherit', xs: 'center' },
        flexDirection: {
          lg: 'row',
          xs: 'column-reverse',
        },
      }}
    >
      {photosSelectionComponent.length > 1
            && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  mt: 2,
                  maxHeight: { xs: 200, sm: 'inherit', lg: 600 },
                  flexDirection: {
                    lg: 'column',
                    md: 'row',
                  },
                  justifyContent: { xs: 'center', lg: 'flex-start' },
                  gap: 1,
                }}
              >
                {photosSelectionComponent}
              </Box>
            )}
      {
        shouldDisplaySkeleton
          && (
          <Skeleton
            variant="rectangular"
            sx={{
              mx: 'auto',
              width: {
                lg: 400,
                xs: 300,
              },
              height: {
                lg: 400,
                xs: 300,
              },

            }}
          />
          )
      }
      <SizedImage
        src={FileUtils.BuildPackagingImageUrl({
          photo: photoOnMainDisplay,
          type: productSubFamily,
          size: { height: 434, fit: 'cover' },
        })}
        alt={t('products:csu')}
        sx={{
          mx: 'auto',
          borderRadius: 0,
          height: shouldDisplaySkeleton ? 0 : 'inherit',
          width: shouldDisplaySkeleton ? 0 : undefined,
        }}
        width={{ lg: 400, xs: 300 }}
        height={{ lg: 400, xs: 300 }}
        onLoad={() => setShouldDisplaySkeleton(false)}
      />

    </Box>
  );
}
