import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { currentContextAtom } from '../../../state';
import { deliveryHoursDrawerOpenAtom } from '../state';
import DeliveryHoursForm from './DeliveryHoursForm';
import EmptyDeliveryHours from './EmptyDeliveryHours';
import DeliveryHours from './DeliveryHours';
import FormDrawer from '../FormDrawer';

export default function DeliveryHoursInfo(): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const { deliveryHours, deliveryDescription } = useAtomValue(currentContextAtom);
  const [deliveryHoursDrawerOpen, setDeliveryHoursDrawerOpen] = useAtom(deliveryHoursDrawerOpenAtom);

  return (
    <>
      <Box
        sx={{
          '& .MuiTypography-root': {
            lineHeight: 'normal',
          },
        }}
      >
        {
          deliveryHours?.length
            ? <DeliveryHours deliveryHours={deliveryHours} deliveryDescription={deliveryDescription} enableEdit />
            : <EmptyDeliveryHours />
        }
      </Box>
      <FormDrawer
        open={deliveryHoursDrawerOpen}
        onClose={() => setDeliveryHoursDrawerOpen(false)}
        title={t('settings:add_delivery_hours')}
        FormComponent={DeliveryHoursForm}
      />
    </>
  );
}
