import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Document_Type_Enum } from 'kheops-graphql';
import { useGetUploadUrlLazyQuery } from '../queries/__generated__/getUploadUrl.generated';
import FileUtils, { FileParameters } from '../common/utils/file.utils';
import { useErrorSnackbar } from './useErrorSnackbar';

export type FileTypes = Document_Type_Enum | 'COMPANY_PHOTOS' | 'PACKAGING_PHOTOS' | 'USER_INVOICE';

export const SUPPORTED_IMAGE_TYPES = [
  'image/jp2',
  'image/jpe',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];
interface UseUploadFileProps {
  type: FileTypes;
  pathId: string;
}
interface UseUploadFile {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  isFileUploadLoading: boolean;
  fileParameters: FileParameters | undefined;
}

const MAXIMUM_FILE_SIZE_IN_BYTES = 50 * 1024 * 1024;

export function useUploadFile({ type, pathId }: UseUploadFileProps): UseUploadFile {
  const { t } = useTranslation(['order', 'error']);
  const [getUploadUrlLazyQuery, { data: getUploadUrlData, loading }] = useGetUploadUrlLazyQuery({ fetchPolicy: 'no-cache' });
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [fileParameters, setFileParameters] = useState<FileParameters>();
  const [fileToUpload, setFileToUpload] = useState<File>();
  const handleFileError = useErrorSnackbar(t('order:document_upload_failed'));
  const displayFileTooLargeError = useErrorSnackbar(t('error:file_too_large', { size: '50MB' }));

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files![0];

    if (file.size > MAXIMUM_FILE_SIZE_IN_BYTES) {
      displayFileTooLargeError();
      throw new Error('File size exceeds limited size of 50MB');
    }
    setFileToUpload(file);
    setIsFileUploadLoading(true);

    const fileName = FileUtils.NormalizeFileName(file.name);

    await getUploadUrlLazyQuery({
      variables: {
        fileType: type,
        fileOriginalName: fileName,
        pathId,
      },
    });
  };

  useEffect(() => {
    if (getUploadUrlData && !loading) {
      fetch(getUploadUrlData.getUploadUrl!.uploadUrl!, {
        method: 'PUT',
        body: fileToUpload,
      }).then((res) => {
        if (res.status === 200) {
          setFileParameters(FileUtils.GetFileParametersFromUrl(getUploadUrlData.getUploadUrl!.uploadUrl!.split('?')[0]));
        } else if (res.status >= 400) {
          handleFileError();
          res.text().then((message) => {
            Sentry.captureException(new Error(message));
          });
        }

        setIsFileUploadLoading(false);
      });
    }
  }, [getUploadUrlData, loading]);

  return {
    handleFileChange,
    isFileUploadLoading,
    fileParameters,
  };
}
