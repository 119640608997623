import SearchIcon from '@mui/icons-material/Search';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Checkbox, FormControlLabel, TextField, Box, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import FilterButton from './FilterButton';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';

interface CompanyFilterProps {
  attribute: string;
}

export default function CompanyFilter({ attribute }: CompanyFilterProps): React.JSX.Element {
  const { t } = useTranslation(['search']);
  const { items, refine } = useRefinementList({ attribute, limit: 100 });
  const currentRefinements = useNormalizedCurrentRefinements(attribute);
  const [itemQuery, setItemQuery] = useState('');

  const handleTextChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setItemQuery(event.target.value);
  }, []);

  const companyOptions = useMemo((): React.JSX.Element[] => (
    items
      .filter(({ label }: { label: string }) => label.toLowerCase().includes(itemQuery.toLowerCase()))
      .map(({ value: company }) => (
        <FormControlLabel
          key={company}
          control={<Checkbox checked={currentRefinements.includes(company)} />}
          onChange={() => refine(company)}
          label={company}
          sx={{
            marginLeft: 0,
            '&:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
          }}
        />
      ))
  ), [items, itemQuery, currentRefinements]);

  return (
    <FilterButton
      label={t('search:suppliers')}
      buttonProps={{
        startIcon: <PersonOutlineIcon />,
      }}
      onClose={() => setItemQuery('')}
      activeItemCount={currentRefinements.length}
      content={(
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            size="small"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" sx={{ marginRight: 1 }} />,
            }}
            onChange={handleTextChange}
            value={itemQuery}
            placeholder={t('search:search_a_particular_supplier')}
          />
          <Typography sx={{ marginTop: 2, marginBottom: 1 }} variant="h6">{t('search:select_one_or_several_suppliers')}</Typography>
          {companyOptions}
        </Box>
      )}
    />
  );
}
