import { Box, InputAdornment, SxProps, Theme } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import CheckboxAutocomplete from '../../common/components/CheckboxAutocomplete';
import useSortedProductFamiliesMap from '../../hooks/useSortedProductFamiliesMap';
import SearchInput from '../../common/components/SearchInput';
import ClearAllButton from '../../common/components/ClearAllButton';

export interface DefaultValues {
  productFamilies: Product_Sub_Family_Name_Enum[];
  query: string;
}

export interface ProductSearchBarProps {
  productFamiliesOptions: Product_Sub_Family_Name_Enum[];
  onChange: (productFamilies: Product_Sub_Family_Name_Enum[], query: string) => void;
  defaultValues?: Partial<DefaultValues>;
  sx?: SxProps<Theme>;
}

export default function ProductSearchBar({ productFamiliesOptions, onChange, sx, defaultValues }: ProductSearchBarProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const productFamilyMap = useSortedProductFamiliesMap(productFamiliesOptions);
  const [productFamilies, setProductFamilies] = useState<Product_Sub_Family_Name_Enum[]>(defaultValues?.productFamilies || []);
  const selectedProductFamilyMap = useSortedProductFamiliesMap(productFamilies);
  const [query, setQuery] = useState(defaultValues?.query || '');

  useEffect((): void => {
    onChange(productFamilies, query);
  }, [productFamilies, query]);

  const clearAll = (): void => {
    setQuery('');
    setProductFamilies([]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 2,
        ...sx,
      }}
    >
      <CheckboxAutocomplete
        options={productFamilyMap}
        onChange={(values) => setProductFamilies(values.map((option) => option.value))}
        values={selectedProductFamilyMap}
        label={t('discovery:departments')}
        getOptionLabel={(option) => option.label}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0.5 }}>
              <ShoppingBasketOutlinedIcon sx={{ ml: 0.5, fontSize: '1.125rem' }} />
            </InputAdornment>
          ),
          sx: {
            minHeight: 48,
          },
        }}
      />
      <SearchInput
        onChange={(inputQuery) => setQuery(inputQuery)}
        value={query}
        sx={{
          height: 48,
          width: {
            xs: '100%',
            sm: 268,
          },
        }}
      />
      {
        query.length + productFamilies.length > 0 && (
        <ClearAllButton
          onClick={clearAll}
        />
        )
      }
    </Box>
  );
}
