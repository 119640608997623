import { Box, Pagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { ProductFragment } from '../products';
import ProductListItem from './ProductListItem';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import { RoutePaths } from '../../routes/AppRoutes';
import ProductSearchBar from '../common/ProductSearchBar';
import { areArraysEqual, insensitiveStringIncludes } from '../../common/utils/common.utils';
import { productFiltersAtom } from './state';

export interface ProductListProps {
  products: ProductFragment[];
}

export default function ProductList({ products }: ProductListProps): React.JSX.Element {
  const PAGE_SIZE = 20;
  const { t } = useTranslation(['common', 'products']);

  const [filteredProducts, setFilteredProducts] = useState<ProductFragment[]>(products);
  const [productFilters, setProductFilters] = useAtom(productFiltersAtom);

  const productFamiliesOptions = useMemo((): Product_Sub_Family_Name_Enum[] => {
    const options = new Set<Product_Sub_Family_Name_Enum>();

    products.forEach((product) => options.add(product.sub_family as Product_Sub_Family_Name_Enum));

    return [...options.values()];
  }, [products]);

  const productItems = useMemo((): ProductFragment[] => {
    const start = PAGE_SIZE * (productFilters.page - 1);

    return filteredProducts.slice(start, start + PAGE_SIZE);
  }, [productFilters.page, filteredProducts]);

  const handleProductSearchChange = useCallback((productFamilies: Product_Sub_Family_Name_Enum[], query: string): void => {
    if (areArraysEqual(productFamilies, productFilters.productFamilies) && query === productFilters.query) {
      return;
    }

    setProductFilters({ productFamilies, query, page: 1 });
  }, [products, productFilters]);

  const handlePageChange = useCallback((newPage: number): void => {
    setProductFilters({ ...productFilters, page: newPage });
  }, [productFilters]);

  useEffect(() => {
    const newFilteredProducts = products.filter((product) => {
      const matchFamilies = !productFilters.productFamilies.length || productFilters.productFamilies.includes(product.sub_family as Product_Sub_Family_Name_Enum);
      const matchSearch = !productFilters.query || insensitiveStringIncludes(product.name, productFilters.query) || product.base_units.some((base_unit) => base_unit.gtin === productFilters.query);

      return matchFamilies && matchSearch;
    });

    setFilteredProducts(newFilteredProducts);
  }, [products, productFilters]);

  return (
    <>
      <ProductSearchBar
        productFamiliesOptions={productFamiliesOptions}
        onChange={handleProductSearchChange}
        defaultValues={productFilters}
        sx={{
          mt: 1,
          mb: 4,
          display: {
            xs: 'none',
            lg: 'flex',
          },
        }}
      />
      {
        products.length
          ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
              {productItems.map((product) => (
                <ProductListItem product={product} />
              ))}
              <Pagination
                color="primary"
                page={productFilters.page}
                count={Math.ceil(filteredProducts.length / PAGE_SIZE)}
                onChange={(event, newPage) => handlePageChange(newPage)}
                sx={{ m: 'auto' }}
              />
            </Box>
          ) : (
            <EmptyPagePlaceholder
              title={t('products:no_product')}
              description={t('products:no_product_description')}
              imageSrc={TrumpeterButterflyImage}
              buttonLabel={t('products:add_a_product')}
              linkDestination={generatePath(RoutePaths.PRODUCT_ADD_INFO)}
              sx={{ pt: 0 }}
              buttonProps={{
                startIcon: <AddIcon />,
              }}
            />
          )
      }
    </>
  );
}
