import { Business_Profile_Enum } from 'kheops-graphql';
import { useAtomValue } from 'jotai';
import { isVisibleInDiscoveryFilter } from '../search/discovery.utils';
import { currentContextAtom } from '../state';

export type SearchMode = 'PRODUCT' | 'COMPANY';

export default function useDiscoveryDefaultFilters(searchMode?: SearchMode): string {
  const { realm } = useAtomValue(currentContextAtom);

  if (searchMode === 'PRODUCT') {
    return isVisibleInDiscoveryFilter;
  }

  return realm === Business_Profile_Enum.Buyer ? 'business_profile:SUPPLIER' : 'business_profile:BUYER';
}
