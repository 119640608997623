import { Container } from '@mui/material';
import { ReactNode } from 'react';
import { Box, SxProps, useTheme } from '@mui/system';

export interface PageWrapperProps {
  children?: ReactNode;
  fullPage?: boolean;
  sx?: SxProps;
}

export function PageWrapper({ children, fullPage, sx }: PageWrapperProps): React.JSX.Element {
  const theme = useTheme();
  return (
    <Box
      sx={{
        pt: 3,
        mb: 2,
        [theme.breakpoints.down('sm')]: {
          px: 1,
        },
        ...sx,
      }}
    >
      {fullPage
        ? children
        : (
          <Container
            maxWidth="xl"
            fixed
            disableGutters
          >
            {children}
          </Container>
        )}
    </Box>
  );
}
