import { Chip, SxProps } from '@mui/material';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import GeolocIcon from '../../assets/icons/geoloc.svg?react';
import { currentContextAtom } from '../../state';
import { computeDistanceInKm } from '../utils/common.utils';

interface CompanyDistanceChipProps {
  targetGeoLoc: { lat: number, lng: number };
  iconSize?: number;
  sx?: SxProps;
}

export default function CompanyDistanceChip({ targetGeoLoc, sx, iconSize = 16 }: CompanyDistanceChipProps): React.JSX.Element {
  const { companyGeoloc } = useAtomValue(currentContextAtom);

  const distance = useMemo((): string => {
    if (!companyGeoloc || !targetGeoLoc) {
      return '';
    }

    const result = computeDistanceInKm(
      targetGeoLoc.lat,
      targetGeoLoc.lng,
      companyGeoloc.lat,
      companyGeoloc.lng,
    ).toFixed(0);

    return `${result} km`;
  }, [targetGeoLoc, companyGeoloc]);

  return (
    <Chip
      color="layout"
      icon={<GeolocIcon height={iconSize} width={iconSize} />}
      label={distance}
      size="small"
      sx={{ ...sx }}
    />
  );
}
