import { Box, Button, Checkbox, Divider, FormControlLabel, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TuneIcon from '@mui/icons-material/Tune';
import CircleIcon from '@mui/icons-material/Circle';
import { Business_Profile_Enum } from 'kheops-graphql';
import { currentContextAtom } from '../../state';
import { OrderContract } from '../../hooks/useOrders';
import useOpenable from '../../hooks/useOpenable';
import FilterTag from '../../search/filters/FilterTag';
import FiltersDialog from '../../search/filters/FiltersDialog';
import FileUtils from '../../common/utils/file.utils';
import CheckboxAutocomplete from '../../common/components/CheckboxAutocomplete';
import { orderListPageAtom, OrderListUiState, orderListUiStateAtom, PartnerOption } from '../state/state';
import ClearAllButton from '../../common/components/ClearAllButton';

interface OrderFiltersProps {
  ordersContracts: OrderContract[];
}

export default function OrderFilters({ ordersContracts }: OrderFiltersProps): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(['common', 'order']);
  const { realm } = useAtomValue(currentContextAtom);
  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState(searchParams.get('companyId'));
  const [orderListUiState, setOrderListUiState] = useAtom(orderListUiStateAtom);
  const setOrderListPage = useSetAtom(orderListPageAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { close, isOpen, open } = useOpenable();

  const thereAreActiveFilters = useMemo(() => (
    orderListUiState.onlyMyOrders || !!orderListUiState.selectedPartners.length || orderListUiState.onlyOrdersWhereActionIsNeeded
  ), [orderListUiState]);

  const partnerOptions = useMemo((): PartnerOption[] => {
    return ordersContracts
      .reduce((acc, contract) => {
        if (!acc.some((option) => contract.company.id === option.value)) {
          acc.push({
            label: contract.company.tradeName,
            value: contract.company.id,
            photo: FileUtils.BuildCompanyImageUrl({ photo: contract.company.photos[0]?.photo, type: 'SUPPLIER', size: { height: 24, width: 24 } }),
          });
        }

        return acc;
      }, [] as PartnerOption[])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [ordersContracts]);

  const selectedPartners = useMemo((): PartnerOption[] => {
    return orderListUiState.selectedPartners;
  }, [orderListUiState]);

  const onOrderListUiStateUpdate = (newState: OrderListUiState): void => {
    setOrderListPage(1);

    window.history.replaceState({
      ...window.history.state,
      uiState: {
        ...newState,
        page: 1,
      },
    }, '');
  };

  const updateOrderUiState = useCallback((state: OrderListUiState): void => {
    setOrderListUiState(state);
    onOrderListUiStateUpdate(state);
  }, []);

  const handleOnlyMyOrdersChange = useCallback((checked: boolean): void => {
    const newUiState = {
      ...orderListUiState,
      onlyMyOrders: checked,
    };

    updateOrderUiState(newUiState);
  }, [orderListUiState]);

  const handlePartnersChange = useCallback((values: PartnerOption[]): void => {
    const newUiState = {
      ...orderListUiState,
      selectedPartners: values,
    };

    setOrderListUiState(newUiState);
    onOrderListUiStateUpdate(newUiState);
  }, [orderListUiState]);

  const removePartner = useCallback((partner: PartnerOption): void => {
    const newPartners = selectedPartners.filter(({ value }) => value !== partner.value);

    handlePartnersChange(newPartners);
  }, [selectedPartners, handlePartnersChange]);

  const handleOnlyOrdersWhereActionIsNeededChange = useCallback((checked: boolean): void => {
    const newUiState: OrderListUiState = {
      ...orderListUiState,
      onlyOrdersWhereActionIsNeeded: checked,
    };

    updateOrderUiState(newUiState);
  }, [orderListUiState]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (isXs) {
      open();

      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect((): void => {
    if (ordersContracts.length && companyId) {
      const contract = ordersContracts.find(({ company }) => company.id === companyId);

      setCompanyId(null);

      if (contract) {
        handlePartnersChange([{
          label: contract.company.tradeName,
          value: contract.company.id,
          photo: FileUtils.BuildCompanyImageUrl({ photo: contract.company.photos[0]?.photo, type: 'SUPPLIER', size: { height: 24, width: 24 } }),
        }]);
      }
    }
  }, [ordersContracts, companyId]);

  const clearAll = (): void => {
    const newUiState: OrderListUiState = {
      ...orderListUiState,
      selectedPartners: [],
      onlyOrdersWhereActionIsNeeded: false,
      onlyMyOrders: false,
    };
    updateOrderUiState(newUiState);
  };

  const companyRelatedFilters = useMemo((): React.JSX.Element => {
    const clientWording = t(`common:${realm === Business_Profile_Enum.Buyer ? 'supplier_other' : 'customer_other'}`);

    return (
      <Box>
        <Typography
          variant="body1"
          fontWeight={600}
          sx={{
            mb: 2,
          }}
        >
          {clientWording}
        </Typography>
        <CheckboxAutocomplete
          options={partnerOptions}
          onChange={handlePartnersChange}
          label={clientWording}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, selected) => option.value === selected.value}
          values={selectedPartners}
          InputProps={{
            sx: {
              minHeight: 48,
            },
          }}
        />
      </Box>
    );
  }, [partnerOptions, selectedPartners]);

  const orderRelatedFilters = useMemo(() => (
    <Box
      sx={{
        mt: 1,
      }}
    >
      <Typography
        variant="body1"
        fontWeight={600}
      >
        {t('common:orders')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        { realm === Business_Profile_Enum.Buyer && (
        <Box>
          <FormControlLabel
            control={<Checkbox sx={{ py: 0 }} checked={orderListUiState.onlyMyOrders} />}
            onChange={(_, checked) => handleOnlyMyOrdersChange(checked)}
            label={t('order:only_orders_created_by_me')}
            sx={{
              whiteSpace: 'normal',
              alignItems: 'flex-start',
              py: 2,
              mr: 0,
            }}
          />
          <Divider />
        </Box>
        )}
        <FormControlLabel
          control={<Checkbox sx={{ py: 0 }} checked={orderListUiState.onlyOrdersWhereActionIsNeeded} />}
          onChange={(_, checked) => handleOnlyOrdersWhereActionIsNeededChange(checked)}
          label={t('order:display_orders_where_action_needed')}
          sx={{
            whiteSpace: 'normal',
            alignItems: 'flex-start',
            pt: 2,
            pb: 1,
            mr: 0,
          }}
        />
      </Box>
    </Box>

  ), [orderListUiState]);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      <Button
        startIcon={<TuneIcon />}
        endIcon={
          thereAreActiveFilters
            ? (
              <CircleIcon
                color="primary"
                sx={{
                  width: 8,
                  height: 8,
                }}
              />
            )
            : undefined
        }
        variant="outlined"
        color="secondary"
        sx={{
          fontSize: '0.875rem',
          borderRadius: 4,
          px: 2,
          height: 32,
          width: 'fit-content',
        }}
        onClick={handleClick}
      >
        {t('common:filters')}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width: 300,
            borderRadius: 4,
            mt: 1,
            p: 1,
            pb: 3,
          },
          '& .MuiMenuItem-root': {
            cursor: 'default',
          },
          '& .MuiMenuItem-root.Mui-focusVisible, & .MuiMenuItem-root:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <MenuItem disableRipple>
          {companyRelatedFilters}
        </MenuItem>
        <MenuItem disableRipple>
          {orderRelatedFilters}
        </MenuItem>
      </Menu>
      <FiltersDialog
        open={isOpen}
        onClose={close}
      >
        <Box
          sx={{
            backgroundColor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 6,
            p: 3,
            mt: 2,
            gap: 2,
          }}
        >
          {companyRelatedFilters}
          {orderRelatedFilters}
        </Box>
      </FiltersDialog>
      {orderListUiState.onlyMyOrders && (
        <FilterTag
          chipProps={{
            label: t('order:orders_created_by_me'),
            icon: <TuneIcon />,
            onDelete: () => handleOnlyMyOrdersChange(false),
            sx: { height: 32, py: 1, m: 0 },
          }}
          variant="outlined"
          size="medium"
        />
      )}
      {orderListUiState.onlyOrdersWhereActionIsNeeded && (
        <FilterTag
          chipProps={{
            label: t('order:orders_where_action_needed'),
            icon: <TuneIcon />,
            onDelete: () => handleOnlyOrdersWhereActionIsNeededChange(false),
            sx: { height: 32, py: 1, m: 0 },
          }}
          variant="outlined"
          size="medium"
        />
      )}
      {selectedPartners.map((partner) => (
        <FilterTag
          chipProps={{
            label: partner.label,
            avatar: <Box component="img" src={partner.photo} sx={{ borderRadius: '50%', height: '24px', '&&': { marginRight: 0 } }} />,
            onDelete: () => removePartner(partner),
            sx: { height: 32, py: 1, m: 0 },
          }}
          variant="outlined"
          size="medium"
        />
      ))}
      {thereAreActiveFilters && (
        <ClearAllButton
          onClick={clearAll}
          sx={{
            height: 32,
          }}
        />
      )}
    </Box>
  );
}
