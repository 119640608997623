import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, SxProps, Theme } from '@mui/material';
import { SystemCssProperties } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export interface BackButtonProps {
  to: string;
  shouldCheckHistory?: boolean;
  compact?: boolean;
  sx?: SxProps;
  color?: SystemCssProperties<Theme>['backgroundColor'];
}

export default function BackButton({ to, compact, sx, color, shouldCheckHistory = true }: BackButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const handleBackClick = (): void => {
    if (!shouldCheckHistory || window.history.length <= 2) {
      navigate(to);
    } else {
      window.history.back();
    }
  };

  const backgroundColor = color || 'white';

  if (compact) {
    return (
      <IconButton
        onClick={handleBackClick}
        color="secondary"
        sx={{
          backgroundColor,
          ':hover': {
            backgroundColor,
          },
          ...sx,
        }}
      >
        <ArrowBackIcon sx={{ height: 18, width: 18, fontSize: 18 }} />
      </IconButton>
    );
  }

  return (
    <Button
      onClick={handleBackClick}
      startIcon={<ArrowBackIcon sx={{ height: 18, width: 18, fontSize: 18 }} />}
      sx={{
        backgroundColor,
        ':hover': {
          backgroundColor,
        },
        p: 1.5,
        lineHeight: '1rem',
        ...sx,
      }}
      color="secondary"
    >
      {t('common:back')}
    </Button>
  );
}
