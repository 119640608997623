import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAtom } from 'jotai';
import { AlgoliaHit } from 'instantsearch.js';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import { SupplierHit } from '../../search/search';
import { discoveryViewAtom } from '../state';
import i18n from '../../i18n';

export interface SlideProps {
  hit: AlgoliaHit;
}

export interface CarrouselHitsProps {
  slide: ({ hit }: SlideProps) => React.JSX.Element;
}

export default function CarrouselHits({ slide }: CarrouselHitsProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery']);
  // eslint-disable-next-line deprecation/deprecation
  const { hits, results } = useHits<AlgoliaHit<SupplierHit>>();
  const [discoveryView, setDiscoveryView] = useAtom(discoveryViewAtom);
  const format = new Intl.NumberFormat(i18n.resolvedLanguage);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!results?.hits.length && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 3 }}>
            <Typography variant="h5" sx={{ lineHeight: 'normal' }}>
              {t(discoveryView === 'PRODUCTS' ? 'discovery:N_supplier' : 'discovery:N_product', { count: results.nbHits, formattedCount: format.format(results.nbHits) })}
            </Typography>
            <Button
              disableRipple
              onClick={() => setDiscoveryView(discoveryView === 'PRODUCTS' ? 'SUPPLIERS' : 'PRODUCTS')}
              color="secondary"
              sx={{
                height: 18,
                textDecoration: 'underline',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
            >
              {t('common:display_all')}
            </Button>
          </Box>
          <Tabs
            sx={{
              backgroundColor: 'layout.main',
              borderRadius: 6,
              px: 2,
              '& .MuiTabs-flexContainer': {
                gap: 2,
              },
              gap: 1,
            }}
            variant="scrollable"
            TabScrollButtonProps={{
              slots: {
                StartScrollButtonIcon: ArrowBackIcon,
                EndScrollButtonIcon: ArrowForwardIcon,
              },
              sx: {
                '&.Mui-disabled': {
                  opacity: 1,
                  color: 'text.disabled',
                },
                display: {
                  xs: 'none',
                  md: 'inline-flex',
                },
                opacity: 1,
                backgroundColor: 'white',
                width: 40,
                height: 40,
                alignSelf: 'center',
                borderRadius: 10,
              },
            }}
          >
            {hits.map((hit) => {
              return (
                <Tab
                  key={hit.objectID}
                  component={slide}
                  hit={hit}
                />
              );
            })}
          </Tabs>
        </Box>
      )}
    </>
  );
}
