import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import useRefine from '../../hooks/useRefine';
import FilterUtils from './filter.utils';
import FilterTag, { FilterTagSize, FilterTagVariant } from './FilterTag';

export interface GeoLocationTagsProps {
  variant: FilterTagVariant;
  size: FilterTagSize;
}

export default function GeoLocationTags({ variant, size }: GeoLocationTagsProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { refine: configureRefine, uiState } = useRefine();
  const { refine: administrativeAreasRefine } = useRefinementList({ attribute: 'administrative_areas' });
  const administrativeAreas = useNormalizedCurrentRefinements('administrative_areas') as string[];

  const clearRadius = (): void => {
    configureRefine({
      aroundRadius: 'all',
    });
  };

  const administrativeAreaTags = useMemo(() => {
    return administrativeAreas.map((area) => (
      <FilterTag
        chipProps={{
          icon: <LocationOnOutlinedIcon />,
          onDelete: () => administrativeAreasRefine(area),
          label: FilterUtils.ExtractAdministrativeAreaInfo(area)[1],
        }}
        key={area}
        variant={variant}
        size={size}
      />
    ));
  }, [administrativeAreas]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Number.isInteger(uiState.aroundRadius) && (
        <FilterTag
          chipProps={{
            icon: <LocationOnOutlinedIcon />,
            onDelete: clearRadius,
            label: t('discovery:in_a_radius_of_N_km', { value: (uiState.aroundRadius as number) / 1000 }),
          }}
          variant={variant}
          size={size}
        />
      )}
      {administrativeAreaTags}
    </>
  );
}
