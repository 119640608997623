import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ContactInformationTemplateProps {
  mobilePhoneNumberComponent: React.JSX.Element;
  landlineNumberComponent: React.JSX.Element;
  emailComponent: React.JSX.Element;
}

export default function ContactInformationTemplate({ mobilePhoneNumberComponent, landlineNumberComponent, emailComponent }: ContactInformationTemplateProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        py: 2,
      }}
    >
      <Box>
        <Typography variant="h6">
          📱 {t('common:mobile_phone')}
        </Typography>
        {mobilePhoneNumberComponent}
      </Box>
      <Box>
        <Typography variant="h6">
          ☎️ {t('common:landline_phone')}
        </Typography>
        {landlineNumberComponent}
      </Box>
      <Box>
        <Typography variant="h6">
          ✉️ {t('common:email_address')}
        </Typography>
        {emailComponent}
      </Box>
    </Box>
  );
}
