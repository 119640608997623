import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Product_Family_Name_Enum } from 'kheops-graphql';
import { getProductFamilyNavigationItems } from '../discovery.utils';
import { currentFamilyAtom, discoveryViewAtom } from '../state';
import useNavigateByProductFamily from '../hooks/useNavigateByProductFamily';

export default function DiscoverySideNavigation(): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const currentFamily = useAtomValue(currentFamilyAtom);
  const discoveryView = useAtomValue(discoveryViewAtom);
  const { navigate } = useNavigateByProductFamily();

  const items = getProductFamilyNavigationItems(t);

  const handleNavigationClick = (newFamily: Product_Family_Name_Enum | 'ALL'): void => {
    navigate(newFamily, 'ALL');
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {t('discovery:categories')}
      </Typography>
      <Tabs
        onChange={(event, newFamily) => handleNavigationClick(newFamily)}
        value={currentFamily}
        orientation="vertical"
        sx={{
          '& .MuiTabs-indicator': {
            display: 'none',
          },
          '& .MuiTabs-flexContainer': {
            gap: 2,
          },
          '& .MuiTab-root': {
            color: 'text.primary',
            alignItems: 'start',
            p: 0,
            minHeight: 'unset',
            weight: 400,
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'text.primary',
          },
        }}
      >
        <Tab label={t(discoveryView === 'PRODUCTS' ? 'discovery:all_products' : 'discovery:all_suppliers')} value="ALL" disableRipple />
        {items.map(({ family, label }) => <Tab key={family} value={family} label={label} disableRipple />)}
      </Tabs>
    </Box>
  );
}
