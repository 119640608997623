import { Box, Card, Grid, Pagination, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { Business_Profile_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PageWrapper } from '../common/components/PageWrapper';
import { preferredProductFamilyAtom } from '../common/state/state';
import { ContractPreview } from '../hooks/useContracts';
import ContractRow from './ContractRow';
import ProductFilterToolbar from '../common/components/ProductFilterToolbar';
import EmptyPagePlaceholder from '../common/components/EmptyPagePlaceholder';
import { RoutePaths } from '../routes/AppRoutes';
import GuitaristButterflyImage from '../assets/images/guitarist_butterfly.png';
import { currentContextAtom, signedContractsAtom } from '../state';

interface ContractListHistoryState {
  page?: number;
  query?: string;
}

export default function ContractList(): React.JSX.Element {
  const PAGE_SIZE = 20;

  const { t } = useTranslation(['common', 'contracts']);
  const { realm } = useAtomValue(currentContextAtom);
  const contracts = useAtomValue(signedContractsAtom);
  const [preferredProductFamilies, setPreferredProductFamilies] = useAtom(preferredProductFamilyAtom);
  const [filteredContracts, setFilteredContracts] = useState<ContractPreview[]>([]);
  const [contractRows, setContractRows] = useState<ContractPreview[]>([]);
  const [page, setPage] = useState((window.history.state.uiState as ContractListHistoryState)?.page || 1);

  const updateHistoryState = (newState: ContractListHistoryState): void => {
    window.history.replaceState({
      ...window.history.state,
      uiState: {
        ...window.history.state.uiState,
        ...newState,
      },
    }, '');
  };

  const updatePage = (newPage: number): void => {
    window.scrollTo(0, 0);
    setPage(newPage);
    updateHistoryState({ page: newPage });
  };

  const handleSearch = useCallback((query: string, productFamilies: Product_Sub_Family_Name_Enum[], skipPaginationReset?: boolean): void => {
    const result = contracts.filter((contract) => {
      const matchQuery = contract.company.tradeName.toLowerCase().includes(query.toLowerCase());
      const matchProductFamilies = !productFamilies.length
       || contract.company.products?.some((product) => productFamilies.includes(product.sub_family as Product_Sub_Family_Name_Enum));

      return matchQuery && matchProductFamilies;
    });

    if (!skipPaginationReset) {
      updatePage(1);
    }

    setPreferredProductFamilies(productFamilies);
    setFilteredContracts(result);
    updateHistoryState({ query });
  }, []);

  const emptyPagePlaceholderProps = realm === Business_Profile_Enum.Buyer
    ? {
      title: t('contracts:no_contractualized_supplier'),
      description: t('contracts:no_contractualized_supplier_description'),
      imageSrc: GuitaristButterflyImage,
      buttonLabel: t('contracts:discover_new_suppliers'),
      linkDestination: generatePath(RoutePaths.DISCOVERY_SUPPLIERS),
    }
    : {
      title: t('contracts:no_contractualized_buyer'),
      description: t('contracts:no_contractualized_buyer_description'),
      imageSrc: GuitaristButterflyImage,
      buttonLabel: t('contracts:discover_new_buyers'),
      linkDestination: generatePath(RoutePaths.DISCOVERY_BUYERS),
    };

  useEffect(() => {
    const start = PAGE_SIZE * (page - 1);

    setContractRows(filteredContracts.slice(start, start + PAGE_SIZE));
  }, [page, filteredContracts]);

  useEffect(() => {
    handleSearch(
      (window.history.state.uiState as ContractListHistoryState)?.query || '',
      preferredProductFamilies,
      true,
    );
  }, []);

  const products = useMemo(() => {
    return contracts.flatMap((contract) => {
      if (!contract.company.products) {
        return [];
      }

      return contract.company.products.map((product) => product);
    });
  }, [contracts]);

  return (
    <PageWrapper>
      <Card
        sx={{
          p: {
            xs: 3,
            md: 4,
          },
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t(`contracts:${realm === Business_Profile_Enum.Buyer ? 'supplier_on_contract' : 'customer_on_contract'}`, { count: contracts.length })}
        </Typography>
        <ProductFilterToolbar
          shouldDisplayClearAllButton={realm === Business_Profile_Enum.Buyer}
          products={products}
          onChange={handleSearch}
          initialQuery={(window.history.state.uiState as ContractListHistoryState)?.query}
          initialProductFamilies={preferredProductFamilies}
        />
        {
          !contracts.length
            ? (
              <EmptyPagePlaceholder {...emptyPagePlaceholderProps} />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {
                  filteredContracts.length
                    ? (
                      <>
                        <Grid container>
                          {contractRows.map((contract) => <ContractRow key={contract.id} contract={contract} />)}
                        </Grid>
                        <Pagination
                          color="primary"
                          sx={{
                            '& .MuiPagination-ul': {
                              justifyContent: 'center',
                            },
                          }}
                          page={page}
                          count={Math.ceil(filteredContracts.length / PAGE_SIZE)}
                          onChange={(event, newPage) => updatePage(newPage)}
                        />
                      </>
                    )
                    : (
                      <Box sx={{ textAlign: 'center', pt: 5 }}>
                        <Typography variant="h6">
                          {t(`contracts:${realm === Business_Profile_Enum.Buyer ? 'no_supplier_matches_your_search' : 'no_customer_matches_your_search'}`)}
                        </Typography>
                      </Box>
                    )
                }
              </>
            )
          }
      </Card>
    </PageWrapper>
  );
}
