import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { Base_Unit_Type_Enum, Billing_Type_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { FormatPackagingUnitPrice, FormatPackagingVolumePrice, round } from 'kheops-utils';
import { useCatalogByIdLazyQuery } from '../../../queries/__generated__/catalogById.generated';
import { currentContextAtom } from '../../../state';
import { DraftCatalog, DraftCatalogPriceCollection } from '../state';
import { usePackagingsByCompanyQuery } from '../../../queries/__generated__/packagingsByCompany.generated';
import { companyPackagingsAtom } from '../../state';

export interface UseCatalogFormDefaultValuesParams {
  catalogId?: string;
  contractId?: string;
  copy?: boolean;
}

export default function useCatalogFormDefaultValues(params?: UseCatalogFormDefaultValuesParams): DraftCatalog {
  const { t } = useTranslation(['price']);
  const { companyId } = useAtomValue(currentContextAtom);
  const [catalogByIdQuery, { data: catalogData }] = useCatalogByIdLazyQuery();
  const { data: catalogPackagingsData } = usePackagingsByCompanyQuery({
    variables: {
      companyId,
      skuPackagingsToIgnore: [],
    },
  });
  const [catalogPackagings, setCatalogPackagings] = useAtom(companyPackagingsAtom);

  const defaultValues = useMemo((): DraftCatalog => {
    const values: DraftCatalog = {
      name: '',
      contracts: [],
      prices: {},
    };

    if (params?.catalogId && !catalogData?.catalog_by_pk) {
      return values;
    }

    if (params?.contractId) {
      values.contracts.push(params.contractId);
    }

    if (catalogData?.catalog_by_pk) {
      if (params?.copy) {
        values.name = t('price:name_copy', { name: catalogData.catalog_by_pk.name });
      } else {
        values.name = catalogData.catalog_by_pk.name;
        values.contracts = catalogData.catalog_by_pk.contracts.reduce((acc, { id, status }) => {
          if (status === Contract_Status_Enum.Signed) {
            acc.push(id);
          }

          return acc;
        }, [] as string[]);
      }
    }

    values.prices = catalogPackagings.reduce((acc, packaging) => {
      const catalogPackaging = catalogData?.catalog_by_pk?.catalog_packagings?.find(({ packaging_sku }) => packaging.sku === packaging_sku);
      const discount = catalogPackaging?.discount || 0;
      const price = packaging.base_unit.unit_type === Base_Unit_Type_Enum.Bulk || packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent
        ? FormatPackagingVolumePrice(packaging, packaging.base_unit).value
        : FormatPackagingUnitPrice(packaging);

      acc[packaging.sku] = {
        discount: round(discount * 100),
        price: round(price * (1 - discount)),
      };

      return acc;
    }, {} as DraftCatalogPriceCollection);

    return values;
  }, [catalogPackagings, catalogData]);

  useEffect((): void => {
    if (catalogPackagingsData?.packaging?.length) {
      setCatalogPackagings(catalogPackagingsData?.packaging);
    }
  }, [catalogPackagingsData]);

  useEffect(() => {
    if (params?.catalogId) {
      catalogByIdQuery({ variables: { id: params.catalogId }, fetchPolicy: 'network-only' });
    }
  }, []);

  return defaultValues;
}
