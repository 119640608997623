import { Document_Type_Enum } from 'kheops-graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import FileUtils from '../common/utils/file.utils';
import { useErrorSnackbar } from './useErrorSnackbar';

interface UseDownloadFileParams {
  documentType: Document_Type_Enum;
  downloadUrl: string;
  orderId: string;
}

export function useDocumentDownloadFile({ documentType, downloadUrl, orderId }: UseDownloadFileParams): () => void {
  const { t } = useTranslation(['order']);
  const handleFileError = useErrorSnackbar(t('order:document_download_failed'));

  return useCallback(() => {
    let documentTitle = '';

    // eslint-disable-next-line default-case
    switch (documentType) {
      case Document_Type_Enum.DeliveryNote:
        documentTitle = t('order:delivery_note_document_title');
        break;
      case Document_Type_Enum.Invoice:
      case Document_Type_Enum.OrderSummary:
        documentTitle = t('order:invoice_order_document_title');
        break;
      case Document_Type_Enum.PurchaseOrder:
        documentTitle = t('order:purchase_order_document_title');
        break;
    }

    FileUtils.DownloadFile(downloadUrl, `${documentTitle}_${orderId}`)
      .catch((error) => {
        handleFileError();
        Sentry.captureException(error);
      });
  }, [documentType, downloadUrl, orderId]);
}
