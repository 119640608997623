import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Documents from './documents/Documents';
import { documentsAtom, orderAtom } from '../state/state';
import Comments from './comments/Comments';
import BuyerInfoDialog, { BuyerView } from '../../company/buyer/dialog/BuyerInfoDialog';
import useOpenable from '../../hooks/useOpenable';

export interface OrderAdditionalInformationProps {
  compact?: boolean;
}

export default function OrderAdditionalInformation({ compact }: OrderAdditionalInformationProps): React.JSX.Element {
  const { t } = useTranslation(['comments', 'common', 'order']);
  const order = useAtomValue(orderAtom);
  const documents = useAtomValue(documentsAtom);
  const [view, setView] = useState<BuyerView>();
  const { open, close, isOpen } = useOpenable();

  const openBuyerDialog = (selectedView: BuyerView): void => {
    setView(selectedView);
    open();
  };

  return (
    <List
      sx={{
        py: 0,
        '& > *:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: 'divider',
        },
      }}
    >
      <Accordion
        disableGutters
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}
          sx={{
            [`& .${accordionSummaryClasses.content}`]: {
              alignItems: 'center',
              gap: 3,
            },
          }}
        >
          <FilePresentOutlinedIcon />
          {t('order:N_documents', { count: Object.keys(documents).length })}
        </AccordionSummary>
        <AccordionDetails>
          <Documents />
        </AccordionDetails>
      </Accordion>
      <ListItemButton onClick={() => openBuyerDialog('DELIVERY')}>
        <ListItemIcon sx={{ minWidth: 48 }}>
          <LocalShippingOutlinedIcon sx={{ color: 'text.primary' }} />
        </ListItemIcon>
        <ListItemText
          primary={t('common:delivery')}
          primaryTypographyProps={{
            sx: {
              lineHeight: '2rem',
            },
          }}
        />
        <ArrowRightOutlinedIcon />
      </ListItemButton>
      <ListItemButton onClick={() => openBuyerDialog('INVOICING')}>
        <ListItemIcon sx={{ minWidth: 48 }}>
          <ReceiptLongOutlinedIcon sx={{ color: 'text.primary' }} />
        </ListItemIcon>
        <ListItemText
          primary={t('common:billing')}
          primaryTypographyProps={{
            sx: {
              lineHeight: '2rem',
            },
          }}
        />
        <ArrowRightOutlinedIcon />
      </ListItemButton>
      <BuyerInfoDialog
        close={close}
        isOpen={isOpen}
        companyId={order.contract.buyingCompanyId}
        openView={view}
      />
      {!compact && <Comments />}
    </List>
  );
}
