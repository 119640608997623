import { Maybe, User } from 'kheops-graphql';
import { RawPhoto } from '../common/common';
import CompanyContact from './CompanyContact';

export type Contact = Pick<User, 'id' | 'first_name' | 'last_name' | 'auth_provider_avatar_url'> & { default_avatar?: Maybe<RawPhoto> };

export interface CompanyContactListProps {
  contacts: Contact[];
  displayContactInfo?: boolean;
}

export default function CompanyContactList({ contacts, displayContactInfo }: CompanyContactListProps): React.JSX.Element {
  return (
    <>
      {contacts.map((contact) => (
        <CompanyContact
          contact={contact}
          displayContactInfo={displayContactInfo}
          key={contact.id}
        />
      ))}
    </>
  );
}
