import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Divider, ListItemIcon, ListItemText, MenuItem, SxProps } from '@mui/material';
import axios, { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Brand_Enum } from 'kheops-graphql';
import { useAtomValue, useSetAtom } from 'jotai';
import { useApolloClient } from '@apollo/client';
import { format } from 'date-fns';
import KheopsMenu from '../../common/components/KheopsMenu';
import { OrderByReferenceIdDocument } from '../../queries/__generated__/orderByReferenceId.generated';
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar';
import { currentContextAtom } from '../../state';
import { commonSnackbarPropsAtom } from '../../common/state/state';

type ExportType = 'Basic' | 'PDF' | 'Ulis' | 'Abaco' | 'UlisReception' | 'Meti';

interface ExportParams {
  exportType: ExportType;
  extension: string;
  mimeType: string;
  label: string;
}

export interface OrderExportButtonProps {
  orderReferenceId: string;
  orderFriendlyId: string;
  orderId: string;
  children?: React.ReactNode;
  sx?: SxProps;
  buttonSize?: LoadingButtonProps['size'];
}

const defaultExportOptions: ExportParams[] = [
  {
    exportType: 'Basic',
    extension: 'xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    label: 'export_as_xslx',
  },
  {
    exportType: 'PDF',
    extension: 'pdf',
    mimeType: 'application/pdf',
    label: 'export_as_pdf',
  },
];

function OrderExportButton({ orderReferenceId, orderId, orderFriendlyId, children, sx, buttonSize }: OrderExportButtonProps): React.JSX.Element {
  const { t } = useTranslation(['order', 'error', 'common']);
  const { companyBrand } = useAtomValue(currentContextAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [exportLoading, setExportLoading] = useState(false);
  const displayError = useErrorSnackbar(t('error:order_has_already_been_updated'));
  const handleExportButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const apolloClient = useApolloClient();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const closeExportMenu = (): void => {
    setAnchorEl(null);
  };

  const handleExport = async ({ exportType, extension, mimeType }: ExportParams): Promise<void> => {
    closeExportMenu();

    setExportLoading(true);

    try {
      const downloadDate = format(new Date(), 'yyyy-MM-dd-HH:mm');
      const fileName = `${t('order')}_${orderFriendlyId}-${downloadDate}.${extension}`;
      const res = await axios.get(`/export/orders/${orderReferenceId}/${orderId}?type=${exportType}`, { responseType: 'blob' });

      if (exportType === 'Meti') {
        const metiFileNamePrefix = 'T-';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fileHandle: FileSystemFileHandle = await (window as any).showSaveFilePicker({ id: exportType, suggestedName: metiFileNamePrefix + fileName });
        const writer = await fileHandle.createWritable();

        await writer.write(res.data);

        await writer.close();
        setCommonSnackbarProps({
          label: t('common:file_download_successful'),
          snackbarProps: {
            open: true,
          },
        });
      } else {
        fileDownload(res.data, fileName, mimeType);
      }
    } catch (e) {
      const status = (e as AxiosError).response?.status;

      if (status === 400) {
        displayError();
        await apolloClient.refetchQueries({
          include: [OrderByReferenceIdDocument],
        });
      }
    }

    setExportLoading(false);
  };

  const exportOptions = useMemo(() => {
    const specialExportOptions: ExportParams[] = [];

    switch (companyBrand) {
      case Brand_Enum.ELeclerc:
        specialExportOptions.push(
          {
            exportType: 'Abaco',
            extension: 'csv',
            mimeType: 'text/csv',
            label: 'export_as_abaco',
          },
        );
        break;
      case Brand_Enum.CarrefourCity:
        specialExportOptions.push(
          {
            exportType: 'Meti',
            extension: 'txt',
            mimeType: 'text/plain',
            label: 'export_as_meti',
          },
        );
        break;
      case Brand_Enum.SuperU:
      case Brand_Enum.UExpress:
      case Brand_Enum.HyperU:
        specialExportOptions.push(
          {
            exportType: 'Ulis',
            extension: 'xlsx',
            mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            label: 'export_as_ulis',
          },
          {
            exportType: 'UlisReception',
            extension: 'xlsx',
            mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            label: 'export_as_ulis_reception',
          },
        );
        break;
      default:
    }

    return defaultExportOptions.concat(specialExportOptions);
  }, []);

  return (
    <>
      <LoadingButton
        loading={exportLoading}
        loadingPosition="start"
        variant="outlined"
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadOutlinedIcon sx={{ height: 18, width: 18 }} />}
        sx={sx}
        color="primary"
        size={buttonSize || 'medium'}
      >
        {children}
      </LoadingButton>
      <KheopsMenu
        onClose={closeExportMenu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          exportOptions.map((option, index) => (
            <>
              <MenuItem key={option.exportType} onClick={() => handleExport(option)}>
                <ListItemIcon>
                  <FileDownloadOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                  {t(option.label)}
                </ListItemText>
              </MenuItem>
              {index !== exportOptions.length - 1 ? <Divider /> : undefined}
            </>
          ))
        }
      </KheopsMenu>
    </>
  );
}

export default React.memo(OrderExportButton);
