import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { format } from 'date-fns';
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar';
import { currentContextAtom } from '../../state';
import { CompanyInfo } from './CompanyInfoCard';

export interface ExportProductsButtonProps {
  supplierCompany: CompanyInfo;
}

export default function ExportProductsButton({ supplierCompany }: ExportProductsButtonProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'error', 'common']);
  const { companyId } = useAtomValue(currentContextAtom);
  const [exportLoading, setExportLoading] = useState(false);
  const displayError = useErrorSnackbar(t('error:an_error_occured'));

  const handleExport = async (): Promise<void> => {
    setExportLoading(true);

    try {
      const downloadDate = format(new Date(), 'yyyy-MM-dd-HH:mm');
      const fileName = `{{${supplierCompany.tradeName}}}(${supplierCompany.legalName})-${downloadDate}.xlsx`;
      const res = await axios.get(`/export/company/${supplierCompany.id}/products?&buyerCompanyId=${companyId}`, { responseType: 'blob' });

      fileDownload(res.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } catch (e) {
      displayError();
    }

    setExportLoading(false);
  };

  return (
    <LoadingButton
      loading={exportLoading}
      loadingPosition="start"
      variant="outlined"
      onClick={handleExport}
      startIcon={<FileDownloadOutlinedIcon sx={{ height: 18, width: 18 }} />}
      color="primary"
    >
      {t('products:export_products')}
    </LoadingButton>
  );
}
