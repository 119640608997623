import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum, Company, Contract_History_Bool_Exp, Order, Order_Status_Enum, Uuid_Comparison_Exp } from 'kheops-graphql';
import { DisplayPhoto } from '../common/common';
import { OrdersCountByCategoryMap } from '../orders/list/OrderList';
import { useGetOrdersQuery } from '../queries/__generated__/getOrders.generated';
import { currentContextAtom } from '../state';

export interface OrderPreview
  extends Pick<Order, 'id' | 'creator_id' | 'created_at' | 'status' | 'reference_id' | 'suggestor_id' | 'friendly_id'> {
  contract: {
    id: string;
    company: Pick<Company, 'id' | 'tradeName' | 'business_profile' | 'brand'>
      & {
        photos: DisplayPhoto[]
      };
  };
  total_price_excl_tax: number;
  total_price_incl_tax: number;
}

export interface OrderContract {
  id: string;
  company: Pick<Company, 'id' | 'tradeName' | 'brand'>
    & {
      photos: DisplayPhoto[]
    };
}

interface UseOrdersResult {
  orders: OrderPreview[];
  ordersContracts: OrderContract[];
  countMap: OrdersCountByCategoryMap;
  loading: boolean;
  called: boolean;
}

export interface OrdersFilters {
  contractFilter?: Contract_History_Bool_Exp;
  creatorFilter?: Uuid_Comparison_Exp;
  statusesToGet?: Order_Status_Enum[];
  aggregateAllCategoryStatuses: Order_Status_Enum[];
  aggregateValidationCategoryStatuses: Order_Status_Enum[];
  aggregateDeliveryCategoryStatuses: Order_Status_Enum[];
  aggregatePaymentCategoryStatuses: Order_Status_Enum[];
  aggregateCompletedCategoryStatuses: Order_Status_Enum[];
}

export default function useOrders(
  offset: number,
  limit: number,
  queryFilters: OrdersFilters,
): UseOrdersResult {
  const context = useAtomValue(currentContextAtom);
  const [countMap, setCountMap] = useState<OrdersCountByCategoryMap>({
    ALL: 0,
    VALIDATION: 0,
    DELIVERY: 0,
    PAYMENT: 0,
    COMPLETED: 0,
  });

  const baseContractFilterExp = {
    buyingCompanyId: context.realm === Business_Profile_Enum.Buyer ? { _eq: context.companyId } : undefined,
    supplyingCompanyId: context.realm === Business_Profile_Enum.Supplier ? { _eq: context.companyId } : undefined,
  };
  const { contractFilter, ...otherQueryFilters } = queryFilters;

  const { data, loading, called } = useGetOrdersQuery(
    {
      fetchPolicy: 'cache-and-network',
      skip: !queryFilters.statusesToGet?.length,
      variables: {
        offset,
        limit,
        baseContractFilterExp,
        contractFilterExp: {
          ...baseContractFilterExp,
          ...contractFilter,
        },
        ...otherQueryFilters,
      },
    },
  );

  useEffect(() => {
    if (data) {
      setCountMap({
        ALL: data.all_orders_count.aggregate?.count || 0,
        VALIDATION: data.orders_in_validation_count.aggregate?.count || 0,
        PAYMENT: data.orders_in_payment_count.aggregate?.count || 0,
        DELIVERY: data.orders_in_delivery_count.aggregate?.count || 0,
        COMPLETED: data.orders_completed_count.aggregate?.count || 0,
      });
    }
  }, [data]);

  const results = useMemo(() => {
    return {
      orders: data?.order.map((order) => (
        {
          ...order,
          total_price_excl_tax: order.total_price_excl_tax || 0,
          total_price_incl_tax: order.total_price_incl_tax || 0,
          contract: {
            id: order.contract.id,
            company: context.realm === Business_Profile_Enum.Buyer ? order.contract.supplying_company : order.contract.buying_company,
          },
        }
      )) || [],
      ordersContracts: data?.contracts_data.map(({ contract }) => ({
        id: contract.id,
        company: context.realm === Business_Profile_Enum.Buyer ? contract.supplying_company : contract.buying_company,
      })) || [],
      loading,
      called,
      countMap,
    };
  }, [data, loading, called, countMap]);

  return results;
}
