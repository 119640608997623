import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import { PackagingFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import FilterTag, { FilterTagSize, FilterTagVariant } from './FilterTag';

interface ProductFamilyTagsProps {
  attribute: string;
  variant: FilterTagVariant;
  size: FilterTagSize;
}

export default function ProductFamilyTags({ attribute, variant, size }: ProductFamilyTagsProps): React.JSX.Element {
  const { t } = useTranslation(['product-family']);
  const { refine } = useRefinementList({ attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute) as string[];

  return (
    <>
      {currentRefinements.map((refinement) => (
        <FilterTag
          key={refinement}
          variant={variant}
          size={size}
          chipProps={{
            icon: <ShoppingBasketOutlinedIcon />,
            label: t(`product-family:${PackagingFamilyTypeIntl[refinement as Product_Sub_Family_Name_Enum]}`),
            onDelete: () => refine(refinement),
          }}
        />
      ))}
    </>
  );
}
