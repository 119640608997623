import fileDownload from 'js-file-download';
import { Brand_Enum, Maybe, Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import BakeryImage from '../../assets/images/bakery.png';
import BeerCiderImage from '../../assets/images/beer_cider.png';
import ClothingTextileImage from '../../assets/images/clothing_textile.png';
import DairyImage from '../../assets/images/dairy.png';
import DeliImage from '../../assets/images/deli.png';
import FishSeafoodImage from '../../assets/images/fish_seafood.png';
import FloralImage from '../../assets/images/floral.png';
import FruitsImage from '../../assets/images/fruits.png';
import HouseholdCleaningImage from '../../assets/images/household_cleaning.png';
import HygieneBeautyImage from '../../assets/images/hygiene_beauty.png';
import NonAlcoholicBeveragesImage from '../../assets/images/non_alcoholic_beverages.png';
import RawMeatImage from '../../assets/images/raw_meat.png';
import SaltyGroceryImage from '../../assets/images/salty_grocery.png';
import SpiritsImage from '../../assets/images/spirits.png';
import SweetGroceryImage from '../../assets/images/sweet_grocery.png';
import VegetablesImage from '../../assets/images/vegetables.png';
import VegetarianVeganImage from '../../assets/images/vegetarian_vegan.png';
import FrozenFoodImage from '../../assets/images/frozen_food.png';
import PetsImage from '../../assets/images/pets.png';
import WineChampagneSparklingImage from '../../assets/images/wine_champagne_sparkling.png';
import GamesToysImage from '../../assets/images/games_toys.png';
import BuyerPlaceholderImage from '../../assets/placeholders/buyer-placeholder.png';
import SupplierPlaceholderImage from '../../assets/placeholders/supplier-placeholder.png';
import NonFoodProductsImage from '../../assets/images/non_food_products.png';
import BeveragesImage from '../../assets/images/beverages.png';
import FreshFoodImage from '../../assets/images/fresh_food.png';
import GroceryImage from '../../assets/images/grocery.png';

export type PhotoSizeFit = 'cover' | 'contain' | 'fill' | 'inside' | 'outside';

// Type from sharp.js
export interface PhotoSize {
  height?: number;
  width?: number;
  fit?: PhotoSizeFit;
  background?: {
    r: number,
    g: number,
    b: number,
    alpha: number,
  };
}

export type PhotoFormat = 'webp' | 'png' | 'jpeg';

export interface CDNFileParameters {
  bucket: string;
  key: string;
  edits?: {
    resize: PhotoSize;
    toFormat?: PhotoFormat;
  };
}

export interface FileParameters {
  domain?: Maybe<string>;
  path: string;
}

export interface BuildImageParameters {
  type: string;
  photo?: FileParameters | null;
  size?: PhotoSize;
}

export const packagingLogoMap: {[key in Product_Sub_Family_Name_Enum]: string} = {
  BAKERY: BakeryImage,
  BEER_CIDER: BeerCiderImage,
  CLOTHING_TEXTILE: ClothingTextileImage,
  DAIRY: DairyImage,
  DELI: DeliImage,
  FISH_SEAFOOD: FishSeafoodImage,
  FLORAL: FloralImage,
  FRUITS: FruitsImage,
  NON_ALCOHOLIC_BEVERAGES: NonAlcoholicBeveragesImage,
  RAW_MEAT: RawMeatImage,
  SALTY_GROCERY: SaltyGroceryImage,
  SPIRITS: SpiritsImage,
  SWEET_GROCERY: SweetGroceryImage,
  VEGETABLES: VegetablesImage,
  VEGETARIAN_VEGAN: VegetarianVeganImage,
  WINE_CHAMPAGNE_SPARKLING: WineChampagneSparklingImage,
  HYGIENE_BEAUTY: HygieneBeautyImage,
  HOUSEHOLD_CLEANING: HouseholdCleaningImage,
  FROZEN_FOOD: FrozenFoodImage,
  PETS: PetsImage,
  GAMES_TOYS: GamesToysImage,
};

export const productFamilyLogoMap: {[key in Product_Family_Name_Enum]: string} = {
  BEVERAGES: BeveragesImage,
  FRESH_FOOD: FreshFoodImage,
  GROCERY: GroceryImage,
  NON_FOOD_PRODUCTS: NonFoodProductsImage,
};

export default class FileUtils {
  static GetFileParametersFromUrl(url: string): FileParameters {
    const photoUrl = new URL(url);

    return {
      domain: photoUrl.hostname.split('.')[0],
      path: photoUrl.pathname.substring(1),
    };
  }

  static BuildBrandLogoURL(brand: Brand_Enum): string {
    return `${import.meta.env.REACT_APP_AWS_IMAGE_BUCKET}brand/${brand}.svg`;
  }

  static BuildImageURL(
    { domain, path }: FileParameters,
    size?: PhotoSize,
    toFormat?: PhotoFormat,
  ): string {
    if (!domain) {
      return path;
    }

    const params: CDNFileParameters = {
      bucket: domain,
      key: path,
    };

    if (size) {
      params.edits = {
        resize: {
          ...size,
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 0,
          },
        },
        toFormat,
      };
    }

    return `${import.meta.env.REACT_APP_IMAGE_CDN_URL}/${window.btoa(JSON.stringify(params))}`;
  }

  static BuildPackagingImageUrl({ photo, type, size }: BuildImageParameters): string {
    if (!photo) {
      return packagingLogoMap[type as Product_Sub_Family_Name_Enum];
    }

    return FileUtils.BuildImageURL(photo, size, 'webp');
  }

  static BuildCompanyImageUrl({ photo, type, size }: BuildImageParameters): string {
    if (!photo) {
      return FileUtils.GetBusinessPlaceHolderImage(type);
    }

    return FileUtils.BuildImageURL(photo, size);
  }

  static GetBusinessPlaceHolderImage(businessProfile: string): string {
    if (businessProfile === 'BUYER') {
      return BuyerPlaceholderImage;
    }
    return SupplierPlaceholderImage;
  }

  static NormalizeFileName(fileName: string): string {
    return fileName.replace(/(.*)\.(.*)/, (_, name: string, extension: string) => `${name}.${extension.toLowerCase()}`);
  }

  static async DownloadFile(url: string, fileName: string): Promise<void> {
    const file = await fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error(`Download file at ${url} went wrong`);
      }

      return response.blob();
    });

    fileDownload(file, fileName, file.type);
  }
}
