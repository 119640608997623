import { useEffect, useMemo } from 'react';
import { createStore, useAtom, useAtomValue, useStore } from 'jotai';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { contractsAtom, currentContextAtom, userAtom } from '../state';
import { currentBuyingCompanyIdAtom } from '../basket/state/state';

export default function useBaseStore(): ReturnType<typeof useStore> {
  const context = useAtomValue(currentContextAtom);
  const user = useAtomValue(userAtom);
  const contracts = useAtomValue(contractsAtom);
  const currentBuyingCompanyId = useAtomValue(currentBuyingCompanyIdAtom);
  const [commonSnackbarProps, setCommonSnackbarProps] = useAtom(commonSnackbarPropsAtom);
  const baseStore = useMemo(() => {
    const store = createStore();

    store.set(commonSnackbarPropsAtom, commonSnackbarProps);
    store.set(currentContextAtom, context);
    store.set(userAtom, user);
    store.set(contractsAtom, contracts);
    store.set(currentBuyingCompanyIdAtom, currentBuyingCompanyId);
    store.sub(commonSnackbarPropsAtom, () => {
      setCommonSnackbarProps(store.get(commonSnackbarPropsAtom));
    });

    return store;
  }, []);

  useEffect(() => {
    baseStore.set(currentContextAtom, context);
    baseStore.set(userAtom, user);
    baseStore.set(currentBuyingCompanyIdAtom, currentBuyingCompanyId);
  }, [user, context, currentBuyingCompanyId]);

  useEffect(() => {
    baseStore.set(contractsAtom, contracts);
  }, [contracts]);

  useEffect(() => {
    baseStore.set(commonSnackbarPropsAtom, commonSnackbarProps);
  }, [commonSnackbarProps]);

  return baseStore;
}
