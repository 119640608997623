import { useCallback, useState } from 'react';
import { useSendbirdStateContext, sendbirdSelectors } from '@sendbird/uikit-react';
import { useJoinGroupChannelMutation } from '../../mutations/__generated__/joinGroupChannel.generated';

interface JoinDefaultChannelParams {
  channelUrl: string;
  contractId: string;
}

interface JoinedDefaultChannelResult {
  loading: boolean;
  joined: boolean;
}

type JoinDefaultChannelFunction = ({ channelUrl, contractId }: JoinDefaultChannelParams) => Promise<boolean>;
type UseJoinDefaultChannelResponse = [JoinDefaultChannelFunction, JoinedDefaultChannelResult];

export default function useJoinDefaultChannel(): UseJoinDefaultChannelResponse {
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const [joinGroupChannel, { loading }] = useJoinGroupChannelMutation();
  const [joined, setJoined] = useState(false);

  const joinDefaultChannel = useCallback(async ({ channelUrl, contractId }: JoinDefaultChannelParams): Promise<boolean> => {
    setJoined(false);

    try {
      await sdkInstance?.groupChannel.getChannel(channelUrl);
    } catch (error) {
      const { data } = await joinGroupChannel({ variables: { contractId } });
      const hasJoined = !!data?.joinGroupChannel?.channelUrl;

      setJoined(hasJoined);

      return hasJoined;
    }

    setJoined(true);

    return true;
  }, [sdkInstance]);

  return [joinDefaultChannel, { joined, loading }];
}
