import { useEffect, useState } from 'react';

export function useIntercomReady(): boolean {
  const [analyticsReady, setAnalyticsReady] = useState(false);
  const [intercomClientReady, setIntercomClientReady] = useState(false);

  useEffect(() => {
    if (!analyticsReady) {
      window.analytics.ready(() => {
        setAnalyticsReady(true);
      });
    }
  }, [analyticsReady]);

  useEffect(() => {
    if (!intercomClientReady && window.Intercom?.booted && analyticsReady) {
      setIntercomClientReady(true);
    }
  }, [intercomClientReady, window.Intercom?.booted, analyticsReady]);

  return intercomClientReady;
}
