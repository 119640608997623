import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';
import { BasketOrder } from '../hooks/useBasketOrders';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { RoutePaths } from '../routes/AppRoutes';
import ItemSummary, { ItemSummarySizeOption } from './BasketItemSummary';
import BulkRemoveFromBasketButton from './BulkRemoveFromBasketButton';
import SizedImage from '../common/components/SizedImage';

interface BasketOrderSummaryDrawerProps {
  order: BasketOrder;
  editable?: boolean;
}

export default function BasketOrderSummaryDrawer(
  { order, editable }: BasketOrderSummaryDrawerProps,
): React.JSX.Element {
  const { items, companyId, name, photoUrl, minimumOrderValue, totalPrice } = order;
  const { t } = useTranslation(['basket', 'common']);
  const currencyFormat = useCurrencyFormat();
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} defaultExpanded {...props} />
  ))(({ theme }) => ({
    '&:before': {
      display: 'none',
    },
    [`& .${accordionDetailsClasses.root}`]: {
      padding: 0,
      paddingRight: theme.spacing(editable ? 1 : 0),
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.875rem' }} />} {...props} />
  ))(() => ({
    [`&.${accordionSummaryClasses.root}`]: {
      padding: 0,
      alignItems: 'start',
    },
    [`& .${accordionSummaryClasses.content}`]: {
      minWidth: 0,
      marginTop: 16,
      marginBottom: 16,
      alignItems: 'start',
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
      width: 44,
      marginTop: 20,
      justifyContent: 'center',
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
      transform: 'rotate(90deg)',
    },
  }));

  return (
    <Accordion>
      <AccordionSummary sx={{ ml: 1 }}>
        <SizedImage
          src={photoUrl}
          alt={t('common:company')}
          width={56}
          height={56}
          borderRadius={3}
        />
        <Box sx={{ marginX: 1 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              flex: 1,
              mb: 0.5,
            }}
          >
            <Box
              component={Link}
              to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId })}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {name}
            </Box>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: 1,
            }}
          >
            <Typography
              sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}
              variant="subtitle1"
            >
              {t('common:total')} : {currencyFormat.format(totalPrice)}
            </Typography>
            <MinimumOrderValueChip sx={{ width: 'fit-content' }} minimumOrderValue={minimumOrderValue} totalPrice={totalPrice} enableProgress />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {items.map((item) => {
          return (
            <ItemSummary
              item={item}
              key={item.packaging.id}
              editable={editable}
              size={ItemSummarySizeOption.SMALL}
            />
          );
        })}
      </AccordionDetails>
      <BulkRemoveFromBasketButton order={order} />
    </Accordion>
  );
}
