import { AppBar, Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KheopsLogoLong from '../../../assets/logo/kheops-logo-long.svg?react';

export default function OfflinePage(): React.JSX.Element {
  const { t } = useTranslation('offline');

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          height: '64px',
          pl: 3,
          bgcolor: 'common.white',
        }}
      >
        <KheopsLogoLong width="132px" height="auto" />
      </AppBar>
      <Container maxWidth="xl">
        <Box
          sx={{
            height: 'calc(100vh - 64px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography component="p" variant="h6">
            {t('no_internet_connection')}
          </Typography>
          <Typography component="p">
            {t('it_seems_your_internet_is_down')}
          </Typography>
          <Typography component="p">
            {t('please_check_your_connection_then_try_again')}
          </Typography>
        </Box>
      </Container>
    </>
  );
}
