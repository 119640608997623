import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../routes/AppRoutes';
import useAuth from './useAuth/useAuth';

interface RedirectIfAuthProps {
  children: React.ReactElement;
}

export function useRedirectIfAuth(): React.JSXElementConstructor<RedirectIfAuthProps> {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate(RoutePaths.ROOT);
    }
  }, [isAuthenticated, isLoading]);

  return useCallback(({ children }: RedirectIfAuthProps) => {
    return children;
  }, []);
}
