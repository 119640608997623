import { useState } from 'react';
import axios from 'axios';
import { InvoiceParseData } from './types';

interface UseProcessInvoiceProps {
  orderReferenceId: string;
}

interface UseProcessInvoiceReturn {
  processInvoice: (file: File) => Promise<void>;
  reset: () => void;
  processedInvoice: InvoiceParseData | undefined;
  loading: boolean;
}

export function useProcessInvoice({ orderReferenceId }: UseProcessInvoiceProps): UseProcessInvoiceReturn {
  const [processedInvoice, setProcessedInvoice] = useState<InvoiceParseData>();
  const [loading, setIsLoading] = useState(false);

  const processInvoice = async (file: File): Promise<void> => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('file', file);
    formData.append('orderReferenceId', orderReferenceId);

    try {
      const response = await axios.post<InvoiceParseData>('/orders/processUserInvoice', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      setProcessedInvoice(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = (): void => {
    setProcessedInvoice(undefined);
  };

  return {
    reset,
    loading,
    processInvoice,
    processedInvoice,
  };
}
