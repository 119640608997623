import { Atom, atom } from 'jotai';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { CompanyRoleAssignment, Context, User } from './common/models/user.models';
import { ContractPreview } from './hooks/useContracts';

export enum LocalStorageKeys {
  DISABLE_CATALOG_PRICE_FIELD_TOOLTIP = 'DISABLE_CATALOG_PRICE_FIELD_TOOLTIP',
}

export const SNACKBAR_DEFAULT_HIDE_DURATION = 6000;

export const userAtom = atom<User>({
  id: '',
  email: '',
  sendbirdAccessToken: '',
  isStaff: false,
  first_name: '',
  last_name: '',
  avatars: {},
});
export const currentContextAtom = atom<Context>({
  realm: Business_Profile_Enum.Buyer,
  roleAssignmentId: '',
  companyId: '',
  companyName: '',
  companyLegalName: '',
  companyAddress: {
    administrativeAreaLevel2: '',
    latitude: 0,
    longitude: 0,
    formattedAddress: '',
    locality: '',
    postalCode: '',
    countryCode: '',
  },
  companyRoleAssignments: [],
});
export const availableContextsAtom = atom<Context[]>([]);

export const contractsAtom = atom<ContractPreview[]>([]);
export const signedContractsAtom = atom<ContractPreview[]>(
  (get) => get(contractsAtom).filter((contract) => contract.status === Contract_Status_Enum.Signed),
);
export const contractAtom = (buyingCompanyId: string, supplyingCompanyId: string): Atom<ContractPreview | undefined> => {
  return atom(
    (get) =>
      get(contractsAtom).find((contract) => {
        return contract.buyingCompanyId === buyingCompanyId && contract.supplyingCompanyId === supplyingCompanyId;
      }),
  );
};

export const contractByReferenceIdAtom = (referenceId: string): Atom<ContractPreview | undefined> => {
  return atom(
    (get) => get(contractsAtom).find(({ reference_id }) => reference_id === referenceId),
  );
};

export const deliveryContactsAtom = atom<CompanyRoleAssignment[]>(
  (get) => get(currentContextAtom).companyRoleAssignments.filter(({ is_delivery_contact }) => is_delivery_contact),
);

export const invoicingContactsAtom = atom<CompanyRoleAssignment[]>(
  (get) => get(currentContextAtom).companyRoleAssignments.filter(({ is_invoicing_contact }) => is_invoicing_contact),
);
