import { useMemo } from 'react';
import { Additional_Address, Maybe } from 'kheops-graphql';
import CountrySetFr from '../../../../assets/world.json';
import { Address } from '../Address';
import { CompanyAddress } from '../../../companyInfo/CompanyInfoCard';

interface ResolvedAdditionalAddressParams {
  companyName: string;
  companyAddress: CompanyAddress;
  additionalAddress?: Maybe<Additional_Address>;
}

export default function useResolvedAdditionalAddress({ companyName, companyAddress, additionalAddress }: ResolvedAdditionalAddressParams): Address {
  return useMemo(() => ({
    id: additionalAddress?.id,
    name: additionalAddress?.name || companyName,
    address_1: additionalAddress?.address_1 || companyAddress.formattedAddress,
    address_2: additionalAddress?.address_2,
    country: additionalAddress?.country || CountrySetFr.find(({ alpha2 }) => alpha2.toUpperCase() === companyAddress.countryCode)?.name || '',
    postal_code: additionalAddress?.postal_code || companyAddress.postalCode!,
    city: additionalAddress?.city || companyAddress.locality!,
  }), [additionalAddress]);
}
