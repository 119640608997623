import { Box, Button, SxProps, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ExpandableTextProps {
  text: string;
  variant?: 'body1' | 'body2';
  isDangerousText?: boolean;
  sx?: SxProps;
  lineClampThreshold: number;
}

/**
 * Displays text with body2 variant, clamps text when it takes more lines than the given threshold.
 */
export default function ExpandableText({ text, lineClampThreshold, isDangerousText, sx, variant = 'body1' }: ExpandableTextProps): React.JSX.Element {
  const [shouldClampText, setShouldClampText] = useState(false);
  const { t } = useTranslation('common');
  const [isTextClamped, setIsTextClamped] = useState(false);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current) {
      // Count number of paragraphs tags, if count of tag is superior to threshold, then clamp.
      if (isDangerousText && ref.current.getElementsByTagName('p').length - 1 > lineClampThreshold) {
        setShouldClampText(true);
        setIsTextClamped(true);
        return;
      }

      // Count number of line breaks, if count is superior to threshold, then clamp.
      const lineBreaksCount = text.split(/\r\n|\r|\n/).length;

      if (lineBreaksCount > lineClampThreshold) {
        setShouldClampText(true);
        setIsTextClamped(true);
        return;
      }

      /*
        Here we try to estimate the number of lines that our text will occupy, the value 7 is chosen slightly
        above the estimated average width a character with Ambit typo and body2 variant (6.5px), because the cost
        of the error of a false shouldClamp is less than a true shouldClamp UX wise.
      */
      if ((text.length * 7) / ref.current.offsetWidth > lineClampThreshold) {
        setShouldClampText(true);
        setIsTextClamped(true);
        return;
      }

      setShouldClampText(false);
    }
  }, [ref, text]);

  return (
    <Box sx={sx} ref={ref}>
      <Typography
        variant={variant}
        sx={{
          whiteSpace: 'break-spaces',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          WebkitLineClamp: isTextClamped ? lineClampThreshold : undefined,
          '& p:first-of-type': {
            mt: 0,
          },
          '& p:last-of-type': {
            mb: 0,
          },
        }}
        dangerouslySetInnerHTML={isDangerousText ? { __html: DOMPurify.sanitize(text) } : undefined}
      >
        {isDangerousText ? undefined : text}
      </Typography>
      { shouldClampText && (
      <Button
        onClick={() => setIsTextClamped(!isTextClamped)}
        color="secondary"
        sx={{
          p: 0,
          fontSize: 14,
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
        }}
        size="small"
      >{isTextClamped ? `+ ${t('common:see_more')}` : `- ${t('common:see_less')}`}
      </Button>
      )}
    </Box>
  );
}
