"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productFamilyMap = void 0;
exports.GetProductFamilyMapFromSubFamilies = GetProductFamilyMapFromSubFamilies;
const kheops_graphql_1 = require("kheops-graphql");
exports.productFamilyMap = {
    [kheops_graphql_1.Product_Family_Name_Enum.Beverages]: [
        kheops_graphql_1.Product_Sub_Family_Name_Enum.BeerCider,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.NonAlcoholicBeverages,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Spirits,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.WineChampagneSparkling,
    ],
    [kheops_graphql_1.Product_Family_Name_Enum.FreshFood]: [
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Bakery,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Dairy,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Deli,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.FishSeafood,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Fruits,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.FrozenFood,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.RawMeat,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Vegetables,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.VegetarianVegan,
    ],
    [kheops_graphql_1.Product_Family_Name_Enum.Grocery]: [
        kheops_graphql_1.Product_Sub_Family_Name_Enum.SaltyGrocery,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.SweetGrocery,
    ],
    [kheops_graphql_1.Product_Family_Name_Enum.NonFoodProducts]: [
        kheops_graphql_1.Product_Sub_Family_Name_Enum.ClothingTextile,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Floral,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.HouseholdCleaning,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.GamesToys,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.HygieneBeauty,
        kheops_graphql_1.Product_Sub_Family_Name_Enum.Pets,
    ],
};
function GetProductFamilyMapFromSubFamilies(productSubFamilies) {
    return productSubFamilies.reduce((acc, subFamily) => {
        const { name, product_family_name } = subFamily;
        if (acc[product_family_name]) {
            acc[product_family_name].push(name);
        }
        else {
            acc[product_family_name] = [name];
        }
        return acc;
    }, {});
}
