import { Base_Unit_Photo_Arr_Rel_Insert_Input, Maybe, Photo_Obj_Rel_Insert_Input } from 'kheops-graphql';
import { RawPhoto } from '../common';

interface BaseUnitPhotosInput {
  main_photo?: Photo_Obj_Rel_Insert_Input;
  photos?: Base_Unit_Photo_Arr_Rel_Insert_Input;
}

export default class PhotosUtils {
  static getFormattedBaseUnitPhotosInput(mainPhoto?: Maybe<RawPhoto>, photos?: Maybe<RawPhoto[]>): BaseUnitPhotosInput {
    const mainPhotoInput = mainPhoto
      ? {
        data: {
          path: mainPhoto.path,
          domain: mainPhoto.domain,
        },
      }
      : undefined;
    const photosInput = photos?.length
      ? {
        data: photos
          .sort((photo) => (photo.path === mainPhoto?.path ? -1 : 0))
          .map((photoData) => ({
            photo: {
              data: {
                domain: photoData.domain,
                path: photoData.path,
              },
            },
          }
          )) }
      : undefined;

    return {
      main_photo: mainPhotoInput,
      photos: photosInput,
    };
  }
}
