import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyInfo } from './CompanyInfoCard';
import CompanyContactInfoBox from '../CompanyContactInfoBox';
import CompanyUsers from '../CompanyUsers';

interface CompanyContactDialogProps {
  isOpen: boolean;
  company: CompanyInfo;
  contractRequestButton?: React.JSX.Element;
  onClose: () => void;
}
export default function CompanyContactDialog({ isOpen, company, contractRequestButton, onClose }: CompanyContactDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: {
            xs: 'calc(100vw - 64px)',
            sm: 600,
            md: 800,
          },
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', mt: 0, p: 3, pb: 2 }} variant="h3">
        {`${t('common:contact')} ${company.tradeName}`}
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent>
        <CompanyUsers
          company={company}
        />
        <CompanyContactInfoBox
          company={company}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="inherit"
          variant="text"
          sx={{
            px: 2,
            mr: 1,
          }}
        >
          {t('common:close')}
        </Button>
        {contractRequestButton}
      </DialogActions>
    </Dialog>
  );
}
