import { Box, Typography } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { parsePhoneNumber } from 'react-phone-number-input';

interface PhoneNumberDisplayProps {
  phoneNumber?: string | null;
  phoneTypeEmoji?: string;
  shouldDisplayFlag?: boolean;
  typographyVariant?: 'subtitle1' | 'body1';
}
export default function PhoneNumberDisplay({ phoneNumber, phoneTypeEmoji, typographyVariant = 'body1', shouldDisplayFlag = true }: PhoneNumberDisplayProps): React.JSX.Element {
  const parsedPhone = parsePhoneNumber(phoneNumber || '');
  const phoneTypeEmojiText = phoneTypeEmoji ? `${phoneTypeEmoji} ` : '';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {parsedPhone?.country && shouldDisplayFlag && (
        <ReactCountryFlag countryCode={parsedPhone.country} svg />
      )}
      <Typography variant={typographyVariant}>{phoneTypeEmojiText}{parsedPhone?.formatInternational() || '-'}</Typography>
    </Box>
  );
}
