import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { BusinessProfile, Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { RoutePaths } from '../routes/AppRoutes';
import useOpenable from '../hooks/useOpenable';
import { CompanyByIdDocument, useCompanyByIdLazyQuery } from '../queries/__generated__/companyById.generated';
import { useCreateContractRequestMutation } from '../mutations/__generated__/createContractRequest.generated';
import BlackBackgroundTooltip from '../common/components/BlackBackgroundTooltip';
import ContractRequestDialog from './contractRequest/ContractRequestDialog';
import { currentContextAtom, userAtom } from '../state';
import { ContractsAsSupplierDocument } from '../queries/__generated__/contractsAsSupplier.generated';
import { ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';

export interface ContractRequestButtonProps {
  toolTipWording: string;
  companyId: string;
  buttonProps?: ButtonProps;
  whenDisableTooltipHover?: boolean;
  contractStatus?: Contract_Status_Enum;
  buttonWording?:string;
}

export default function ContractRequestButton({ contractStatus, companyId, toolTipWording, buttonProps, buttonWording, whenDisableTooltipHover = false }: ContractRequestButtonProps): React.JSX.Element {
  const navigate = useNavigate();
  const context = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const [createContractRequest, { loading }] = useCreateContractRequestMutation({
    refetchQueries: [CompanyByIdDocument, ContractsAsSupplierDocument, ContractsAsBuyerDocument],
    awaitRefetchQueries: true,
  });
  const [companyByIdQuery, { data: companyData }] = useCompanyByIdLazyQuery({
    variables: {
      companyId: companyId!,
    },
  });
  const isContractUnsigned = contractStatus === Contract_Status_Enum.Unsigned;
  const { isOpen: isContactRequestDialogOpen, open: openContactRequest, close: closeContractRequest } = useOpenable();

  let buyingCompanyId: string;
  let supplyingCompanyId: string;

  if (context.realm === Business_Profile_Enum.Buyer) {
    buyingCompanyId = context.companyId;
    supplyingCompanyId = companyId!;
  } else {
    buyingCompanyId = companyId!;
    supplyingCompanyId = context.companyId;
  }

  const handleCloseContact = async (message?: string): Promise<void> => {
    if (message) {
      const res = await createContractRequest({
        variables: {
          userId,
          buyingCompanyId,
          supplyingCompanyId,
          fromBusinessProfile: context.realm as unknown as BusinessProfile,
          message,
        },
      });

      if (res.data?.createContractRequest.channelUrl) {
        navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${res.data.createContractRequest.channelUrl}`);
      }
    }

    closeContractRequest();
  };

  const handleClick = (): void => {
    if (companyData?.company_by_pk?.id) {
      openContactRequest();
    } else {
      companyByIdQuery();
    }
  };

  useEffect((): void => {
    if (companyData?.company_by_pk?.id) {
      openContactRequest();
    }
  }, [companyData]);

  return (
    <>
      <BlackBackgroundTooltip
        title={toolTipWording}
        disableHoverListener={whenDisableTooltipHover}
        arrow
        sx={{
          '.MuiTooltip-popper': {
            backgroundColor: 'black',
          },
        }}
      >
        <span>
          <LoadingButton
            loading={loading}
            disabled={isContractUnsigned}
            onClick={() => handleClick()}
            color="primary"
            variant="contained"
            {...buttonProps}
          >
            {buttonWording}
          </LoadingButton>
        </span>
      </BlackBackgroundTooltip>
      {companyData && (
        <ContractRequestDialog
          isOpen={isContactRequestDialogOpen}
          onClose={handleCloseContact}
          companyInfo={companyData!.company_by_pk!}
          loading={loading}
        />
      )}
    </>
  );
}
