
      import snippet from '@segment/snippet';

      const code = snippet.min({
        apiKey: import.meta.env.REACT_APP_SEGMENT_WRITE_KEY,
        load: true,
      });
      const scriptTag = document.createElement('script');

      scriptTag.textContent = code;
      document.body.prepend(scriptTag);
    