export enum OrderButtonType {
  APPROVE = 'APPROVE',
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
}

export enum OrderStatusType {
  order_to_be_validated_by_buyer = 'order_to_be_validated_by_buyer',
  order_to_be_validated_by_supplier = 'order_to_be_validated_by_supplier',
  order_to_be_delivered_by_supplier = 'order_to_be_delivered_by_supplier',
  order_to_be_prepared_by_supplier = 'order_to_be_prepared_by_supplier',
  order_to_be_received_by_buyer = 'order_to_be_received_by_buyer',
  order_to_be_billed_by_supplier = 'order_to_be_billed_by_supplier',
  order_to_be_paid_by_buyer = 'order_to_be_paid_by_buyer',
  order_closed = 'order_closed',
}
