import { AlgoliaHit } from 'instantsearch.js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { getProductUrlFromProductHit } from '../discovery.utils';
import { ProductHit } from '../../search/search';
import SearchUtils from '../../common/utils/search.utils';
import FileUtils from '../../common/utils/file.utils';
import SizedImage from '../../common/components/SizedImage';
import { ellipsisStyle } from '../../common/utils/style.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';

export interface ProductSlideProps {
  hit: AlgoliaHit;
}

export default function ProductSlide({ hit }: ProductSlideProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const currencyFormat = useCurrencyFormat();
  const productUrl = getProductUrlFromProductHit(hit as AlgoliaHit<ProductHit>);

  return (
    <Box
      component={Link}
      to={productUrl}
      sx={{
        color: 'text.primary',
        backgroundColor: 'white',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        pr: 2,
        gap: 2,
        width: 208,
        flexShrink: 0,
        textDecoration: 'none',
        overflow: 'hidden',
      }}
    >
      <SizedImage
        height={{
          xs: 96,
          lg: 104,
        }}
        width={{
          xs: 96,
          lg: 104,
        }}
        src={
          FileUtils.BuildPackagingImageUrl({
            photo: hit.photo,
            size: { height: 104, width: 104, fit: 'contain' },
            type: hit.family,
          })
        }
        alt={t('common:company')}
        borderRadius={0}
      />
      <Box sx={{ minWidth: 0 }}>
        <Typography
          variant="body1"
          sx={{
            ...ellipsisStyle,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            whiteSpace: 'wrap',
          }}
        >
          {hit.name}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {SearchUtils.getFormatedUnitPrice(hit as AlgoliaHit<ProductHit>, currencyFormat.format)}
        </Typography>
      </Box>
    </Box>
  );
}
