import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../ProductFormPackagings';

export interface CSUTypeFormProps {
  csuIndex: number;
}

export default function CSUTypeForm({ csuIndex }: CSUTypeFormProps): React.JSX.Element {
  const { control } = useFormContext<ProductPackagingsFormData>();
  const { t } = useTranslation(['packaging', 'products']);

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('products:base_unit_type')}
      </Typography>
      <Controller
        defaultValue="UNIT"
        name={`productPackagings.${csuIndex}.csu.csu_type`}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} row>
            <Box sx={{ display: 'column', mr: 2 }}>
              <FormControlLabel value="UNIT" control={<Radio />} label={t('packaging:per_unit') as string} />
              <Typography variant="subtitle2">
                {t('products:per_unit_description')}
              </Typography>
            </Box>

            <Box sx={{ display: 'column' }}>
              <FormControlLabel value="BULK" control={<Radio />} label={t('products:bulk') as string} />
              <Typography variant="subtitle2">
                {t('products:bulk_description')}
              </Typography>
            </Box>
          </RadioGroup>
        )}
      />
    </Box>
  );
}
