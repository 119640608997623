import { Button, SxProps, Theme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';

interface ClearAllButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

export default function ClearAllButton({ onClick, sx }: ClearAllButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<ClearIcon sx={{ width: 18 }} />}
      sx={{
        textDecoration: 'underline',
        fontSize: 16,
        alignItems: 'center',
        px: 0.5,
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '& .MuiButton-startIcon': {
          mr: 0.5,
        },
        height: 48,
        ...sx,
      }}
      onClick={onClick}
    >
      {t('common:clear_all')}
    </Button>
  );
}
