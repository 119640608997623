import { AppBar, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';

export interface TopBarProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function TopBar({ children, sx }: TopBarProps): React.JSX.Element {
  const [displayBorder, setDisplayBorder] = useState(false);

  useEffect(() => {
    const listener = (): void => {
      setDisplayBorder(!!window.scrollY);
    };

    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <AppBar
      elevation={0}
      sx={{
        color: 'text.primary',
        height: 72,
        backgroundColor: 'common.white',
        flexDirection: 'row',
        alignItems: 'center',
        px: {
          xs: 2,
          sm: 4,
        },
        zIndex: 10,
        boxSizing: 'border-box',
        borderBottom: displayBorder ? '1px solid' : 'none',
        borderColor: 'divider',
        ...sx,
      }}
    >
      {children}
    </AppBar>
  );
}
