import { Box, Typography } from '@mui/material';
import { Product_Product_Labels_Product_Label } from 'kheops-graphql';
import { useTranslation } from 'react-i18next';
import { FormatPackagingLabels } from '../../../packaging/packaging-formatter';

interface ProductLabelsProps {
  labels: Pick<Product_Product_Labels_Product_Label, 'label'>[];
  short?: boolean;
}

export default function ProductLabels({ labels, short }: ProductLabelsProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Box>
      {!short && (
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('products:certifications_and_qualities')}
        </Typography>
      )}
      <Box sx={{ display: 'flex', columnGap: 1 }}>
        {
          FormatPackagingLabels(labels).map((labelImage) => (
            <Box
              key={labelImage.alt}
              component="img"
              alt={labelImage.alt}
              src={labelImage.src}
              sx={{
                borderRadius: 1,
                borderColor: 'divider',
                borderStyle: 'solid',
                borderWidth: '1px',
                p: 0.5,
                height: 32,
                width: 32,
                objectFit: 'contain',
              }}
            />
          ))
        }
      </Box>
    </Box>
  );
}
