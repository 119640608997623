import { Maybe } from 'graphql/jsutils/Maybe';
import { atom } from 'jotai';
import {
  Product_Sub_Family_Name_Enum,
  Product_Label_Name_Enum,
  Base_Unit_Type_Enum,
  Measurement_Unit_Enum,
  Trade_Item_Unit_Descriptor_Enum,
  Photo,
  Billing_Type_Enum,
  Duration_Type_Enum,
} from 'kheops-graphql';

export type ProductPackagingEditMode = 'CREATE' | 'ADD' | 'EDIT';
export type DraftPhoto = Pick<Photo, 'domain' | 'path'>;

export interface DraftProduct {
  sku?: number;
  name: string;
  company_id: string;
  labels: Product_Label_Name_Enum[];
  sub_family: Product_Sub_Family_Name_Enum;
  shelf_life_duration: Maybe<number>;
  shelf_life_duration_type: Maybe<Duration_Type_Enum>;
  vat_rate: number;
  ingredients: Maybe<string>;
  allergens: Maybe<string>;
  calories: Maybe<number>;
  calories_kj: Maybe<number>;
  fat: Maybe<number>;
  saturated_fat: Maybe<number>;
  carbohydrate: Maybe<number>;
  sugars: Maybe<number>;
  sodium: Maybe<number>;
  protein: Maybe<number>;
  description: Maybe<string>;
}

export interface DraftCSU {
  sku?: number;
  unit_quantity?: number;
  unit_type: Base_Unit_Type_Enum;
  unit_net_content?: number;
  content_measurement_unit: Maybe<Measurement_Unit_Enum>;
  gtin: Maybe<string>;
  photos: DraftPhoto[];
  main_photo?: DraftPhoto | null;
  availability: boolean;
  billing_type: Billing_Type_Enum;
  csu_type: 'BULK' | 'UNIT';
  suggested_retail_price?: Maybe<number>;
}

export interface DraftPackaging {
  sku?: number;
  trade_item_unit_descriptor: Trade_Item_Unit_Descriptor_Enum;
  content_measurement_unit: Maybe<Measurement_Unit_Enum>;
  unit_quantity: Maybe<number>;
  net_content: Maybe<number>;
  price: number;
  base_unit_price: number;
}

export interface DraftProductPackagings {
  csu: DraftCSU;
  packagings: DraftPackaging[];
}

export const draftProductPackagingsAtom = atom<DraftProductPackagings[]>([]);
export const draftProductAtom = atom<DraftProduct | undefined>(undefined);
export const companyIdAtom = atom<string>('');
export const productSubmittedAtom = atom<boolean>(false);
export const editModeAtom = atom<ProductPackagingEditMode>('CREATE');
