import { useAtomValue } from 'jotai';
import { Business_Profile_Enum, Document_Type_Enum, Payment_Method_Enum } from 'kheops-graphql';
import { currentContextAtom } from '../state';

export type OrderInvoiceType = Document_Type_Enum.Invoice | Document_Type_Enum.OrderSummary;

export default function useOrderInvoiceType(hasCommission: boolean, hasInvoiceGeneration: boolean, paymentMethod: Payment_Method_Enum): OrderInvoiceType {
  const { realm } = useAtomValue(currentContextAtom);

  return realm === Business_Profile_Enum.Supplier && hasCommission && paymentMethod !== Payment_Method_Enum.NoPayment && hasInvoiceGeneration
    ? Document_Type_Enum.OrderSummary
    : Document_Type_Enum.Invoice;
}
