import { useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useClearRefinements, useRefinementList } from 'react-instantsearch';
import { currentFamilyAtom, currentSubFamilyAtom, dispatchNavigationAtom } from '../state';
import { getSearchFamiliesFromCurrentFamilies } from '../discovery.utils';

export interface DiscoveryNavigationWrapperProps {
  attribute: string;
  ignoreHistory?: boolean;
  children: React.ReactElement;
}

export default function DiscoveryNavigationWrapper({ attribute, ignoreHistory, children }: DiscoveryNavigationWrapperProps): React.JSX.Element {
  const currentFamily = useAtomValue(currentFamilyAtom);
  const currentSubFamily = useAtomValue(currentSubFamilyAtom);
  const [dispatchNavigation, setDispatchNavigation] = useAtom(dispatchNavigationAtom);
  const { refine, canRefine } = useRefinementList({ attribute });
  const { refine: clearRefinements } = useClearRefinements({ includedAttributes: [attribute] });

  useEffect((): void => {
    if (!canRefine || !ignoreHistory) {
      return;
    }

    const families = getSearchFamiliesFromCurrentFamilies(currentFamily, currentSubFamily);

    families.forEach((family) => refine(family));
  }, [canRefine]);

  useEffect((): void => {
    if (dispatchNavigation) {
      const valuesToEnable = getSearchFamiliesFromCurrentFamilies(currentFamily, currentSubFamily);

      clearRefinements();

      valuesToEnable.forEach((value) => refine(value));

      setDispatchNavigation(false);
    }
  }, [currentFamily, currentSubFamily, dispatchNavigation]);

  return children;
}
