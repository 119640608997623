import i18n from 'i18next';
import { useMemo } from 'react';

export default function useCurrencyFormat(options?: Intl.NumberFormatOptions): Intl.NumberFormat {
  const resolvedOptions = useMemo(() => {
    if (options) {
      const res = { ...options };

      if (res.maximumFractionDigits !== undefined && res.minimumFractionDigits === undefined) {
        res.minimumFractionDigits = Math.min(res.maximumFractionDigits, 2);
      }

      return res;
    }
  }, [options]);

  return useMemo(() => {
    return new Intl.NumberFormat(i18n.resolvedLanguage, {
      style: 'currency',
      currency: 'EUR',
      ...resolvedOptions,
    });
  }, [i18n.resolvedLanguage]);
}
