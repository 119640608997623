import { MenuItem, MenuList, Radio, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { availableContextsAtom, currentContextAtom } from '../state';
import { Context } from '../common/models/user.models';
import { getCompanyShortName } from '../common/utils/common.utils';
import { ellipsisStyle } from '../common/utils/style.utils';
import ContextImage from './ContextImage';

export interface ContextNavigationProps {
  showCurrent?: boolean;
}

export default function ContextNavigation({ showCurrent }: ContextNavigationProps): React.JSX.Element {
  const { companyId: currentCompanyId } = useAtomValue(currentContextAtom);
  const availableContexts = useAtomValue(availableContextsAtom);

  const contexts = useMemo((): Context[] => {
    if (showCurrent) {
      return availableContexts;
    }

    return availableContexts.filter((context) => context.companyId !== currentCompanyId);
  }, [availableContexts]);

  const handleMenuItemClick = (companyId: string): void => {
    localStorage.setItem('contextId', companyId);
    window.history.replaceState({ ...window.history.state, newContextId: companyId }, '', `${window.location.pathname}?contextId=${companyId}`);
    window.location.reload();
  };

  return (
    <MenuList
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        py: 0,
        '& .MuiMenuItem-root': {
          p: 1,
          height: 40,
          borderRadius: 4,
        },
      }}
    >
      {contexts.map((context) => (
        <MenuItem key={context.companyId} onClick={() => handleMenuItemClick(context.companyId)}>
          {showCurrent && <Radio checked={currentCompanyId === context.companyId} />}
          <ContextImage context={context} size={40} borderRadius={4} />
          <Typography variant="body1" sx={{ ...ellipsisStyle, ml: 1 }}>
            {getCompanyShortName(context.companyName, context.realm)}
          </Typography>
        </MenuItem>
      ))}
    </MenuList>
  );
}
