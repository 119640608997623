/* eslint-disable no-console */
import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18next';
import { AUTH_TOKEN, AuthToken } from '../models/auth.model';

interface RequestConfig extends InternalAxiosRequestConfig {
  _authRetry: boolean;
}

interface RequestResponse extends AxiosResponse {
  config: RequestConfig;
}

let isAxiosConfigSet = false;

export function setAxiosConfiguration(logout: () => void): void {
  if (isAxiosConfigSet) {
    return;
  }

  axios.defaults.baseURL = import.meta.env.REACT_APP_SERVER_BASE_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.interceptors.request.use(
    async (request) => {
      const accessToken = localStorage.getItem(AUTH_TOKEN);

      if (accessToken) {
        let parsedAccessToken: AuthToken;
        try {
          parsedAccessToken = JSON.parse(accessToken);
          request.headers!.Authorization = `Bearer ${parsedAccessToken.access_token}`;
        } catch (e) {
          logout();
        }
      }

      if (import.meta.env.REACT_APP_AXIOS_DEBUG) {
        console.log('Request: ', request);
      }

      request.headers!['x-custom-lang'] = i18n.resolvedLanguage;

      return request;
    },
    (error) => {
      if (import.meta.env.REACT_APP_AXIOS_DEBUG) {
        console.log('Request error: ', error);
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => {
      if (import.meta.env.REACT_APP_AXIOS_DEBUG) {
        console.log('Response: ', response);
      }
      return response;
    },
    (error) => {
      if (import.meta.env.REACT_APP_AXIOS_DEBUG) {
        console.log('Response error: ', error);
      }
      const response = error.response as RequestResponse;
      if (!response) {
        return Promise.reject(error);
      }

      const token = localStorage.getItem(AUTH_TOKEN);
      const url = response.config.url ?? '';

      // Detect 401 axios error
      if (
        axios.isAxiosError(error)
        && response?.status === 401
        && token
        && !url.includes('auth')
      ) {
        const accessToken: AuthToken = JSON.parse(token);

        // Check if a refreshToken exist and
        // if it's not an auth url or a retry
        if (accessToken.refresh_token && !response.config._authRetry) {
          // Call api to refresh the token
          return axios
            .post<AuthToken>('/auth/refreshToken', { refresh_token: accessToken.refresh_token })
            .then(({ data }) => {
              // Update user accessToken and refreshToken if needed
              localStorage.setItem(AUTH_TOKEN, JSON.stringify(data));
              // eslint-disable-next-line max-len
              axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
              // Avoid infinite loop of retry
              response.config._authRetry = true;

              // Retry failed request
              return axios(response.config);
            })
            .catch((err) => {
              // If an error is still trown and it's a 4XX user is logged out
              if (err?.response?.status >= 400) {
                logout();
              }
              return Promise.reject(err);
            });
        }
        // In case it's a 401 and we don't have any user
        logout();
      }

      return Promise.reject(error);
    },
  );

  isAxiosConfigSet = true;
}
