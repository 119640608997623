/// <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    // The variable below is part of Matomo's SDK and automatically loaded by Segment
    _paq: [string, ...unknown[]][];
  }
}

export interface SegmentPageProperties {
  path?: string,
  referrer?: string,
  search?: string,
  title?: string,
  url?: string,
}

export class Analytics {
  static page(pageProperties: SegmentPageProperties): void {
    // We need to specify specific variables for Matomo to properly track SPAs
    // See https://developer.matomo.org/guides/spa-tracking
    window.analytics.ready(() => {
      window._paq = window._paq || [];
      window._paq.push(['setCustomUrl', pageProperties.path]);
      window._paq.push(['setReferrerUrl', pageProperties.referrer]);
    });
    window.analytics.page(pageProperties);
  }

  static track(name: string, properties?: SegmentAnalytics.SegmentOpts): void {
    window.analytics.track(name, properties);
  }

  static identify(userId: string): void {
    window.analytics.identify(userId);
  }

  static reset(): void {
    if ('Intercom' in window) {
      window.Intercom('shutdown');
    }

    window.analytics.reset();
  }
}
