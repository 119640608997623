import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import KheopsAvatar from '../../common/components/KheopsAvatar';
import { orderAtom } from '../state/state';
import { longDateFormat } from '../../common/state/state';

export default function OrderCreationInfo(): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const order = useAtomValue(orderAtom);

  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
        <Typography variant="subtitle2">
          {t('order:ordered_at')}
        </Typography>
        <Typography sx={{ lineHeight: '24px' }}>
          {format(order.created_at, longDateFormat)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle2">
          {t('order:created_by')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {!!order.user && (
            <>
              <KheopsAvatar size={24} userAvatars={order.user} sx={{ borderRadius: 2 }} />
              <Typography variant="body1">
                {order.user.first_name}&nbsp;{order.user.last_name}
              </Typography>
            </>
          )}
          {!order.user && (
            <Typography>-</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
