import { addDays, isSunday } from 'date-fns';
import { Delivery_Hours } from 'kheops-graphql';
import { WeekDays } from '../../company/buyer/state';

export default class DateUtils {
  static AddWorkingDaysToDate(date: Date, workingDays: number): Date {
    let dateToShift = date;
    for (let i = 1; i <= workingDays; i++) {
      dateToShift = addDays(dateToShift, 1);
      if (isSunday(dateToShift)) {
        dateToShift = (addDays(dateToShift, 1));
      }
    }
    return dateToShift;
  }
}

export function isDeliveryDay(day: number, deliveryHours?: Pick<Delivery_Hours, 'week_day'>[]): boolean {
  if (!deliveryHours?.length) {
    return false;
  }

  const dayName: WeekDays = Object.values(WeekDays)[day ? day - 1 : 6];
  const deliveryDays = deliveryHours.map(({ week_day }) => week_day);

  return deliveryDays.includes(dayName);
}
