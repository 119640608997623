import { SvgIcon, SxProps, Tab, Tabs, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import useNavigationTabs from '../hooks/useNavigationTabs';
import useCurrentTabs from './hooks/useCurrentTab';
import { RoutePaths } from '../routes/AppRoutes';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { SNACKBAR_DEFAULT_HIDE_DURATION } from '../state';

export interface NavigationTabsProps {
  sx?: SxProps<Theme>;
}

export default function NavigationTabs({ sx }: NavigationTabsProps): React.JSX.Element {
  const tabsInfo = useNavigationTabs();
  const currentTabValue = useCurrentTabs([
    RoutePaths.DISCOVERY_SUPPLIERS,
    RoutePaths.DISCOVERY_BUYERS,
    RoutePaths.SEARCH_PRODUCT,
    RoutePaths.CONTRACT_LIST,
    RoutePaths.ORDER_LIST,
  ]);
  const [commonSnackbarProps, setCommonSnackbarProps] = useAtom(commonSnackbarPropsAtom);

  useEffect(() => {
    if (commonSnackbarProps && !commonSnackbarProps!.snackbarProps.autoHideDuration) {
      setCommonSnackbarProps({
        ...commonSnackbarProps,
        snackbarProps: {
          ...commonSnackbarProps.snackbarProps,
          autoHideDuration: SNACKBAR_DEFAULT_HIDE_DURATION,
        },
      });
    }
  }, [currentTabValue]);

  return (
    <Tabs
      centered
      indicatorColor="secondary"
      sx={sx}
      textColor="secondary"
      value={currentTabValue}
    >
      {tabsInfo.map((tab) => (
        <Tab
          key={tab.to}
          value={tab.to}
          icon={(
            <SvgIcon>
              <tab.icon />
            </SvgIcon>
          )}
          label={tab.label}
          component={Link}
          to={tab.to}
          sx={{
            fontSize: '0.875rem',
            borderRadius: 4,
            '& .MuiTab-iconWrapper': {
              mb: 0.5,
            },
            '&.Mui-selected': {
              fontWeight: 600,
            },
            '&:hover': {
              backgroundColor: 'greys.primary',
            },
          }}
        />
      ))}
    </Tabs>
  );
}
