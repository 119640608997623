import { Box, Chip, Grid, Typography } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { AlgoliaHit } from 'instantsearch.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Base_Unit_Type_Enum, Contract_Status_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { FormatMeasurementUnit, formatPriceByBillingType } from 'kheops-utils';
import BlackBackgroundTooltip from '../../common/components/BlackBackgroundTooltip';
import FileUtils from '../../common/utils/file.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { ProductHit } from '../search';
import { ProductHitInfo } from './SearchProductHit';
import UpdateBasketButton from './UpdateBasketButton';
import ContractRequestButton from '../../company/ContractRequestButton';
import { linkStyle } from '../../common/utils/style.utils';

interface SearchProductCardProps {
  hit: AlgoliaHit<ProductHit>;
  productHitInfo: ProductHitInfo;
  contractStatus?: Contract_Status_Enum;
}

export default function SearchProductRow({ hit, productHitInfo, contractStatus }: SearchProductCardProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'contracts']);
  const currencyFormat = useCurrencyFormat();
  const priceComponent = useMemo(() => {
    const isContractSigned = contractStatus === Contract_Status_Enum.Signed;

    if (hit.baseUnitType === Base_Unit_Type_Enum.Bulk && !isContractSigned) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      <BlackBackgroundTooltip
        title={t(isContractSigned ? 'products:price_per_packaging' : 'products:price_per_volume')}
        arrow
      >
        <Typography variant="subtitle1">
          {isContractSigned
            ? formatPriceByBillingType(currencyFormat.format(Number(hit.price)), hit.billingType)
            : `${currencyFormat.format(Number(hit.volumePrice.value))}/${FormatMeasurementUnit(hit.volumePrice.unit as Measurement_Unit_Enum, 'fr', 1)}`}
        </Typography>
      </BlackBackgroundTooltip>
    );
  }, [contractStatus, hit]);
  const imageSize = 80;

  return (
    <>
      <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component={Link}
          to={productHitInfo.productUrl}
          sx={{
            width: imageSize,
            height: imageSize,
            textAlign: 'center',
            mr: 2,
          }}
        >
          <Box
            component="img"
            src={FileUtils.BuildPackagingImageUrl({ photo: hit.photo, type: hit.family, size: { height: 160, fit: 'contain' } })}
            sx={{
              maxWidth: imageSize,
              maxHeight: imageSize,
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Box
            component={Link}
            to={productHitInfo.productUrl}
            sx={{
              ...linkStyle,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 600,
              }}
            >
              {hit.name}
            </Typography>
          </Box>
          <Box
            component={Link}
            to={productHitInfo.companyUrl}
            sx={{
              ...linkStyle,
            }}
          >
            <Typography variant="subtitle1" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {hit.company}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', pl: '8px !important' }}>
        {!!hit.gtin && (
          <Chip
            color="marketing2"
            size="small"
            sx={{
              borderRadius: 22,
              px: 0,
            }}
            label={hit.gtin}
          />
        )}
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
        <Chip
          sx={{
            borderRadius: 22,
            mr: 0.5,
          }}
          size="small"
          label={hit.baseUnit}
        />
        <Typography variant="subtitle2" sx={{ mt: 1, ml: 1 }}>
          {hit.packaging}
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <BlackBackgroundTooltip
            title={t(hit.baseUnitType === Base_Unit_Type_Enum.Bulk ? 'products:price_per_volume' : 'products:price_per_sales_unit')}
            arrow
            placement="top"
          >
            <Typography variant="h6" color="primary.main" sx={{ fontWeight: 700 }}>
              {productHitInfo.price}
            </Typography>
          </BlackBackgroundTooltip>
          {priceComponent}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', ml: 1.5, width: '124px' }}>
          {
            contractStatus === Contract_Status_Enum.Signed
              ? <UpdateBasketButton packagingId={hit.packaging_id} supplyingCompanyId={hit.company_id} />
              : (
                <ContractRequestButton
                  companyId={hit.company_id}
                  contractStatus={contractStatus}
                  toolTipWording={t('contracts:you_can_command_when_supplier_is_contractualized')}
                  whenDisableTooltipHover={!contractStatus}
                  buttonProps={{
                    startIcon: <ShoppingCartOutlinedIcon sx={{ width: 16, ml: 1 }} />,
                  }}
                />
              )
          }
        </Box>
      </Grid>
    </>
  );
}
