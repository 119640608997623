import { Business_Profile_Enum } from 'kheops-graphql';
import { Navigate } from 'react-router';
import { useAtomValue } from 'jotai';
import { RoutePaths } from './routes/AppRoutes';
import { currentContextAtom } from './state';

export default function DefaultPage(): React.JSX.Element {
  const { realm } = useAtomValue(currentContextAtom);

  if (realm === Business_Profile_Enum.Supplier) {
    return <Navigate to={RoutePaths.ORDER_LIST} />;
  }

  return <Navigate to={RoutePaths.SEARCH_PRODUCT} />;
}
