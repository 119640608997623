import type { PlainSearchParameters } from 'algoliasearch-helper';
import { useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { configureRefineAtom, uiStateAtom } from '../search/state/state';

interface UseRefine {
  refine: (searchParameters: PlainSearchParameters) => void;
  uiState: PlainSearchParameters;
}

// This effect is here only to handle an issue from Algolia https://github.com/algolia/react-instantsearch/issues/3490
// TL;DR: useConfigure refine does not update others instantsearch hooks on the first call, so we need to maintain our own uiState
export default function useRefine(): UseRefine {
  const configureRefine = useAtomValue(configureRefineAtom);
  const [uiState, setUiState] = useAtom(uiStateAtom);

  const refineCallback = useCallback((searchParameters: PlainSearchParameters) => {
    if (!configureRefine) {
      return;
    }

    const mergedParameters = {
      ...uiState,
      ...searchParameters,
    };

    configureRefine!.refine(mergedParameters);
    setUiState(mergedParameters);
  }, [configureRefine, setUiState, uiState]);

  return {
    refine: refineCallback,
    uiState,
  };
}
