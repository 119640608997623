import { useSetAtom } from 'jotai';
import { ProductFamilyNavigation, ProductSubFamilyNavigation, currentFamilyAtom, currentSubFamilyAtom, dispatchNavigationAtom } from '../state';
import { getSearchFamiliesFromCurrentFamilies } from '../discovery.utils';

export interface UseNavigateByProductFamilyParams {
  attribute: string;
}

export interface UseNavigateByProductFamily {
  navigate: (currentFamily: ProductFamilyNavigation, currentSubFamily: ProductSubFamilyNavigation) => void;
}

export default function useNavigateByProductFamily(): UseNavigateByProductFamily {
  const setCurrentFamily = useSetAtom(currentFamilyAtom);
  const setCurrentSubFamily = useSetAtom(currentSubFamilyAtom);
  const setDispatchNavigation = useSetAtom(dispatchNavigationAtom);

  const packagingIndex = import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX;
  const companyIndex = import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX;

  const navigate = (newCurrentFamily: ProductFamilyNavigation, newCurrentSubFamily: ProductSubFamilyNavigation): void => {
    setCurrentFamily(newCurrentFamily);
    setCurrentSubFamily(newCurrentSubFamily);
    setDispatchNavigation(true);

    const families = getSearchFamiliesFromCurrentFamilies(newCurrentFamily, newCurrentSubFamily);

    window.history.replaceState(
      {
        ...window.history.state,
        uiState: {
          ...window.history.state.uiState,
          [packagingIndex]: {
            ...window.history.state.uiState?.[packagingIndex],
            refinementList: {
              ...window.history.state.uiState?.[packagingIndex]?.refinementList,
              family: families,
            },
          },
          [companyIndex]: {
            ...window.history.state.uiState?.[companyIndex],
            refinementList: {
              ...window.history.state.uiState?.[packagingIndex]?.refinementList,
              product_families: families,
            },
          },
        },
      },
      '',
    );
  };

  return {
    navigate,
  };
}
