import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { LoadingButton } from '@mui/lab';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Business_Profile_Enum, Company } from 'kheops-graphql';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { currentContextAtom } from '../../state';
import { DisplayPhoto } from '../../common/common';

export interface ContractRequestDialogProps {
  companyInfo: Pick<Company, 'tradeName' | 'business_profile'> & { photos: DisplayPhoto[] };
  isOpen: boolean;
  onClose: (message?: string) => void;
  loading?: boolean;
}

export default function ContractRequestDialog({ companyInfo, isOpen, onClose, loading }: ContractRequestDialogProps): React.JSX.Element {
  const { realm } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['common', 'contracts']);
  const [message, setMessage] = useState('');

  const handleSubmit = (): void => {
    onClose(message);
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle sx={{ textAlign: 'center', mt: 0, p: 3, pb: 2 }} variant="h3">
        {t('contracts:contact')}
      </DialogTitle>
      <DialogContent sx={{ px: 3 }}>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
            py: 2,
          }}
        >
          {realm === Business_Profile_Enum.Buyer ? t('contracts:contact_request_description_from_buyer') : t('contracts:contact_request_description_from_supplier')}
        </Typography>
        <Typography variant="h5" sx={{ fontSize: '1.25rem', fontWeight: 700, pt: 2 }}>
          {t('contracts:your_message')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <SizedImage
            src={
              FileUtils.BuildCompanyImageUrl({
                photo: companyInfo.photos[0]?.photo,
                type: companyInfo.business_profile,
                size: { height: 48, width: 48 },
              })
            }
            alt={t('common:company')}
            height={48}
            width={48}
            sx={{ borderRadius: 4, mr: 2 }}
          />
          <Typography variant="h6">
            {companyInfo.tradeName}
          </Typography>
        </Box>
        <TextField
          placeholder={t('contracts:ask_questions_to_supplier')}
          onChange={(event) => setMessage(event.target.value)}
          sx={{ width: '100%' }}
          multiline
          minRows={3}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Button
          onClick={() => onClose()}
          color="inherit"
          variant="text"
          sx={{
            px: 2,
            mr: 1,
          }}
        >
          {t('common:cancel')}
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          color="primary"
          variant="contained"
          startIcon={<AddBusinessOutlinedIcon sx={{ '&&': { fontSize: '1rem' }, ml: 0.5 }} fontSize="small" />}
          disabled={!message}
        >
          {t('contracts:contact')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
