import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';

export interface DeliveryTimePickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timePickerProps: TimePickerProps<any>;
  error?: boolean;
}

export default function DeliveryTimePicker({ timePickerProps, error }: DeliveryTimePickerProps): React.JSX.Element {
  return (
    <TimePicker
      {...timePickerProps}
      slotProps={{
        inputAdornment: {
          position: 'start',
        },
        textField: {
          error,
        },
      }}
      sx={{
        borderColor: 'divider',
        '& .MuiFormControl-root, & .MuiTextField-root': {
          borderColor: 'divider',
        },
        '& .MuiButtonBase-root': {
          ml: 0.25,
        },
        '& .MuiInputAdornment-root': {
          m: 0,
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
            color: 'text.primary',
          },
        },
        '& .MuiInputBase-root': {
          borderRadius: 3,
          height: 48,
        },
      }}
    />
  );
}
