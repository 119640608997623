import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Global_Product_Family_Enum, Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { CompanyInfo } from './companyInfo/CompanyInfoCard';
import { useGetCompanyUsersQuery } from '../queries/__generated__/getCompanyUsers.generated';
import UserCard from './UserCard';
import { UserAvatars } from '../common/models/user.models';

interface CompanyUserProps {
  company: CompanyInfo
}

interface UserProductFamilySettings {
  generalSetting?: Global_Product_Family_Enum | null;
  productFamilies: Product_Family_Name_Enum[];
  productSubFamilies: Product_Sub_Family_Name_Enum[]
}

export interface UserDisplay {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  avatars: UserAvatars;
  productFamiliesSettings: UserProductFamilySettings;
}

export default function CompanyUsers({ company }: CompanyUserProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const { data: usersData } = useGetCompanyUsersQuery({
    variables: {
      companyId: company.id,
    },
  });
  const [usersToDisplay, setUserToDisplay] = useState<UserDisplay[]>([]);

  useEffect(() => {
    if (usersData?.role_assignment) {
      const users: UserDisplay[] = usersData.role_assignment.map(({ product_families: userProductFamilies, user }) => {
        const userGlobalProductFamiliesSetting = userProductFamilies[0]?.global_setting;
        const userProductFamiliesSettings: UserProductFamilySettings = {
          generalSetting: userGlobalProductFamiliesSetting,
          productFamilies: !userGlobalProductFamiliesSetting
            ? userProductFamilies.map((userProductFamily) => userProductFamily.product_family).filter((family) => !!family) as Product_Family_Name_Enum[]
            : [],
          productSubFamilies: !userGlobalProductFamiliesSetting
            ? userProductFamilies.map((userProductFamily) => userProductFamily.product_sub_family).filter((subfamily) => !!subfamily) as Product_Sub_Family_Name_Enum[]
            : [],
        };

        return {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          avatars: {
            default_avatar: user.default_avatar,
            auth_provider_avatar_url: user.auth_provider_avatar_url,
          },
          productFamiliesSettings: userProductFamiliesSettings,
        };
      });

      const usersToBeDisplayed = users.filter((user) => user.productFamiliesSettings.generalSetting !== Global_Product_Family_Enum.None);

      setUserToDisplay(usersToBeDisplayed);
    }
  }, [usersData]);

  const usersComponent = useMemo(() => {
    return usersToDisplay
      .sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''))
      .map((user) => (
        <UserCard
          key={user.id}
          user={user}
        />
      ));
  }, [usersToDisplay]);

  if (usersData && !usersToDisplay.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('common:contacts')}
      </Typography>
      <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
        {usersComponent}
      </Box>
    </Box>
  );
}
