import { Box, Chip, Typography, buttonBaseClasses, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum, Order_Status_Enum, Payment_Method_Enum } from 'kheops-graphql';
import { orderAtom, orderInvoiceAtom } from '../state/state';
import KheopsAvatar from '../../common/components/KheopsAvatar';
import { currentContextAtom, userAtom } from '../../state';
import ExpandableText from '../../common/components/ExpandableText';
import useOrderFormButtons from './hooks/useOrderFormButtons';
import { OrderButtonType } from '../../common/models/order.models';
import OrderTotalPriceDisplay from './OrderTotalPriceDisplay';
import { longDateFormat } from '../../common/state/state';

interface OrderInvoiceDetailsProps {
  availableButtons: OrderButtonType[];
}

export default function OrderInvoiceDetails({ availableButtons }: OrderInvoiceDetailsProps): React.JSX.Element {
  const { t } = useTranslation(['order', 'price', 'payment']);
  const invoice = useAtomValue(orderInvoiceAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const order = useAtomValue(orderAtom);
  const { id: userId } = useAtomValue(userAtom);
  const orderFormButtons = useOrderFormButtons({ availableButtons, userId });
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const canEditInvoice = realm === Business_Profile_Enum.Supplier && order.status === Order_Status_Enum.OrderToBePaidByBuyer && !order.has_invoice_generation;
  const displayedReference = invoice?.revised_reference || invoice?.reference;

  const displayButtonInTopRightCorner = orderFormButtons.length === 1 && !canEditInvoice && !isXs;

  return (
    invoice
      ? (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            py: 3,
            px: 2,
            borderRadius: 6,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px 48px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Typography variant="subtitle1">{t('order:invoice_number')}</Typography>
                <Typography variant="body1" fontSize={24}>{displayedReference}</Typography>
              </Box>
              <OrderTotalPriceDisplay />
              {(order.payment_method === Payment_Method_Enum.SddAuto || order.payment_method === Payment_Method_Enum.SddTriggered) && (
              <>
                <Box>
                  <Typography variant="subtitle1">{t('payment:payment_method')}</Typography>
                  <Chip
                    label={t(order.payment_method === Payment_Method_Enum.SddAuto ? 'payment:auto_payment' : 'payment:triggered_payment')}
                    color="secondary"
                    size="small"
                  />
                </Box>
                {order.sdd_payment_date && (
                <Box>
                  <Typography variant="subtitle1">{t('payment:payment_date')}</Typography>
                  <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>{format(order.sdd_payment_date, longDateFormat)}</Typography>
                </Box>
                )}
              </>
              )}
            </Box>

            {displayButtonInTopRightCorner && (
              <Box
                sx={{
                  height: 40,
                  [`& .${buttonBaseClasses.root}`]: {
                    lineHeight: '18px',
                  },
                }}
              >
                {orderFormButtons}
              </Box>
            )}
          </Box>
          {invoice.comment && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                p: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: 4,
              }}
            >
              <Typography variant="h6">{t('order:total_change_reason')} :</Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
              >
                <KheopsAvatar
                  userAvatars={invoice.order.updater!}
                  size={32}
                  sx={{
                    borderRadius: 2,
                  }}
                />
                <ExpandableText
                  text={invoice.comment}
                  lineClampThreshold={4}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              [`& .${buttonBaseClasses.root}`]: {
                height: '42px',
              },
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              gap: 1,
            }}
          >
            {!displayButtonInTopRightCorner && (orderFormButtons)}
          </Box>
        </Box>
      )
      : (
        <></>
      )

  );
}
