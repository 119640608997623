import { Box, Card, IconButton, SxProps, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import GroupAddOutlined from '@mui/icons-material/GroupAddOutlined';
import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum } from 'kheops-graphql';
import { Member, currentChannelAtom, currentContractAtom, showChannelMembersAtom } from '../state';
import ChannelCompanyMemberList from './ChannelCompanyMemberList';
import useOpenable from '../../hooks/useOpenable';
import AddMemberDialog from './AddMemberDialog';

export interface ChannelMemberListProps {
  sx?: SxProps;
}

export default function ChannelMemberList({ sx }: ChannelMemberListProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'chat']);
  const currentChannel = useAtomValue(currentChannelAtom);
  const currentContract = useAtomValue(currentContractAtom);
  const setShowChannelMembers = useSetAtom(showChannelMembersAtom);
  const { close, open, isOpen } = useOpenable();

  const [buyingCompanyMembers, supplyingCompanyMembers] = useMemo(() => {
    const userInChannel = (user: Member): boolean => !!currentChannel?.members.some(({ userId }) => userId === user.id);

    return [
      currentContract?.buying_company.role_assignments.map(({ user }) => user).filter(userInChannel),
      currentContract?.supplying_company.role_assignments.map(({ user }) => user).filter(userInChannel),
    ];
  }, [currentContract, currentChannel?.lastMessage]);

  if (!currentChannel || !currentContract) {
    return <></>;
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxSizing: 'border-box', ...sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton color="secondary" onClick={() => setShowChannelMembers(false)} sx={{ width: 20, height: 20 }}>
            <Close sx={{ width: 18, height: 18 }} />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">
              {t('chat:members_of_conversation')}
            </Typography>
            <Typography variant="subtitle2">
              {t('chat:N_member', { count: currentChannel.memberCount })}
            </Typography>
          </Box>
        </Box>
        <IconButton color="secondary" onClick={open}>
          <GroupAddOutlined />
        </IconButton>
        {isOpen && (
          <AddMemberDialog
            channel={currentChannel}
            contract={currentContract}
            isOpen={isOpen}
            onClose={close}
          />
        )}
      </Box>
      <Box sx={{ mt: 2, overflow: 'auto' }}>
        <ChannelCompanyMemberList company={currentContract.buying_company} companyMembers={buyingCompanyMembers!} businessProfile={Business_Profile_Enum.Buyer} />
        <ChannelCompanyMemberList company={currentContract.supplying_company} companyMembers={supplyingCompanyMembers!} businessProfile={Business_Profile_Enum.Supplier} />
      </Box>
    </Card>
  );
}
