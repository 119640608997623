import { Box, Breakpoint, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlgoliaHit } from 'instantsearch.js';
import { useAtomValue } from 'jotai';
import { Contract_Status_Enum } from 'kheops-graphql';
import FileUtils, { PhotoSize } from '../../common/utils/file.utils';
import { SupplierHit } from '../search';
import { RoutePaths } from '../../routes/AppRoutes';
import ContractStatusTag from '../ContractStatusTag';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import SizedImage from '../../common/components/SizedImage';
import BrandBox from '../../common/components/BrandBox';
import CompanyDistanceChip from '../../common/components/CompanyDistanceChip';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import ImageDarkOverlay from '../../common/components/ImageDarkOverlay';
import SearchUtils from '../../common/utils/search.utils';
import { currentContextAtom } from '../../state';
import { PackagingFamilyTypeIntl } from '../../common/i18n/product-family.translation';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface SearchBuyerHitProps {
  hit: AlgoliaHit<SupplierHit>;
}

const imageSizeByBreakpoint: Record<Breakpoint, PhotoSize> = {
  xs: {
    height: 120,
    width: 120,
  },
  sm: {
    height: 156,
    width: 288,
  },
  md: {
    height: 156,
    width: 288,
  },
  lg: {
    height: 196,
    width: 288,
  },
  xl: {
    height: 196,
    width: 372,
  },
};

export default function SearchBuyerHit({ hit }: SearchBuyerHitProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery', 'product-family']);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { companyId, deliveryZones } = useAtomValue(currentContextAtom);
  const breakpoint = useCurrentBreakpoint();

  const canDeliver = useMemo(() => {
    if (!hit.partners.includes(`${companyId}_SIGNED`) && deliveryZones?.length) {
      const hitAdministrativeArea2 = hit.administrative_areas.find((administrativeArea) => administrativeArea.includes('administrative_area_level_2'))?.split('|')[1];

      return SearchUtils.canItDeliverAnArea(deliveryZones, hitAdministrativeArea2!);
    }

    return true;
  }, [hit]);

  const imageSize = useMemo(() => {
    return imageSizeByBreakpoint[breakpoint];
  }, [breakpoint]);

  const contractStatusBadge = useMemo(() => {
    const partnerInfo = hit.partners.find((partner) => partner.includes(companyId));

    if (!partnerInfo) {
      return undefined;
    }

    return (
      <ContractStatusTag status={partnerInfo.split('_')[1] as Contract_Status_Enum} />
    );
  }, []);

  const handleBuyerHitClick = useCallback((): void => {
    const routePath = RoutePaths.DISCOVERY_BUYER;

    navigate(
      generatePath(routePath, { companyId: hit.objectID }),
      {
        state: {
          origin: 'DISCOVERY',
        },
      },
    );
  }, []);

  return (
    <Box
      onClick={handleBuyerHitClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: {
          xs: 'row',
          sm: 'column',
        },
        mb: 3,
      }}
    >
      <SizedImage
        src={FileUtils.BuildCompanyImageUrl({ photo: hit.photo, type: hit.business_profile, size: imageSize })}
        alt={t('common:company')}
        height={imageSize.height!}
        width={imageSize.width!}
        sx={{
          flexShrink: 0,
          border: !hit.photo ? '1px solid' : 'unset',
          borderColor: 'divider',
          borderRadius: 6,
          '& .Kheops-brand': {
            width: {
              xs: 40,
              sm: 48,
            },
            height: {
              xs: 40,
              sm: 48,
            },
          },
          '& .Kheops-brandLogo': {
            width: {
              xs: 24,
              sm: 32,
            },
          },
        }}
        overlay={(
          <>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                py: 1.5,
                px: 1,
              }}
            >
              {!isXs && (
                <>
                  {/* _geoloc's type is wrong, it should also accept lon for longitude, as it is configured that way in our records. */}
                  <CompanyDistanceChip
                    targetGeoLoc={{ lat: hit._geoloc!.lat, lng: (hit._geoloc! as any).lon }}
                    iconSize={18}
                    sx={{ fontSize: '1rem' }}
                  />
                </>
              )}
              {hit.brand && (<BrandBox brand={hit.brand} />)}
            </Box>

            {!canDeliver && (
              <ImageDarkOverlay label={t(DiscoveryWordingsKeys.SUPPLIER.out_of_delivery_zones)} />
            )}
          </>
        )}
      />
      <Box
        sx={{
          mt: {
            xs: 0,
            sm: 1,
          },
          ml: {
            xs: 1,
            sm: 0,
          },
          minWidth: 0,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
            {contractStatusBadge}
            <Tooltip title={hit.tradeName} enterDelay={500} enterNextDelay={500}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  ml: contractStatusBadge ? 0.5 : 0,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {hit.tradeName}
              </Typography>
            </Tooltip>
          </Box>
          {!!hit.product_families?.length && (
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {t(`product-family:${PackagingFamilyTypeIntl[hit.product_families[0]]}`)}
              {hit.product_families.length > 1 && ` (+${hit.product_families.length - 1})`}
            </Typography>
          )}
        </Box>
        {isXs && (
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
            }}
          >
            <CompanyDistanceChip
              targetGeoLoc={{ lat: hit._geoloc!.lat, lng: (hit._geoloc! as any).lon }}
              iconSize={18}
              sx={{ fontSize: '1rem' }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
