import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import PackagingPricesDetails from '../../company/prices/PackagingPricesDetails';
import useOpenable from '../../hooks/useOpenable';
import { ContractConditions } from '../state';

export interface OpenPackagingPricesButtonProps {
  offerInfo: ContractConditions;
}

export default function OpenPackagingPricesButton({ offerInfo }: OpenPackagingPricesButtonProps): React.JSX.Element {
  const { open, isOpen, close } = useOpenable();

  if (!offerInfo.catalog_id) {
    return <></>;
  }

  return (
    <>
      <IconButton
        onClick={open}
        sx={{
          p: 1,
          ml: 1,
          backgroundColor: 'greys.primary',
          color: 'text.primary',
        }}
      >
        <InfoOutlinedIcon sx={{ height: 18, width: 18 }} />
      </IconButton>
      <PackagingPricesDetails
        companyId={offerInfo.supplyingCompanyId}
        catalogId={offerInfo.catalog_id}
        open={isOpen}
        onClose={close}
      />
    </>
  );
}
