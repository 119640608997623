import {
  Box,
  Card,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { RoutePaths } from '../routes/AppRoutes';
import { useCompanyByIdQuery } from '../queries/__generated__/companyById.generated';
import { PageWrapper } from '../common/components/PageWrapper';
import CompanyProductSearch from './CompanyProductSearch';
import { CompanyInfoCard } from './companyInfo/CompanyInfoCard';
import { currentBuyingCompanyIdAtom } from '../basket/state/state';
import ContractInfoCard from './ContractInfoCard';
import CompanyContacts from './CompanyContacts';
import BackBar from '../common/components/BackBar';
import { contractAtom, currentContextAtom, userAtom } from '../state';
import LastOrderRecommendation from './LastOrderRecommendation';

export default function CompanyPage(): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['contracts', 'common']);

  const context = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const { companyId } = useParams();
  const setCurrentBuyingCompanyId = useSetAtom(currentBuyingCompanyIdAtom);
  const [buyingCompanyId, supplyingCompanyId] = context.realm === Business_Profile_Enum.Buyer
    ? [context.companyId, companyId!]
    : [companyId!, context.companyId];
  const companyContractAtom = useMemo(() => {
    return contractAtom(buyingCompanyId!, supplyingCompanyId);
  }, [buyingCompanyId, supplyingCompanyId]);
  const contract = useAtomValue(companyContractAtom);

  const companyQuery = useCompanyByIdQuery({
    variables: {
      companyId: companyId!,
    },
    skip: !buyingCompanyId || !supplyingCompanyId || !userId,
  });
  const company = companyQuery.data?.company_by_pk;

  const productSearchCompanyId = useMemo(() => {
    return context.realm === Business_Profile_Enum.Buyer ? companyId! : context.companyId;
  }, [company]);

  const productListTitle = useMemo((): string => {
    let title = '';

    if (context) {
      if (context.realm === Business_Profile_Enum.Buyer) {
        title = t(contract?.status === Contract_Status_Enum.Signed ? 'contracts:place_an_order_to_this_supplier' : 'common:product_other');
      } else {
        title = t('contracts:suggest_an_order_to_this_buyer');
      }
    }

    return title;
  }, [contract]);

  const displayProducts = useMemo((): boolean => {
    return company?.business_profile === Business_Profile_Enum.Supplier || contract?.status === Contract_Status_Enum.Signed;
  }, [contract, company]);

  const displayCompanyContacts = useMemo((): boolean => {
    return context.realm === Business_Profile_Enum.Supplier && contract?.status !== Contract_Status_Enum.Signed;
  }, [contract]);

  useEffect(() => {
    if (context.realm === Business_Profile_Enum.Supplier && companyId) {
      setCurrentBuyingCompanyId(companyId);
    } else {
      setCurrentBuyingCompanyId(context.companyId);
    }
  }, [companyId]);

  return (
    <>
      <BackBar to={RoutePaths.DISCOVERY_BUYERS} />
      <PageWrapper>
        {!!company && (
          <>
            {contract?.status === Contract_Status_Enum.Signed ? (
              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={9} xl={10}>
                  <CompanyInfoCard company={company} contract={contract} />
                </Grid>
                <Grid item xs={12} md={3} xl={2} sx={{ pb: 2 }}>
                  <ContractInfoCard contract={contract} company={company} />
                </Grid>
              </Grid>
            ) : (
              <CompanyInfoCard company={company} contract={contract} />
            )}
            {contract && <LastOrderRecommendation contract={contract} />}
            {displayProducts && (
              <Card sx={{ [theme.breakpoints.up('sm')]: { p: 4 } }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {productListTitle}
                </Typography>
                <Box
                  sx={{
                    '& .MuiGrid-root.MuiGrid-item': {
                      [theme.breakpoints.down('sm')]: {
                        paddingLeft: 0,
                      },
                    },
                    '& .MuiPaper-root.MuiCard-root': {
                      [theme.breakpoints.down('sm')]: {
                        paddingLeft: 0,
                      },
                    },
                  }}
                >
                  <CompanyProductSearch companyId={productSearchCompanyId} contract={contract} />
                </Box>
              </Card>
            )}
            {displayCompanyContacts && (
              <CompanyContacts companyId={companyId!} contract={contract} />
            )}
          </>
        )}
      </PageWrapper>
    </>
  );
}
