import {
  Box,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { BaseUnitTypeIntlMap } from 'kheops-utils';
import { Base_Unit_Type_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ValidatorsUtils from '../../../common/utils/validators.utils';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import MeasurementUnitSelect from './inputs/MeasurementUnitSelect';
import UnitNetContentInput from './inputs/UnitNetContentInput';

export default function CSUForm({ index }: { index: number }): React.JSX.Element {
  const { control } = useFormContext<ProductPackagingsFormData>();
  const { t } = useTranslation(['common', 'packaging', 'products', 'validation']);

  const baseUnitValues = useMemo(() => {
    return (Object.keys(BaseUnitTypeIntlMap) as Base_Unit_Type_Enum[])
      .reduce((acc, baseUnitType) => {
        if (baseUnitType !== Base_Unit_Type_Enum.Bulk) {
          acc.push({
            baseUnitType,
            label: t(`packaging:${BaseUnitTypeIntlMap[baseUnitType]}`),
          });
        }

        return acc;
      }, [] as { baseUnitType: Base_Unit_Type_Enum, label: string }[])
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ baseUnitType, label }) => (
        <MenuItem value={baseUnitType} key={baseUnitType}>
          <ListItemText primary={label} />
        </MenuItem>
      ));
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
      <Typography variant="h6">
        {t('products:consumer_value_stores')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {t('common:quantity')}*
          </Typography>
          <Controller
            name={`productPackagings.${index}.csu.unit_quantity`}
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
              min: { value: 1, message: t('validation:this_value_should_be_higher_than_N', { value: 0 }) },
              validate: {
                decimal: (value) => !!value && (ValidatorsUtils.validateDecimal(value) || (t('validation:this_value_should_be_a_integer') as string)),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <OutlinedInput
                  name={field.name}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(parseInt(e.target.value, 10));
                  }}
                  error={!!fieldState.error}
                  fullWidth
                  inputProps={{
                    min: '1',
                  }}
                  type="number"
                  placeholder={t('products:quantity_placeholder')}
                  sx={{ height: 48 }}
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {t('products:container')}*
          </Typography>
          <Controller
            name={`productPackagings.${index}.csu.unit_type`}
            control={control}
            rules={{ required: t('validation:this_field_is_required') as string }}
            render={({ field, fieldState }) => (
              <>
                <Select
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  displayEmpty
                  fullWidth
                  renderValue={(selected: Base_Unit_Type_Enum | '') => (
                    !selected
                      ? <Typography sx={{ color: 'text.secondary' }}>{t('products:container_placeholder')}</Typography>
                      : t(`packaging:${BaseUnitTypeIntlMap[selected]}`)
                  )}
                  error={!!fieldState.error}
                  sx={{ height: 48 }}
                >
                  {baseUnitValues}
                </Select>
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {t('products:net_content')}*
          </Typography>
          <UnitNetContentInput name={`productPackagings.${index}.csu.unit_net_content`} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
            {t('products:measurement_unit')}*
          </Typography>
          <MeasurementUnitSelect name={`productPackagings.${index}.csu.content_measurement_unit`} />
        </Grid>
      </Grid>
      <Typography variant="subtitle2">
        {t('products:base_unit_settings_description')}
      </Typography>
      <Box>
        <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
          {t('common:barcode')}
        </Typography>
        <Controller
          name={`productPackagings.${index}.csu.gtin`}
          control={control}
          rules={{ validate: {
            gtin: (value) => !value || ValidatorsUtils.validateGtin(value) || (t('validation:this_barcode_is_not_valid') as string),
          } }}
          render={({ field, fieldState }) => (
            <>
              <OutlinedInput
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('products:barcode_placeholder')}
                fullWidth
                error={!!fieldState.error}
                sx={{ height: 48, minHeight: 48 }}
              />
              {fieldState.error
                && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
            </>
          )}
        />
      </Box>
    </Box>
  );
}
