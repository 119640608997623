import { useBlocker } from 'react-router-dom';
import { useEffect } from 'react';
import ConfirmDialog from '../common/components/ConfirmDialog';
import useOpenable from './useOpenable';

interface BlockerMessage {
  title: string;
  message: string | React.JSX.Element;
  confirmLabel: string;
  cancelLabel: string;
}

export function useNavBlocker(shouldBlock: boolean, blockerMessage?: BlockerMessage): React.JSX.Element {
  const blocker = useBlocker(shouldBlock);
  const { isOpen, open, close } = useOpenable();

  const handleDialog = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      blocker.proceed!();
    } else {
      blocker.reset!();
    }
    close();
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      open();
    }
  }, [blocker.state]);

  useEffect(() => {
    if (shouldBlock) {
      window.onbeforeunload = () => ('');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onbeforeunload = () => {};
    }
  }, [shouldBlock]);

  return (
    blockerMessage
      ? (
        <ConfirmDialog
          {...blockerMessage}
          open={isOpen}
          onClose={handleDialog}
        />
      )
      : <></>
  );
}
