import { Box, Button, Card, Dialog, dialogClasses, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Close from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import OrderHeader from './OrderHeader';
import OrderDeliveryDateForm from './OrderDeliveryDateForm';
import useOpenable from '../../hooks/useOpenable';
import OrderCreationInfo from './OrderCreationInfo';
import ReorderButton from './ReorderButton';
import { orderAtom, orderTerminationStatuses } from '../state/state';
import OrderSuggestionChip from './OrderSuggestionChip';
import OrderAdditionalInformation from './OrderAdditionalInformation';

export default function OrderInformation(): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const { open, close, isOpen } = useOpenable();
  const order = useAtomValue(orderAtom);

  const isOrderTerminated = useMemo(() => (
    orderTerminationStatuses.includes(order.status)
  ), [order]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        [theme.breakpoints.up('lg')]: {
          maxHeight: 'calc(100vh - 136px)',
          overflowY: 'auto',
          position: 'sticky',
          top: '96px',
          px: 0,
          pt: 0,
        },
      }}
    >
      <OrderHeader compact={isSmall} />
      <Box
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
          px: {
            xs: 0,
            lg: 2,
          },
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <ReorderButton
          variant={isOrderTerminated ? 'contained' : 'outlined'}
          orderReferenceId={order.reference_id}
          sx={{ width: '100%', height: 40 }}
        />
        <OrderCreationInfo />
        <OrderDeliveryDateForm />
        <OrderAdditionalInformation />
      </Box>
      {isSmall && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <ReorderButton orderReferenceId={order.reference_id} sx={{ width: '100%', height: 40 }} />
          <Button
            onClick={open}
            variant="outlined"
            startIcon={<InfoOutlinedIcon />}
          >
            {t('common:more_information')}
          </Button>
          <Dialog
            open={isOpen}
            onClose={close}
            PaperProps={{
              sx: {
                p: 2,
                m: 1,
                gap: 2,
                width: '100%',
                minHeight: 620,
                boxSizing: 'border-box',
              },
            }}
            sx={{
              [`& .${dialogClasses.container}`]: {
                alignItems: 'flex-end',
              },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {t('order:order_details')}
              </Typography>
              <IconButton
                onClick={close}
                sx={{
                  backgroundColor: 'greys.primary',
                  color: 'text.primary',
                  width: 36,
                  height: 36,
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {order.suggestor_id && <OrderSuggestionChip sx={{ width: 'fit-content' }} />}
              <OrderCreationInfo />
              <OrderDeliveryDateForm />
              <OrderAdditionalInformation compact />
            </Box>
          </Dialog>
        </Box>
      )}
    </Card>
  );
}
