import { Menu, styled, alpha, MenuProps } from '@mui/material';
import React from 'react';

function KheopsMenu({ children, ...menuProps }: MenuProps): React.JSX.Element {
  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoFocus={false}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 24,
      marginTop: theme.spacing(1),
      minWidth: 180,
      width: 238,
      boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.primary,
          marginRight: theme.spacing(1),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  return <StyledMenu {...menuProps}>{children}</StyledMenu>;
}

export default React.memo(KheopsMenu);
