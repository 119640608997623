import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BuyerInfoViewProps } from './BuyerInfoDialog';
import Address from '../address/Address';
import useResolvedAdditionalAddress from '../address/hook/useResolvedAdditionalAddress';
import CompanyContactList from '../../CompanyContactList';
import DeliveryHours from '../deliveryHours/DeliveryHours';

type CategoryName = 'address' | 'contact' | 'delivery_hours';

export default function BuyerInfoDeliveryView({ company, close, changeView, hideBackArrow }: BuyerInfoViewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const deliveryAddress = useResolvedAdditionalAddress({ companyName: company.tradeName, companyAddress: company.addresses[0], additionalAddress: company.delivery_address });
  const [currentExpandedAccordion, setCurrentExpandedAccordion] = useState<CategoryName | undefined>('address');

  const deliveryContacts = useMemo(() => {
    return company.role_assignments.filter(({ is_delivery_contact }) => is_delivery_contact).map(({ user }) => user);
  }, [company]);

  const toggleAccordion = (name: CategoryName): void => {
    setCurrentExpandedAccordion(currentExpandedAccordion === name ? undefined : name);
  };

  return (
    <DialogContent sx={{ maxWidth: 500, height: 620, p: 2, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="secondary"
            onClick={() => changeView('GENERAL')}
            sx={{
              backgroundColor: 'greys.primary',
              display: hideBackArrow ? 'none' : undefined,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <LocalShippingOutlinedIcon sx={{ mx: 1 }} />
          <Typography variant="h6" sx={{ flex: 1 }}>
            {t('common:delivery')}
          </Typography>
          <IconButton color="secondary" onClick={close} sx={{ backgroundColor: 'greys.primary' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="subtitle2">
          {t('contracts:buyer_delivery_information')}
        </Typography>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          '& > *:not(:last-child)': {
            borderBottom: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        <Accordion
          disableGutters
          elevation={0}
          expanded={currentExpandedAccordion === 'address'}
          onChange={() => toggleAccordion('address')}
        >
          <AccordionSummary sx={{ lineHeight: '2rem' }} expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}>
            📍 {t('common:address')}
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Address
              address={deliveryAddress}
              additionalInfoTitle={t('settings:delivery_instructions')}
              additionalInfo={company.delivery_instructions}
              email={company.delivery_email}
              phoneNumber={company.delivery_phone_number}
              hidePhone
              enableMapLink
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          expanded={currentExpandedAccordion === 'contact'}
          onChange={() => toggleAccordion('contact')}
        >
          <AccordionSummary sx={{ lineHeight: '2rem' }} expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}>
            👤 {t('common:contacts')}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {deliveryContacts.length
              ? (
                <CompanyContactList contacts={deliveryContacts} displayContactInfo />
              ) : (
                <Typography variant="subtitle2" sx={{ px: 2, pt: 1, pb: 2 }}>
                  {t('contracts:no_delivery_contact_registered')}
                </Typography>
              )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          expanded={currentExpandedAccordion === 'delivery_hours'}
          onChange={() => toggleAccordion('delivery_hours')}
        >
          <AccordionSummary sx={{ lineHeight: '2rem' }} expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}>
            ⏰ {t('contracts:reception_hours')}
          </AccordionSummary>
          <AccordionDetails>
            {company.delivery_hours?.length
              ? (
                <DeliveryHours deliveryHours={company.delivery_hours} deliveryDescription={company.delivery_description} />
              ) : (
                <Typography variant="subtitle2">
                  {t('settings:no_delivery_hours')}
                </Typography>
              )}
          </AccordionDetails>
        </Accordion>
        <Typography variant="body1" sx={{ p: 2 }}>
          📞 {company.delivery_phone_number || '-'}
        </Typography>
        <Typography variant="body1" sx={{ p: 2 }}>
          ✉️ {company.delivery_email || '-'}
        </Typography>
      </Box>
    </DialogContent>
  );
}
