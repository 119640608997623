import { Chip, SxProps } from '@mui/material';
import { Business_Profile_Enum } from 'kheops-graphql';
import { Order_Status_Enum } from 'kheops-graphql/packages/graphql/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { OrderStatusTypeIntl, OrderStatusTypeShortIntl } from '../../common/i18n/order-status.translation';
import { contextToActionNeededStatusMap } from '../state/state';
import { currentContextAtom } from '../../state';

interface OrderStatusChipProps {
  status: Order_Status_Enum;
  short?: boolean;
  sx?: SxProps;
}

export default function OrderStatusChip({ status, short, sx }: OrderStatusChipProps): React.JSX.Element {
  const { t } = useTranslation('order-status');
  const { realm } = useAtomValue(currentContextAtom);
  const orderStatusFormated = useMemo(() => {
    const statusToMap = status === Order_Status_Enum.OrderToBePreparedBySupplier && realm === Business_Profile_Enum.Buyer
      ? Order_Status_Enum.OrderToBeDeliveredBySupplier
      : status;

    return t(short ? OrderStatusTypeShortIntl[statusToMap] : OrderStatusTypeIntl[realm][statusToMap]);
  }, [status, short]);

  const isActionNeededByUser = useMemo(() => (
    contextToActionNeededStatusMap
      .get(realm)!
      .includes(status)

  ), [status]);

  return (
    <Chip
      color={isActionNeededByUser ? 'primary' : undefined}
      label={orderStatusFormated}
      size="small"
      sx={{
        ...sx,
        backgroundColor: isActionNeededByUser ? undefined : 'secondary.light',
      }}
    />
  );
}
