import { Product_Label_Name_Enum } from 'kheops-graphql';
import AgricultureBioImage from '../assets/images/label-agriculture-bio.png';
import AOPImage from '../assets/images/label-aop.png';
import GoutezArdecheImage from '../assets/images/label-goutez-ardeche.png';
import IGPImage from '../assets/images/label-igp.png';
import LabelRougeImage from '../assets/images/label-label-rouge.png';
import NatureProgresImage from '../assets/images/label-nature-progres.png';
import { LabelDisplay } from '../orders/state/state';
import ProduitIleDeFranceImage from '../assets/images/label-produit-ile-de-france.png';
import ValeursParcNaturelRegionalImage from '../assets/images/label-valeurs-parc-naturel-regional.png';
import AppellationOrigineControleeImage from '../assets/images/label-appellation-origine-controlee.png';
import EcocertEcodetergentImage from '../assets/images/label-ecocert-ecodetergent.png';
import HauteValeurEnvironnementalImage from '../assets/images/label-haute-valeur-environnemental.png';
import OrigineFranceGarantieImage from '../assets/images/label-origine-france-garantie.png';
import SaveursDeNormandieImage from '../assets/images/label-saveurs-de-normandie.png';
import LePorcFrancaisImage from '../assets/images/label-le-porc-francais.png';

// Packaging display rules detailed here
// https://www.notion.so/kheops/Packaging-display-rules-c6112933427a449795bcd0d0f5a7e4e7

export interface LabelImage {
  src: string;
  alt: string;
}

export const logoLabels: { [key in Product_Label_Name_Enum]: LabelImage } = {
  AGRICULTURE_BIOLOGIQUE: {
    src: AgricultureBioImage,
    alt: 'Agriculture Biologique',
  },
  APPELLATION_ORIGINE_PROTEGEE: {
    src: AOPImage,
    alt: "Appellation d'origine protégée",
  },
  GOUTEZ_ARDECHE: {
    src: GoutezArdecheImage,
    alt: "Goutez l'Ardèche",
  },
  NATURE_ET_PROGRES: {
    src: NatureProgresImage,
    alt: 'Nature & Progrès',
  },
  LABEL_ROUGE: {
    src: LabelRougeImage,
    alt: 'Label Rouge',
  },
  INDICATION_GEOGRAPHIQUE_PROTEGEE: {
    src: IGPImage,
    alt: 'Indication géographique protégée',
  },
  PRODUIT_ILE_DE_FRANCE: {
    src: ProduitIleDeFranceImage,
    alt: 'Produit Île de France',
  },
  APPELLATION_ORIGINE_CONTROLEE: {
    src: AppellationOrigineControleeImage,
    alt: 'Appellation origine controlée',
  },
  VALEURS_PARC_NATUREL_REGIONAL: {
    src: ValeursParcNaturelRegionalImage,
    alt: 'Valeurs parc naturel regional',
  },
  ECOCERT_ECODETERGENT: {
    src: EcocertEcodetergentImage,
    alt: 'Ecocert ecodetergent',
  },
  HAUTE_VALEUR_ENVIRONNEMENTALE: {
    src: HauteValeurEnvironnementalImage,
    alt: 'Haute Valeur Environnementale',
  },
  ORIGINE_FRANCE_GARANTIE: {
    src: OrigineFranceGarantieImage,
    alt: 'Origine France Garantie',
  },
  SAVEURS_DE_NORMANDIE: {
    src: SaveursDeNormandieImage,
    alt: 'Saveurs de Normandie',
  },
  LE_PORC_FRANCAIS: {
    src: LePorcFrancaisImage,
    alt: 'Le Porc Français',
  },
};

export function FormatPackagingLabels(labels: LabelDisplay[]): LabelImage[] {
  return labels.map(({ label }) => logoLabels[label as Product_Label_Name_Enum]);
}
