import { atom } from 'jotai';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';

interface ProductFilters {
  productFamilies: Product_Sub_Family_Name_Enum[];
  query: string;
  page: number;
}

export const productFiltersAtom = atom<ProductFilters>({
  productFamilies: [],
  query: '',
  page: 1,
});
