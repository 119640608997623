import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Chip, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import i18n from 'i18next';
import { useSetAtom } from 'jotai';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QuantitySelector from '../common/components/quantitySelector/QuantitySelector';
import FileUtils from '../common/utils/file.utils';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { BasketItem } from './state/basket';
import { currentBasketItemsAtom } from './state/state';
import useBasketItem from '../hooks/useBasketItem';

export enum ItemSummarySizeOption {
  SMALL = 'small',
  MEDIUM = 'medium',
}

interface ItemSummarySize {
  image: number;
  priceVariant: Variant;
  priceSx: SxProps;
  nameSx?: SxProps;
}

type ItemSummarySizes = {
  [key in ItemSummarySizeOption]: ItemSummarySize;
}

const PACKAGING_QUANTITY_SUMMARY_SIZES: ItemSummarySizes = {
  [ItemSummarySizeOption.SMALL]: {
    image: 56,
    priceVariant: 'subtitle1',
    priceSx: {
      fontWeight: 600,
    },
    nameSx: {
      fontWeight: 600,
      lineHeight: 'normal',
    },
  },
  [ItemSummarySizeOption.MEDIUM]: {
    image: 56,
    priceVariant: 'subtitle1',
    priceSx: {
      fontWeight: 700,
    },
    nameSx: {
      fontWeight: 600,
      lineHeight: 'normal',
    },
  },
};

export interface ItemSummaryProps {
  item: BasketItem;
  editable?: boolean;
  size?: ItemSummarySizeOption;
}

function BasketItemSummary({ item, editable, size = ItemSummarySizeOption.MEDIUM }: ItemSummaryProps): React.JSX.Element {
  const { t } = useTranslation('common');
  const currencyFormat = useCurrencyFormat();
  const currentItem = useBasketItem(`${item.packaging.id}_${item.contract_id}`)?.quantity || 0;
  const editBasketItem = useSetAtom(currentBasketItemsAtom);
  const packagingPhotoUrl = FileUtils.BuildPackagingImageUrl({
    photo: item.packaging.base_unit?.main_photo,
    type: item.packaging.product!.sub_family,
    size: { height: 112 },
  });
  const totalPrice = currentItem * item.packaging.price;
  const numberFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { minimumFractionDigits: 0, maximumFractionDigits: 2 });

  const packagingDetails = useMemo(() => {
    return {
      name: item.packaging.product!.name,
      baseUnit: FormatBaseUnitQuantity(item.packaging.base_unit!, 'fr', numberFormatter),
      logisticalPackaging: FormatLogisticalPackaging(item.packaging, 'fr', item.packaging.base_unit!),
    };
  }, [item]);

  const handleChange = (newQuantity: number): void => {
    editBasketItem({
      id: item.id,
      packagingId: item.packaging.id,
      quantity: newQuantity,
      contractId: item.contract_id,
    });
  };

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            height: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
            width: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
            textAlign: 'center',
          }}
        >
          <Box
            component="img"
            src={packagingPhotoUrl}
            alt={t('common:packaging_one')}
            sx={{
              maxWidth: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
              maxHeight: PACKAGING_QUANTITY_SUMMARY_SIZES[size].image,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, width: '100%' }}>
        <Tooltip title={packagingDetails.name} placement="top-start" arrow>
          <Typography variant="subtitle1" sx={PACKAGING_QUANTITY_SUMMARY_SIZES[size].nameSx}>
            {packagingDetails.name}
          </Typography>
        </Tooltip>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', mt: 0.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Chip
              sx={{
                backgroundColor: 'secondary.light',
              }}
              size="small"
              label={packagingDetails.baseUnit}
            />

            <Typography variant="subtitle2" sx={{ ml: 0.5, mt: 0.25 }}>
              {packagingDetails.logisticalPackaging}
            </Typography>
          </Box>
          {editable
            ? (
              <Box sx={{ marginLeft: 'auto' }}>
                <IconButton size="small" onClick={() => handleChange(0)} sx={{ paddingTop: 0 }}>
                  <DeleteOutlineIcon fontSize="inherit" color="secondary" />
                </IconButton>
              </Box>
            )
            : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <Typography variant={PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceVariant} sx={PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceSx}>
                  {currencyFormat.format(totalPrice)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    height: 24,
                    width: 65,
                    gap: 0.5,
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle2">
                    {`${t('basket:abbreviated_quantity')} :`}
                  </Typography>
                  <QuantitySelector
                    inputSx={{
                      fontSize: 14,
                    }}
                    sx={{
                      mx: 0,
                    }}
                    quantity={currentItem}
                    onQuantityChange={handleChange}
                    shouldDisplayButtons={false}
                  />
                </Box>
              </Box>
            )}
        </Box>
        {editable && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography
              variant={PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceVariant}
              sx={{
                ...PACKAGING_QUANTITY_SUMMARY_SIZES[size].priceSx,
                mr: 1,
              }}
            >
              {currencyFormat.format(totalPrice)}
            </Typography>
            <QuantitySelector quantity={currentItem} onQuantityChange={handleChange} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(BasketItemSummary);
