import { Box, Button, FormControlLabel, FormHelperText, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import GeolocIcon from '../../assets/icons/geoloc.svg?react';
import { useAdmnistrativeAreasQuery } from '../../queries/__generated__/administrativeAreas.generated';
import CheckboxAutocomplete from '../../common/components/CheckboxAutocomplete';
import { DeliveryZonesForm } from './DeliveryZoneSettings';

interface DeliveryZoneSettingProps {
  index: number;
  deletable: boolean;
  deleteZone: () => void;
  standAlone?: boolean;
}

type AreaOption = 'all' | 'administrativeAreas';

export function DeliveryZoneSetting({ index, deletable, deleteZone, standAlone }: DeliveryZoneSettingProps): React.JSX.Element {
  const { t } = useTranslation(['settings', 'common', 'validation']);
  const { data } = useAdmnistrativeAreasQuery();
  const { control, watch } = useFormContext<DeliveryZonesForm>();
  const { field: areasField } = useController({
    name: `deliveryZones.${index}.areas`,
    control,
    rules: {
      validate: {
        required: (value) => !!value.length,
      },
    },
  });
  const [areaOption, setAreaOption] = useState<AreaOption>(areasField.value.includes('ALL_AREAS') ? 'all' : 'administrativeAreas');
  const deliveryZones = watch('deliveryZones');
  const isAllAreaSelected = deliveryZones.some(({ areas }) => areas[0] === 'ALL_AREAS');

  const administrativeAreaList = useMemo(() => {
    if (data?.administrative_area) {
      const selectedAreas = deliveryZones.reduce<string[]>((acc, current, currentIndex) => {
        if (currentIndex !== index) {
          acc.push(...current.areas);
        }

        return acc;
      }, []);

      return data.administrative_area.map(({ area }) => area).filter((area) => !selectedAreas.includes(area));
    }

    return [];
  }, [data, deliveryZones.map((zone) => zone.areas.join(''))]);

  const selectedAreas = useMemo(() => {
    return areasField.value.filter(
      (area: string) => area !== 'ALL_AREAS',
    );
  }, [areasField.value]);

  useEffect(() => {
    let areasToChange: string[] | undefined;

    if (areaOption === 'all') {
      areasToChange = ['ALL_AREAS'];
    } else if (areasField.value.includes('ALL_AREAS')) {
      areasToChange = [];
    }

    if (areasToChange) {
      areasField.onChange(areasToChange);
    }
  }, [areaOption]);

  return (
    <Box
      sx={{
        width: {
          sm: 400,
        },
        margin: 'auto',
        pt: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', height: 40 }}>
        <GeolocIcon />
        <Typography variant="h5" sx={{ flex: 1, ml: 1 }}>
          {t('settings:zone', { index: index + 1 })}
        </Typography>
        {!standAlone && (
          <Button
            onClick={deleteZone}
            color="error"
            variant="outlined"
            disabled={!deletable}
            startIcon={<DeleteIcon />}
          >
            {t('common:delete')}
          </Button>
        )}
      </Box>
      <Box>
        <Typography variant="h6">
          {t('common:minimum_order_value')}
        </Typography>
        <Controller
          name={`deliveryZones.${index}.mov`}
          control={control}
          rules={{
            required: t('validation:this_field_is_required') as string,
            min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
          }}
          render={({ field, fieldState }) => (
            <>
              <OutlinedInput
                {...field}
                sx={{
                  height: 40,
                  width: '100%',
                }}
                type="number"
                inputProps={{
                  min: 0,
                  sx: {
                    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                  },
                }}
                endAdornment="€"
                placeholder={t('settings:define_minimum_order_value')}
              />
              {!!fieldState.error && (
                <FormHelperText sx={{ ml: 2 }} error>{fieldState.error.message}</FormHelperText>
              )}
            </>
          )}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">
          {t('settings:bound_areas')}
        </Typography>
        <RadioGroup value={areaOption} onChange={(event) => setAreaOption(event.target.value as AreaOption)}>
          <FormControlLabel value="administrativeAreas" control={<Radio />} label={t('settings:by_area')} />
          <CheckboxAutocomplete
            onChange={areasField.onChange}
            values={selectedAreas}
            disabled={areaOption === 'all'}
            sx={{
              width: '100%',
            }}
            InputProps={{
              startAdornment: (
                <SearchIcon fontSize="small" sx={{ mr: 1 }} />
              ),
            }}
            label={t('settings:define_area_in_this_zone')}
            options={administrativeAreaList}
          />
          <FormControlLabel
            value="all"
            control={<Radio />}
            disabled={isAllAreaSelected && areasField.value[0] !== 'ALL_AREAS'}
            label={t(deliveryZones.length > 1 ? 'settings:rest_of_france' : 'settings:all_france')}
            sx={{
              pt: 2,
              pb: 3,
            }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
}
