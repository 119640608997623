import { Box, Chip, Divider, IconButton, MenuItem, Select, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, getWeek, setDate, setDay } from 'date-fns';
import { fr } from 'date-fns/locale/fr';
import { convertSqlTimeToDate, getFormattedHour } from 'kheops-utils';
import { useGetCompanyDeliveryHoursInfoQuery } from '../../queries/__generated__/getCompanyDeliveryHoursInfo.generated';
import { isDeliveryDay } from '../utils/date.utils';

export interface DeliveryDatePickerProps {
  companyId: string;
  value?: Date | null;
  onChange?: (date: Date | null) => void;
}

interface WeekOption {
  date: Date;
  label: string;
}

export default function DeliveryDatePicker({ companyId, value, onChange }: DeliveryDatePickerProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);
  const { data } = useGetCompanyDeliveryHoursInfoQuery({
    variables: {
      companyId,
    },
  });
  const [weekIndex, setWeekIndex] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(value);

  const weeks = useMemo((): WeekOption[] => {
    const results: WeekOption[] = [];
    const firstDay = new Date();
    let firstOptionLabel = `${t('common:week')} ${getWeek(firstDay, { weekStartsOn: 1 })}`;

    if (firstDay.getDay() !== 1) {
      firstOptionLabel += `-${getWeek(firstDay, { weekStartsOn: 1 }) + 1}`;
    }

    results.push({ date: firstDay, label: firstOptionLabel });

    for (let i = 0; i < 51; i++) {
      const monday = setDay(results[i].date, 1, { weekStartsOn: 1 });
      const nextMonday = setDate(monday, monday.getDate() + 7);

      results.push({
        date: nextMonday,
        label: `${t('common:week')} ${getWeek(nextMonday)}`,
      });
    }

    return results;
  }, []);

  const isSelectedDeliveryDay = useMemo((): boolean => {
    if (!selectedDate) {
      return false;
    }

    return isDeliveryDay(selectedDate.getDay(), data?.company_by_pk?.delivery_hours);
  }, [data, selectedDate]);

  const handleSelectChange = (newDate: Date): void => {
    if (onChange) {
      onChange(newDate);
      setSelectedDate(newDate);
    }
  };

  const days = useMemo((): Date[] => {
    const results: Date[] = [weeks[weekIndex].date];

    for (let i = 0; i < 6; i++) {
      const nextDay = new Date(results[results.length - 1]);

      nextDay.setDate(nextDay.getDate() + 1);
      results.push(nextDay);
    }

    return results;
  }, [weeks, weekIndex]);

  useEffect((): void => {
    if (weeks?.length && value) {
      const today = new Date();
      const initialDeliveryDate = new Date(value);

      today.setHours(0, 0, 0);
      initialDeliveryDate.setHours(0, 0, 0);

      setWeekIndex(
        initialDeliveryDate < today ? 0 : getWeek(value, { weekStartsOn: 1 }) - getWeek(new Date(), { weekStartsOn: 1 }),
      );
    }
  }, [weeks]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Select
          value={weekIndex}
          onChange={(event) => setWeekIndex(event.target.value as number)}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarTodayOutlinedIcon />
              {weeks[selected].label}
            </Box>
          )}
          sx={{
            height: 48,
            width: 232,
            borderRadius: 3,
          }}
          MenuProps={{
            sx: {
              mt: 1,
              height: 372,
              '& .MuiPaper-root': {
                borderRadius: 3,
              },
              '& .MuiList-root': {
                p: 2,
              },
              '& .MuiTypography-root': {
                lineHeight: 'normal',
              },
              '& .MuiMenuItem-root': {
                width: 200,
                borderRadius: 4,
                height: 56,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
              },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: 'rgba(16, 45, 45, 0.12)',
              },
            },
          }}
        >
          {weeks.map(({ date, label }, index) => {
            const options = [(
              <MenuItem key={date.getTime()} value={index}>
                <Typography variant="body1">
                  {label}
                </Typography>
                <Typography variant="subtitle2">
                  {`${format(date, 'd MMM', { locale: fr })} - ${format(setDate(date, date.getDate() + 6), 'd MMM', { locale: fr })}`}
                </Typography>
              </MenuItem>
            )];

            if (label === `${t('common:week')} 1`) {
              const yearDivider = (
                <MenuItem
                  sx={{
                    maxHeight: 36,
                    mt: 2,
                    py: 0,
                    '&.Mui-disabled': { opacity: 1 },
                  }}
                  disabled
                  value=""
                >
                  <Divider flexItem />
                  <Typography variant="subtitle2" sx={{ pl: 2, pt: 1.5, pb: 1 }}>
                    {new Date().getFullYear() + 1}
                  </Typography>
                </MenuItem>
              );

              options.unshift(yearDivider);
            }

            return options.flat();
          })}
        </Select>
        <IconButton
          disabled={weekIndex === 0}
          onClick={() => setWeekIndex(weekIndex - 1)}
          color="secondary"
          sx={{
            '&:disabled': {
              backgroundColor: 'secondary.light',
            },
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          disabled={weekIndex === weeks.length - 1}
          onClick={() => setWeekIndex(weekIndex + 1)}
          color="secondary"
          sx={{
            '&:disabled': {
              backgroundColor: 'secondary.light',
            },
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {days.map((day) => (
          <Box
            className={day.toDateString() === selectedDate?.toDateString() ? 'selected' : ''}
            key={day.toString()}
            sx={{
              width: 75,
              height: 48,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 0.5,
              px: 2,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              '& .MuiTypography-root': {
                lineHeight: 'normal',
              },
              '&.selected': {
                borderWidth: 2,
                borderColor: 'common.black',
              },
              '&:hover': {
                backgroundColor: 'rgba(33, 20, 3, 0.08)',
              },
              position: 'relative',
            }}
            onClick={() => handleSelectChange(day)}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
              {format(day, 'EEE', { locale: fr })}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem' }}>
              {format(day, 'd MMM', { locale: fr })}
            </Typography>
            {isDeliveryDay(day.getDay(), data?.company_by_pk?.delivery_hours) && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  backgroundColor: 'primary.main',
                  width: 6,
                  height: 6,
                  borderRadius: '100%',
                }}
              />
            )}
          </Box>
        ))}
      </Box>
      {isSelectedDeliveryDay && (
        <Chip
          size="small"
          icon={<ScheduleOutlinedIcon />}
          label={
            t(
              'order:open_from_to',
              {
                openingHours: getFormattedHour(convertSqlTimeToDate(data!.company_by_pk!.delivery_hours[0].opening_hour)),
                closingHours: getFormattedHour(convertSqlTimeToDate(data!.company_by_pk!.delivery_hours[0].closing_hour)),
              },
            )
          }
          sx={{
            alignSelf: 'start',
            backgroundColor: '#D3F9E2',
          }}
        />
      )}
      <Box sx={{ borderRadius: 2, backgroundColor: 'secondary.light', p: 1 }}>
        <Typography variant="subtitle2">
          {t('settings:additional_info')}
        </Typography>
        <Typography variant="subtitle1">
          {data?.company_by_pk?.delivery_description || '-'}
        </Typography>
      </Box>
    </Box>
  );
}
