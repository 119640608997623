import { Drawer } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import Basket from './Basket';
import { isBasketDrawerOpenAtom } from './state/state';

function BasketSummaryDrawer(): React.JSX.Element {
  const [isBasketDrawerOpen, setIsBasketDrawerOpen] = useAtom(isBasketDrawerOpenAtom);

  return (
    <Drawer
      open={isBasketDrawerOpen}
      anchor="right"
      onClose={() => setIsBasketDrawerOpen(false)}
      sx={{
        '& > .MuiPaper-root': {
          width: {
            xs: '90%',
            sm: 464,
          },
        },
      }}
    >
      <Basket isDrawer />
    </Drawer>
  );
}

export default React.memo(BasketSummaryDrawer);
