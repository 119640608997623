import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { AlgoliaHit } from 'instantsearch.js';
import { Business_Profile_Enum } from 'kheops-graphql';
import { RoutePaths } from '../../routes/AppRoutes';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { ellipsisStyle } from '../../common/utils/style.utils';

export interface SupplierSlideProps {
  hit: AlgoliaHit;
}

export default function SupplierSlide({ hit }: SupplierSlideProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <Box
      component={Link}
      to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: hit.objectID })}
      sx={{
        backgroundColor: 'white',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        pr: 2,
        gap: 2,
        width: 208,
        flexShrink: 0,
        textDecoration: 'none',
        overflow: 'hidden',
      }}
    >
      <SizedImage
        height={{
          xs: 96,
          lg: 104,
        }}
        width={{
          xs: 96,
          lg: 104,
        }}
        src={
          FileUtils.BuildCompanyImageUrl({
            photo: hit.photo,
            size: { height: 104, width: 104 },
            type: Business_Profile_Enum.Supplier,
          })
        }
        alt={t('common:company')}
        borderRadius={0}
      />
      <Typography
        variant="body1"
        sx={{
          ...ellipsisStyle,
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          whiteSpace: 'wrap',
        }}
      >
        {hit.tradeName}
      </Typography>
    </Box>
  );
}
