/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import axios, { AxiosResponse } from 'axios';
import { createClient } from 'graphql-ws';
import { useSetAtom } from 'jotai';
import React from 'react';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';
import { AUTH_TOKEN, AuthToken } from '../common/models/auth.model';
import { dispatchAuthStateAtom } from '../common/state/state';
import { Analytics, FORCED_LOGOUT } from '../common/utils/analytics';
import { AuthActionType } from '../hooks/useAuth/authActionsTypes';
import i18n from '../i18n';
import { RoutePaths } from '../routes/AppRoutes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestContext = Record<string, any>;
type RequestCallback = (context: RequestContext) => unknown;

const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // eslint-disable-next-line no-console
      console.error(message, { operation });

      Sentry.captureException(
        new Error(message),
        {
          extra: {
            variables: operation.variables,
            operationName: operation.operationName,
          },
        },
      );
    });
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);

    Sentry.captureException(new Error(networkError.message));
  }
});

const networkErrorsRetryStrategyLink = new RetryLink({
  attempts: {
    max: 3,
  },
});

interface Definintion {
  kind: string;
  operation?: string;
}

function fetchRefreshToken(): Promise<AxiosResponse<AuthToken>> {
  const accessToken = localStorage.getItem(AUTH_TOKEN);

  if (!accessToken) {
    throw new Error('No access token');
  }

  const parsedAccessToken = JSON.parse(accessToken);

  return axios.post<AuthToken>('/auth/refreshToken', { refresh_token: parsedAccessToken.refresh_token });
}

let isRefreshing = false;

// We save in this array all requests that failed due to jwt
// All failed queries will be retried after the refreshToken mutation succeed
let pendingRequests: RequestCallback[] = [];

const resolvePendingRequests = (context: RequestContext): void => {
  pendingRequests.map((callback) => callback(context));
  pendingRequests = [];
};

export const buildApolloLink = (logout: () => void): ApolloLink => {
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (!token) {
      return { headers };
    }
    const accessToken: AuthToken = JSON.parse(token);

    return {
      headers: {
        ...headers,
        'x-custom-lang': i18n.resolvedLanguage,
        authorization: accessToken?.access_token
          ? `Bearer ${accessToken.access_token}`
          : '',
      },
    };
  });
  const wsLink = new GraphQLWsLink(createClient({
    url: import.meta.env.REACT_APP_KHEOPS_GQL_WEBSOCKET_URL,
    connectionParams: () => {
      const token = localStorage.getItem(AUTH_TOKEN);
      if (!token) {
        return undefined;
      }
      const accessToken: AuthToken = JSON.parse(token);

      return {
        headers: {
          authorization: accessToken?.access_token
            ? `Bearer ${accessToken.access_token}`
            : '',
        },
      };
    },
  }));

  const refreshTokenLink = onError(({ graphQLErrors, operation, forward }) => {
    if (
      graphQLErrors !== undefined
      && graphQLErrors.length > 0
      && (graphQLErrors[0].message
        === 'Missing Authorization header in JWT authentication mode'
        || graphQLErrors[0].message.includes('Could not verify JWT'))
    ) {
      let forward$: Observable<void | RequestContext>;
      if (!isRefreshing) {
        isRefreshing = true;
        forward$ = fromPromise(
          fetchRefreshToken()
            .then(({ data }) => {
              const context = (): RequestContext => ({
                headers: { authorization: `Bearer ${data.access_token}` },
              });
              // Update user accessToken and refreshToken if needed
              localStorage.setItem(AUTH_TOKEN, JSON.stringify(data));
              // Retry failed request
              resolvePendingRequests(context);
              return context;
            })
            .catch(() => {
              pendingRequests = [];
              Analytics.track(FORCED_LOGOUT);
              logout();
            })
            .finally(() => {
              isRefreshing = false;
            }),
        ).filter((value) => !!value);
      } else {
        forward$ = fromPromise(
          new Promise<RequestContext>((resolve) => {
            // @ts-ignore
            pendingRequests.push((context) => resolve(context));
          }),
        );
      }

      return forward$.flatMap((context: RequestContext | void) => {
        if (context) {
          operation.setContext(context);
        }

        return forward(operation);
      });
    }
  });
  const link = split(
    ({ query }) => {
      const { kind, operation }: Definintion = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    authLink.concat(refreshTokenLink).concat(
      new HttpLink({ uri: import.meta.env.REACT_APP_KHEOPS_GQL_URL }),
    ),
  );
  return from([errorLink, networkErrorsRetryStrategyLink, link]);
};

type Props = {
  children: React.ReactNode;
};
export function CustomApolloProvider({ children }: Props): React.JSX.Element {
  const dispatch = useSetAtom(dispatchAuthStateAtom);
  const logout = (): void => {
    localStorage.removeItem(AUTH_TOKEN);
    dispatch({ type: AuthActionType.LOGOUT });

    window.history.pushState({}, '', RoutePaths.ROOT);
  };
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        order_packaging_quantity: {
          keyFields: ['orderId', 'packagingId'],
        },
      },
    }),
    link: buildApolloLink(logout),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
