import { Box, Chip, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BulbImage from '../../assets/images/bulb.png';
import BlackBackgroundTooltip from '../../common/components/BlackBackgroundTooltip';

interface OrderSuggestionChipProps {
  sx?: SxProps;
  short?: boolean;
}

export default function OrderSuggestionChip({ sx, short }: OrderSuggestionChipProps): React.JSX.Element {
  const { t } = useTranslation(['order']);

  return (
    <BlackBackgroundTooltip disableHoverListener={!short} title={t('order:supplier_suggestion')} arrow>
      <Chip
        icon={(
          <Box component="img" src={BulbImage} />
        )}
        label={short ? '' : t('order:supplier_suggestion')}
        size="small"
        sx={{
          p: 0.5,
          backgroundColor: 'background.default',
          color: 'common.black',
          '& .MuiChip-icon': {
            ml: 0,
            mr: short ? 0 : 0.5,
            width: 12,
          },
          '& .MuiChip-label': {
            p: 0,
            pr: short ? 0 : 1,
          },
          ...sx,
        }}
      />
    </BlackBackgroundTooltip>
  );
}
