import { Box, Button, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { Delivery_Hours, Maybe } from 'kheops-graphql';
import { convertSqlTimeToDate, getFormattedHour } from 'kheops-utils';
import { deliveryHoursDrawerOpenAtom, WeekDays } from '../state';

export interface DeliveryHoursProps {
  deliveryHours: Pick<Delivery_Hours, 'week_day' | 'opening_hour' | 'closing_hour'>[];
  deliveryDescription?: Maybe<string>;
  enableEdit?: boolean;
}

export default function DeliveryHours({ deliveryHours, deliveryDescription, enableEdit }: DeliveryHoursProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'date', 'settings']);
  const setDeliveryHoursDrawerOpenAtom = useSetAtom(deliveryHoursDrawerOpenAtom);

  const getDeliveryHoursDisplay = (deliveryHour: Pick<Delivery_Hours, 'opening_hour' | 'closing_hour'>): string => {
    const openingHour = convertSqlTimeToDate(deliveryHour.opening_hour);
    const closingHour = convertSqlTimeToDate(deliveryHour.closing_hour);

    return `${getFormattedHour(openingHour)}-${getFormattedHour(closingHour)}`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Object.keys(WeekDays).map((weekDay) => {
        const deliveryHour = deliveryHours!.find(({ week_day }) => week_day === weekDay);

        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} key={weekDay}>
            <Typography variant="body1">
              {t(`date:${weekDay.toLowerCase()}`)}
            </Typography>
            <Typography variant="body1">
              {deliveryHour ? getDeliveryHoursDisplay(deliveryHour) : t('settings:closed')}
            </Typography>
          </Box>
        );
      })}
      <Box sx={{ borderRadius: 2, backgroundColor: 'secondary.light', p: 1 }}>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          {t('settings:additional_info')}
        </Typography>
        <Typography variant="body1">
          {deliveryDescription || '-'}
        </Typography>
      </Box>
      {enableEdit && (
        <Button
          variant="outlined"
          onClick={() => setDeliveryHoursDrawerOpenAtom(true)}
          startIcon={<EditOutlinedIcon />}
          sx={{ alignSelf: 'start' }}
        >
          {t('common:modify')}
        </Button>
      )}
    </Box>
  );
}
