import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { UserMessage } from '@sendbird/chat/message';
import Avatar from '@sendbird/uikit-react/ui/Avatar';
import MessageStatus from '@sendbird/uikit-react/ui/MessageStatus';
import { Business_Profile_Enum } from 'kheops-graphql';
import { currentContextAtom, userAtom } from '../../state';
import OfferData from './OfferData';
import MessageTime from '../customMessages/MessageTime';
import { currentChannelAtom, currentContractAtom, OfferMessageData, showChannelMembersAtom } from '../state';

export interface OfferMessageProps {
  message: UserMessage;
}

export default function OfferMessage({ message }: OfferMessageProps): React.JSX.Element | null {
  const { t } = useTranslation(['contracts']);
  const { realm } = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const currentChannel = useAtomValue(currentChannelAtom);
  const currentContract = useAtomValue(currentContractAtom);
  const showChannelMembers = useAtomValue(showChannelMembersAtom);
  const data = JSON.parse(message.data) as OfferMessageData;
  const offerInfo = {
    ...data,
    supplyingCompanyId: currentContract?.supplyingCompanyId || '',
  };
  const isByMe = message.sender?.userId === userId;
  const isOfferEditable = useMemo((): boolean => {
    return data.proposer_business_profile === realm && currentContract?.id === data.contract_id;
  }, [currentContract]);
  const isOfferNegotiable = useMemo((): boolean => {
    return !!currentContract && data.proposer_business_profile !== realm && currentContract?.id === data.contract_id;
  }, [currentContract]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        isByMe
          ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', px: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {t(realm === Business_Profile_Enum.Buyer ? 'contracts:you_sent_an_offer_as_buyer' : 'contracts:you_sent_an_offer_as_supplier')}
              </Typography>
              {!!currentContract && (
                <OfferData
                  contractId={data.contract_id}
                  offerInfo={offerInfo}
                  editable={isOfferEditable}
                />
              )}
              <Box
                sx={{
                  mt: 0.5,
                  '& .sendbird-message-status': {
                    justifyContent: 'end',
                  },
                }}
              >
                <MessageStatus message={message} channel={currentChannel!} />
              </Box>
            </Box>
          )
          : (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', px: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {t('contracts:partner_has_sent_an_offer', { partner: message.sender.nickname })}
              </Typography>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                  <Avatar
                    className="sendbird-message-content__left__avatar"
                    src={message.sender.profileUrl || ''}
                    width="28px"
                    height="28px"
                  />
                  <OfferData
                    contractId={data.contract_id}
                    offerInfo={offerInfo}
                    editable={isOfferEditable}
                    negotiable={isOfferNegotiable}
                    sx={{
                      flexDirection: {
                        xs: isOfferNegotiable ? 'column' : 'row',
                        lg: showChannelMembers ? 'column' : 'row',
                      },
                      gap: 2,
                    }}
                  />
                </Box>
                <MessageTime time={message.createdAt} />
              </Box>
            </Box>
          )
      }
    </>
  );
}
