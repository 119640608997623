import { Box, Divider, Drawer, IconButton, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import Close from '@mui/icons-material/Close';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import AddComment from './AddComment';
import CommentCard from './CommentCard';
import { useCommentsByOrderQuery } from '../../../queries/__generated__/commentsByOrder.generated';
import useOpenable from '../../../hooks/useOpenable';
import { orderAtom } from '../../state/state';

export interface OrderCommentsProps {
  compact?: boolean;
}

export default function OrderComments({ compact }: OrderCommentsProps): React.JSX.Element {
  const { t } = useTranslation(['comments']);
  const { open, close, isOpen } = useOpenable();
  const { reference_id: orderReferenceId } = useAtomValue(orderAtom);
  const { data } = useCommentsByOrderQuery({ variables: { orderReferenceId }, fetchPolicy: 'network-only' });

  return (
    <>
      {compact && (
        <IconButton
          color="primary"
          sx={{
            border: '1px solid',
          }}
          onClick={open}
        >
          <AddCommentOutlinedIcon sx={{ height: 18, width: 18 }} />
        </IconButton>
      )}
      {!compact && !!data && (
        <ListItemButton onClick={open}>
          <ListItemIcon sx={{ minWidth: 48 }}>
            <MessageOutlinedIcon sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <ListItemText
            primary={t('comments:N_comments', { count: data.comment.length })}
            primaryTypographyProps={{
              sx: {
                lineHeight: '2rem',
              },
            }}
          />
          <ArrowRightOutlinedIcon />
        </ListItemButton>
      )}
      {!!data && (
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={close}
          PaperProps={{
            sx: {
              boxSizing: 'border-box',
              width: {
                xs: '90%',
                sm: 440,
              },
              p: 3,
              gap: 2,
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="title" sx={{ lineHeight: '40px' }}>
              {t('comments:N_comments', { count: data.comment.length })}
            </Typography>
            <IconButton
              onClick={close}
              sx={{
                backgroundColor: 'greys.primary',
                color: 'text.primary',
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ flex: 1 }}>
            {data.comment.length
              ? data.comment.map((comment, index) => (
                <>
                  <CommentCard key={comment.id} comment={comment} />
                  {index + 1 < data.comment.length && <Divider sx={{ mt: 3, mb: 2 }} />}
                </>
              ))
              : (
                <Typography variant="h6" sx={{ color: 'text.secondary', lineHeight: 'normal' }}>
                  {t('comments:share_something_about_this_order')}
                </Typography>
              )}
          </Box>
          <AddComment orderReferenceId={orderReferenceId} />
        </Drawer>
      )}
    </>
  );
}
