import SearchIcon from '@mui/icons-material/Search';
import { useHits, useSearchBox } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import FilterTag, { FilterTagSize, FilterTagVariant } from './FilterTag';

export interface QueryTagProps {
  variant: FilterTagVariant;
  size: FilterTagSize;
}

export default function QueryTag({ variant, size }: QueryTagProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { results } = useHits();
  const { query, clear } = useSearchBox();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!(query && results) && (
        <FilterTag
          chipProps={{
            icon: <SearchIcon />,
            onDelete: clear,
            label: `${t('discovery:advanced_search')} ${t('discovery:N_result', { count: results.nbHits })}`,
          }}
          variant={variant}
          size={size}
        />
      )}
    </>
  );
}
