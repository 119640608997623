import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

interface ProductAllergensProps {
  allergens: string;
}

export default function ProductAllergens({ allergens }: ProductAllergensProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t('products:allergen_list')}
      </Typography>
      <Typography variant="body1">
        {allergens}
      </Typography>
    </Box>
  );
}
