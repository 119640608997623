import { useEffect } from 'react';
import { Provider, useAtom } from 'jotai';
import DiscoveryAsBuyerPage from './DiscoveryAsBuyerPage';
import useBaseStore from '../../hooks/useBaseStore';
import { basketItemsAtom } from '../../basket/state/state';

export default function DiscoveryAsBuyerProvider(): React.JSX.Element {
  const baseStore = useBaseStore();
  const [basketItems, setBasketQuantities] = useAtom(basketItemsAtom);

  useEffect((): void => {
    baseStore.sub(basketItemsAtom, () => {
      setBasketQuantities(baseStore.get(basketItemsAtom));
    });
  }, []);

  useEffect((): void => {
    baseStore.set(basketItemsAtom, basketItems);
  }, [basketItemsAtom]);

  return (
    <Provider store={baseStore}>
      <DiscoveryAsBuyerPage />
    </Provider>
  );
}
