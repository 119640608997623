import { Box, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BackButton from '../common/components/BackButton';
import { RoutePaths } from '../routes/AppRoutes';
import ContextNavigation from './ContextNavigation';

export default function NavigationContexts(): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          px: 2,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          width: '100vw',
          height: '72px',
        }}
      >
        <BackButton
          compact
          sx={{
            p: 1,
            width: 40,
            height: 40,
            '& .MuiSvgIcon-root': {
              width: 24,
              height: 24,
            },
          }}
          to={RoutePaths.ROOT}
        />
        <Typography variant="title">
          {t('common:switch_company')}
        </Typography>
      </Box>
      <Card sx={{ m: 2, px: 2, py: 1 }}>
        <ContextNavigation showCurrent />
      </Card>
    </>
  );
}
