import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { RoutePaths } from '../../routes/AppRoutes';
import { DraftCatalog } from './state';

export interface CatalogFormButtonsProps {
  copy?: boolean;
  edit?: boolean;
  fullWidth?: boolean;
}

export default function CatalogFormButtons({ edit, copy, fullWidth }: CatalogFormButtonsProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'price']);
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || undefined;
  const navigate = useNavigate();
  const { formState: { isDirty, isValid, isSubmitted } } = useFormContext<DraftCatalog>();

  const handleCancel = (): void => {
    if (contractId) {
      window.history.back();
    } else {
      navigate(RoutePaths.COMPANY_CATALOGS);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, py: 2 }}>
      <Button
        onClick={handleCancel}
        variant="text"
        color="secondary"
        sx={{ flexGrow: fullWidth ? 1 : 0 }}
      >
        {t('common:cancel')}
      </Button>
      <Button
        disabled={(!isDirty && !copy) || (!isValid && isSubmitted)}
        variant="contained"
        type="submit"
        startIcon={edit ? <EditIcon /> : <AddIcon />}
        sx={{ flexGrow: fullWidth ? 1 : 0 }}
      >
        {t(edit ? 'price:edit_the_catalog' : 'price:create_the_catalog')}
      </Button>
    </Box>
  );
}
