import { useMemo } from 'react';
import { useCurrentRefinements } from 'react-instantsearch';

export default function useNormalizedCurrentRefinements(attributeToInclude: string | string[]): (string | number)[] {
  const { items } = useCurrentRefinements({
    includedAttributes: typeof attributeToInclude === 'string' ? [attributeToInclude] : attributeToInclude,
  });

  const normalizedItems = useMemo(() => (
    items[0]?.refinements.map((refinement) => refinement.value) || []
  ), [items]);

  return normalizedItems;
}
