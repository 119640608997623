import { DraftCSU, DraftPackaging } from './state';

export function isPriceInputDisabled(baseUnit: DraftCSU, packaging: DraftPackaging): boolean {
  let baseUnitRequiredAttributes: (keyof DraftCSU)[];
  let packagingRequiredAttributes: (keyof DraftPackaging)[];

  if (baseUnit.csu_type === 'BULK') {
    baseUnitRequiredAttributes = [];
    packagingRequiredAttributes = ['trade_item_unit_descriptor', 'net_content', 'content_measurement_unit'];
  } else {
    baseUnitRequiredAttributes = ['unit_quantity', 'unit_type', 'unit_net_content', 'content_measurement_unit'];
    packagingRequiredAttributes = ['trade_item_unit_descriptor', 'unit_quantity'];
  }

  return baseUnitRequiredAttributes.some((key) => !baseUnit[key]) || packagingRequiredAttributes.some((key) => !packaging[key]);
}
