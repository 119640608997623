import { Box, Card, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useAtomValue, useSetAtom } from 'jotai';
import { Catalog_Packaging_Insert_Input } from 'kheops-graphql';
import { currentContextAtom } from '../../state';
import { RoutePaths } from '../../routes/AppRoutes';
import { PageWrapper } from '../../common/components/PageWrapper';
import useCatalogFormDefaultValues from './hooks/useCatalogFormDefaultValues';
import useUpsertCatalogMutation, { UpsertCatalogMutationVariables } from './hooks/useUpsertCatalogMutation';
import { DraftCatalog } from './state';
import CatalogInfoForm from './CatalogInfoForm';
import CatalogProductsForm from './CatalogProductsForm';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import CatalogFormButtons from './CatalogFormButtons';
import CatalogFormHeader from './hooks/CatalogFormHeader';

export default function CatalogForm(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['price']);
  const { companyId } = useAtomValue(currentContextAtom);
  const catalogId = searchParams.get('catalogId') || undefined;
  const contractId = searchParams.get('contractId') || undefined;
  const copyCatalogId = searchParams.get('copyCatalogId') || undefined;
  const defaultValues = useCatalogFormDefaultValues({
    catalogId: catalogId || copyCatalogId,
    contractId,
    copy: !!copyCatalogId,
  });
  const form = useForm<DraftCatalog>({ defaultValues });
  const {
    handleSubmit,
    reset,
  } = form;
  const { upsertCatalogMutation } = useUpsertCatalogMutation();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const onSubmit = useCallback(async (draftCatalog: DraftCatalog): Promise<void> => {
    const upsertCatalogMutationVariables = {} as UpsertCatalogMutationVariables;
    const catalogPackagingsPayload = Object.entries(draftCatalog.prices).reduce((acc, [packagingSku, draftPrice]) => {
      if (draftPrice.discount) {
        acc.push({
          packaging_sku: parseInt(packagingSku, 10),
          discount: draftPrice.discount / 100,
          company_id: companyId,
          catalog_id: catalogId || undefined,
        });
      }

      return acc;
    }, [] as Catalog_Packaging_Insert_Input[]);
    let successLabel: string;

    if (!catalogId) {
      upsertCatalogMutationVariables.upsertCatalogVariables = {
        catalog: {
          name: draftCatalog.name,
          company_id: companyId,
          catalog_packagings: {
            data: catalogPackagingsPayload,
          },
        },
      };
      upsertCatalogMutationVariables.addedContractIds = draftCatalog.contracts;

      successLabel = 'price:catalog_created';
    } else {
      const deletedPackagingSkus = Object.entries(defaultValues.prices).reduce((acc, [packagingSku, draftPrice]) => {
        if (!draftPrice) {
          acc.push(parseInt(packagingSku, 10));
        }

        return acc;
      }, [] as number[]);

      upsertCatalogMutationVariables.upsertCatalogVariables = {
        id: catalogId,
        name: draftCatalog.name,
        now: new Date().toISOString(),
        deletedPackagingSkus,
        catalogPackagings: catalogPackagingsPayload,
      };
      upsertCatalogMutationVariables.addedContractIds = draftCatalog.contracts.filter((value) => !defaultValues.contracts.includes(value));
      upsertCatalogMutationVariables.deletedContractIds = defaultValues.contracts.filter((value) => !draftCatalog.contracts.includes(value));

      successLabel = 'price:catalog_updated';
    }

    const newCatalogId = await upsertCatalogMutation(upsertCatalogMutationVariables);

    if (location.state?.origin) {
      navigate(
        {
          pathname: location.state.origin,
          search: newCatalogId ? `?${createSearchParams({ newCatalogId })}` : undefined,
        },
        {
          state: { newCatalogId, newCatalogName: draftCatalog.name },
        },
      );
    } else {
      navigate(RoutePaths.COMPANY_CATALOGS);
    }

    setCommonSnackbarProps({
      label: t(successLabel),
      snackbarProps: {
        open: true,
      },
    });
  }, [catalogId, defaultValues]);

  useEffect((): void => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <PageWrapper>
      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          <Grid
            container
            sx={{
              height: {
                xs: 'calc(100vh - 240px)',
                lg: 'calc(100vh - 168px)',
              },
            }}
            columnSpacing={2}
          >
            <Grid item xs={0} lg={4} sx={{ display: { xs: 'none', lg: 'flex' } }}>
              <Card sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: '100%' }}>
                <CatalogFormHeader edit={!!catalogId} />
                <CatalogInfoForm />
                <CatalogFormButtons copy={!!copyCatalogId} edit={!!catalogId} fullWidth />
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: {
                    xs: '100%',
                    lg: 'calc(100% + 24px)',
                  },
                  pb: {
                    lg: 0,
                  },
                  gap: 3,
                }}
              >
                {isDownLg && (
                  <>
                    <CatalogFormHeader edit={!!catalogId} />
                    <CatalogInfoForm />
                  </>
                )}
                <CatalogProductsForm />
                {isDownLg && <CatalogFormButtons copy={!!copyCatalogId} edit={!!catalogId} />}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </PageWrapper>
  );
}
