import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { editedOrderItemsAtom } from '../state/state';

interface OrderActionButtonCancelProps {
  disabled: boolean
}

export default function OrderActionButtonCancel({ disabled }: OrderActionButtonCancelProps): React.JSX.Element {
  const { t } = useTranslation('order');
  const setEditedItems = useSetAtom(
    editedOrderItemsAtom,
  );

  return (
    <Button
      size="extraLarge"
      variant="text"
      color="primary"
      onClick={() => setEditedItems()}
      disabled={disabled}
      sx={{
        flex: 'auto',
        backgroundColor: 'white',
        ':hover': {
          backgroundColor: 'primary.main',
          color: 'white',
        },
      }}
    >
      {t('order:cancel_modifications')}
    </Button>
  );
}
