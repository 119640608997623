import { Badge, IconButton } from '@mui/material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { PushTriggerOption } from '@sendbird/chat';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';
import { RoutePaths } from '../routes/AppRoutes';

export default function ChatButton(): React.JSX.Element {
  const navigate = useNavigate();
  const { allChannels } = useChannelListContext();
  const matchChatPage = useMatch(RoutePaths.CHAT_LIST);

  const hasUnreadMessages = useMemo((): boolean => {
    return allChannels.some((channel) => channel.unreadMessageCount && channel.myPushTriggerOption !== PushTriggerOption.OFF);
  }, [allChannels]);

  return (
    <Badge
      color="primary"
      variant="dot"
      invisible={!hasUnreadMessages}
      sx={{
        '& .MuiBadge-badge': {
          top: 6,
          right: 6,
        },
      }}
    >
      <IconButton
        color="secondary"
        sx={{
          backgroundColor: matchChatPage ? 'greys.dark' : 'greys.primary',
        }}
        onClick={() => navigate(RoutePaths.CHAT_LIST)}
      >
        <MessageOutlinedIcon />
      </IconButton>
    </Badge>
  );
}
