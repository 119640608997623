import { Tab, TabProps, styled } from '@mui/material';

// there seems to be an issue with types as component is not recognized as a proprety
// hence why it was added manually
// https://github.com/mui/material-ui/issues/16846
const KheopsTab = styled(Tab)<TabProps & { component?: React.ElementType, to?: string}>(({ theme }) => ({
  color: 'black',
  height: 56,
  '&.Mui-selected': {
    backgroundColor: theme.palette.greys.light,
  },
  '&:hover': {
    backgroundColor: theme.palette.greys.light,
  },
  '& .MuiTab-iconWrapper': {
    flexShrink: 0,
    mr: 0.5,
  },
  borderRadius: theme.spacing(2),
}));

export default KheopsTab;
