import { getFormat, isValid } from 'gtin';

export default class ValidatorsUtils {
  static validateDecimal(value: number): boolean {
    if (!value) {
      return true;
    }

    return value.toString().split('.').length === 1;
  }

  static validateGtin(value: string): boolean {
    if (!value) {
      return true;
    }

    try {
      return getFormat(value) === 'GTIN-13' && isValid(value);
    } catch (e) {
      return false;
    }
  }

  static validatePrice(value: number): boolean {
    return /^\d+(\.\d{1,2})?$/.test(value.toString());
  }
}
